import { OnInit, OnDestroy, ElementRef } from '@angular/core';
import { MatDialogRef, MatChipInputEvent, MatDialog, MatTableDataSource } from '@angular/material';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { ContactList, ContactListDetail } from './c-list.model';
import { Subject } from 'rxjs';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ContactsListService } from '../c-list/c-list.service';
import { debounceTime } from 'rxjs/operators';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { SelectionModel } from '@angular/cdk/collections';
var ContactListEditorComponent = /** @class */ (function () {
    function ContactListEditorComponent(matDialogRef, _data, _formBuilder, _contactService, _matDialog) {
        this.matDialogRef = matDialogRef;
        this._data = _data;
        this._formBuilder = _formBuilder;
        this._contactService = _contactService;
        this._matDialog = _matDialog;
        this.selectedTab = 0;
        this.allContacts = [];
        this.filterApplied = false;
        this.contactsDisabled = false;
        this.allSelected = false;
        this.displayedColumns = ['select', 'fullName', 'companyName', 'email', 'phone', 'address',];
        this.dataSource = new MatTableDataSource(this.allContacts);
        this.selection = new SelectionModel(true, []);
        this.editIndex = [];
        this._unsubscribeAll = new Subject();
        this.visible = true;
        this.selectable = true;
        this.removable = true;
        this.addOnBlur = true;
        this.separatorKeysCodes = [ENTER, COMMA];
        this.tags = [];
        this.contactExist = false;
        this.firstload = true;
        // Set the defaults
        this.action = _data.action;
        if (this.action === 'edit') {
            this.contactList = _data.contactList;
            this.contactListDetail = _data.contactListDetail;
            this.contacts = _data.contacts;
            this.allContacts = _data.contacts;
            this.firstload = true;
        }
        else {
            this.contactList = new ContactList();
            this.contacts = _data.contacts;
            this.allContacts = _data.contacts;
        }
        this.createContactForm();
        // this.createAddressForm();
        this.subscribeContactExist();
    }
    Object.defineProperty(ContactListEditorComponent.prototype, "formValue", {
        get: function () {
            return this.contactListForm.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ContactListEditorComponent.prototype, "addNewIndex", {
        get: function () {
            var value = this.contactListForm.value;
            return value && value.contactListDetail ? value.contactListDetail.length - 1 : -1;
        },
        enumerable: true,
        configurable: true
    });
    ContactListEditorComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.selectedContacts = [];
        if (this.contactListDetail != undefined) {
            this.contactListDetail.forEach(function (contact) {
                _this.selectedContacts.push(_this.allContacts.find(function (b) { return b.contactId == contact[Object.keys(contact)[2]]; }));
            });
        }
        this.dataSource = new MatTableDataSource(this.allContacts);
        this.selection = new SelectionModel(true, this.selectedContacts);
        // this.dataSource.filterPredicate = function(data: ContactListItem, filterValue: string) {
        //     return data.fullName
        //       .trim()
        //       .toLocaleLowerCase().indexOf(filterValue.trim().toLocaleLowerCase()) >= 0;
        //   };
        // fromEvent(this.filter.nativeElement, 'keyup')
        //     .pipe(
        //         takeUntil(this._unsubscribeAll),
        //         debounceTime(150),
        //         distinctUntilChanged()
        //     )
        //     .subscribe(() => {
        //         if (!this.dataSource) {
        //             return;
        //         }
        //         this.dataSource.filter = this.filter.nativeElement.value;
        //     });
    };
    ContactListEditorComponent.prototype.filterContacts = function (a) {
        this.dataSource.filter = a.value;
    };
    ContactListEditorComponent.prototype.ngOnDestroy = function () {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    };
    ContactListEditorComponent.prototype.subscribeContactExist = function () {
        var _this = this;
        this.contactListForm
            .get('description')
            .valueChanges.pipe(debounceTime(500))
            .subscribe(function (_) { return _this.checkExist(); });
        // this.contactsListSelected.value = a;
        // this.contactListForm
        //     .get('contactListDetail')
        //     .valueChanges.pipe(debounceTime(500))
        //     .subscribe();
        // this.contactListForm
        //     .get('contactListContacts')
        //     .valueChanges.pipe(takeUntil(this._unsubscribeAll))
        //     // .valueChanges.pipe(debounceTime(500))
        //     .subscribe();
        // .valueChanges.pipe(takeUntil(this._unsubscribeAll))
        // this.contactForm
        //     .get('lastName')
        //     .valueChanges.pipe(debounceTime(500))
        //     .subscribe(_ => this.checkExist());
        // this.contactForm
        //     .get('phoneNumber')
        //     .valueChanges.pipe(debounceTime(500))
        //     .subscribe(_ => this.checkExist());
        // this.contactForm
        //     .get('email')
        //     .valueChanges.pipe(debounceTime(500))
        //     .subscribe(_ => this.checkExist());
    };
    ContactListEditorComponent.prototype.isAllSelected = function () {
        var numSelected = this.selection.selected.length;
        var numRows = this.dataSource.data.length;
        return numSelected === numRows;
    };
    /** Selects all rows if they are not all selected; otherwise clear selection. */
    ContactListEditorComponent.prototype.masterToggle = function () {
        var _this = this;
        this.isAllSelected() ?
            this.selection.clear() :
            this.dataSource.data.forEach(function (row) { return _this.selection.select(row); });
    };
    /** The label for the checkbox on the passed row */
    ContactListEditorComponent.prototype.checkboxLabel = function (row) {
        if (!row) {
            return (this.isAllSelected() ? 'select' : 'deselect') + " all";
        }
        return (this.selection.isSelected(row) ? 'deselect' : 'select') + " row " + (row.contactId + 1);
    };
    ContactListEditorComponent.prototype.checkExist = function () {
        var _this = this;
        var contactList = {
            contactListId: this.contactListForm.get('contactListId').value,
            description: this.contactListForm.get('description').value,
        };
        this._contactService.isContactExist(contactList).then(function (result) {
            _this.contactExist = result.status;
            _this.contactExistId = result.contactListId;
            if (_this.contactExist) {
                _this.openExistWarningDialog();
            }
        });
    };
    ContactListEditorComponent.prototype.openExistWarningDialog = function () {
        this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });
        this.confirmDialogRef.componentInstance.header = 'Contact List Already Exists';
        this.confirmDialogRef.componentInstance.confirm = 'Ok';
        this.confirmDialogRef.componentInstance.cancel = null;
        this.confirmDialogRef.componentInstance.confirmMessage =
            'Please note that this contact List already exists in your address book!';
    };
    ContactListEditorComponent.prototype.createContactForm = function () {
        var _this = this;
        this.tags = this.contactList.tags.slice();
        this.contactList.tags = [];
        this.contactList.contactListDetail = [];
        this.contactListForm = this._formBuilder.group(this.contactList);
        this.contactList.tags = this.tags;
        if (this.contactListDetail)
            this.contactList.contactListDetail = this.contactListDetail;
        // this.contactListForm.setControl('contactListContacts', this._formBuilder.array([], Validators.required));
        // const contactFormArray = this.contactListForm.get('contactListDetail') as FormArray;
        // this.contactList.contactListDetail.forEach(detail => {
        //     contactFormArray.push(this._formBuilder.group(detail));
        //     this.editIndex.push(false);
        // });
        this.contactListForm.valueChanges.subscribe(function (_) { return (_this.firstload = false); });
    };
    ContactListEditorComponent.prototype.populateContactListDetails = function (action) {
        var _this = this;
        this.contactListDetail = [];
        this.selection.selected.forEach(function (element) {
            var selectedlist = new ContactListDetail();
            selectedlist.ContactId = element.contactId;
            var a = _this.contactListDetail.find(function (b) { return b.ContactId == element.contactId; });
            if (a) {
                selectedlist.ContactListId = a.ContactListId;
                selectedlist.Id = a.Id;
            }
            else {
                selectedlist.ContactListId = 0;
                selectedlist.Id = 0;
            }
            _this.contactListDetail.push(selectedlist);
            _this.contactList.contactListDetail.push(selectedlist);
            _this.contactListForm.controls['contactListDetail'].setValue(selectedlist);
        });
    };
    ContactListEditorComponent.prototype.add = function (event) {
        var input = event.input;
        var value = event.value;
        // Add our fruit
        if ((value || '').trim()) {
            this.tags.push(value.trim());
        }
        // Reset the input value
        if (input) {
            input.value = '';
        }
    };
    ContactListEditorComponent.prototype.remove = function (tag) {
        var index = this.tags.indexOf(tag);
        if (index >= 0) {
            this.tags.splice(index, 1);
        }
    };
    ContactListEditorComponent.prototype.saveNew = function () {
        if (!this.contactListForm.valid) {
            return;
        }
        this.populateContactListDetails('new');
        this.matDialogRef.close(['new', this.contactListForm.value, this.tags, this.contactListDetail]);
    };
    ContactListEditorComponent.prototype.saveExisting = function () {
        if (!this.contactListForm.valid) {
            return;
        }
        this.populateContactListDetails('edit');
        this.matDialogRef.close(['edit', this.contactListForm.value, this.tags, this.contactListDetail]);
    };
    return ContactListEditorComponent;
}());
export { ContactListEditorComponent };
