import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { OrderAnonymousBaseComponent } from '../anonymous-base/anonymous-base.component';
import { CommodityService } from 'app/_shared/commodity.service';
import { MyNetServiceType, ServiceDirection } from 'app/_enums/ServiceDirection.enum';
var OrdersAnonymousShipmentDetailsComponent = /** @class */ (function (_super) {
    tslib_1.__extends(OrdersAnonymousShipmentDetailsComponent, _super);
    function OrdersAnonymousShipmentDetailsComponent(_commodityService) {
        var _this = _super.call(this) || this;
        _this._commodityService = _commodityService;
        _this.submited = false;
        return _this;
    }
    OrdersAnonymousShipmentDetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.form.get('currentDirection')) {
            var serviceType = this.form.get('currentDirection').value;
            var mynetServiceType = void 0;
            switch (serviceType) {
                case ServiceDirection.Export:
                    mynetServiceType = MyNetServiceType.Export;
                    break;
                case ServiceDirection.Import:
                    mynetServiceType = MyNetServiceType.Import;
                    break;
                case ServiceDirection.Domestic:
                    mynetServiceType = MyNetServiceType.Domestic;
                    break;
                default:
                    break;
            }
            this._commodityService.getCommodities(mynetServiceType).then(function (result) {
                _this.commodities = result;
            });
        }
        // this._commodityService.commoditiesChanged.subscribe(result => {
        //     this.commodities = result;
        //     this.group.get('commodityId').setValue('');
        // });
    };
    OrdersAnonymousShipmentDetailsComponent.prototype.validateAndContinue = function () {
        if (this.group.valid) {
            this.continue();
        }
        else {
            this.submited = true;
            this.group.updateValueAndValidity();
            this.group.markAllAsTouched();
        }
    };
    return OrdersAnonymousShipmentDetailsComponent;
}(OrderAnonymousBaseComponent));
export { OrdersAnonymousShipmentDetailsComponent };
