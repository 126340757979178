import { OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { QuoteService } from '../quote.service';
import { QuickQuote } from './quick-quote.model';
import { ServiceDirection, MyNetServiceType } from 'app/_enums/ServiceDirection.enum';
import { CurrencyType, ServiceType } from 'app/_enums/ServiceType.enum';
import { InternationalQuote } from '../international/international.model';
import { Router, NavigationExtras } from '@angular/router';
import { Routes } from 'app/Routes';
import { QuoteData } from 'app/main/orders/new-order/quote-data.model';
import { QuoteResult } from '../quote-result/quote-result.model';
import { WeightValidator } from 'app/_validators/weight.validator';
import { FuseConfigService } from '@fuse/services/config.service';
import { AuthenticationService } from 'app/authentication/authentication.service';
import { DecimalValidator } from 'app/_validators/decimal.validator';
import { MatDialog } from '@angular/material';
import { ConfirmDimesionsComponent } from 'app/main/profile/confirm-dimensions/confirm-dimensions';
import { CashOnDelivery } from 'app/main/orders/new-order/order.model';
import { CurrencyValidator } from 'app/_validators/currency.validator';
import { StatusValidator } from 'app/_validators/status.validator';
var QuotesQuickQuoteComponent = /** @class */ (function () {
    //#endregion
    function QuotesQuickQuoteComponent(_matDialog, _formBuilder, _quoteService, _router, _authenticationService, _fuseConfigService) {
        this._matDialog = _matDialog;
        this._formBuilder = _formBuilder;
        this._quoteService = _quoteService;
        this._router = _router;
        this._authenticationService = _authenticationService;
        this._fuseConfigService = _fuseConfigService;
        this.quoteCalculated = false;
        this.formChanged = false;
        this.loading = false;
        this.allowedToProceed = true;
        this.neededServiceType = false;
        this.ServiceType = ServiceType;
        this.loadingShippingTypes = false;
        this.fromCityControl = new FormControl('', Validators.required);
        this.toCityControl = new FormControl('', Validators.required);
        this.selectedDirectionName = '';
        this.selectedDirectionNameClone = '';
        this.quoteIcon = '';
        this.quoteIconClone = '';
        // lebanonId = Countries.Lebanon;
        this.domesticCountryId = 158; //Lebanon
        this._unsubscribeAll = new Subject();
        this.ServiceDirection = ServiceDirection;
        var pathname = window.location.pathname;
        if (pathname.includes(Routes.domesticQuotes)) {
            this.readyDirection = ServiceDirection.Domestic;
            this.updateFuseTemplate();
        }
        else if (pathname.includes(Routes.exportQuote)) {
            this.readyDirection = ServiceDirection.Export;
            this.updateFuseTemplate();
        }
    }
    QuotesQuickQuoteComponent.prototype.updateFuseTemplate = function () {
        if (!this._authenticationService.isLoggedIn()) {
            this._fuseConfigService.config = {
                layout: {
                    navbar: {
                        hidden: true
                    },
                    footer: {
                        hidden: true
                    },
                    sidepanel: {
                        hidden: true
                    }
                }
            };
        }
    };
    QuotesQuickQuoteComponent.prototype.addNewCod = function () {
        var cods = this.quoteForm.get('cods');
        var cod = this._formBuilder.group(new CashOnDelivery());
        // cod.setValidators();
        cod.get('codAmount').setValidators(Validators.min(1));
        cod.get('codCurrencyId').setValidators(Validators.min(1));
        if (cods.length > 0) {
            cod.get('codCurrencyId').setValidators([Validators.min(1), CurrencyValidator.validCurrency(cods.at(cods.length - 1).get('codCurrencyId').value)]);
            cod.get('codCurrencyId').setValue(2);
        }
        else
            cod.get('codCurrencyId').setValue(1);
        cods.push(cod);
    };
    QuotesQuickQuoteComponent.prototype.codAdded = function (data) {
        var pieceForm = data.value;
        var mode = data.mode;
        var index = data.index;
        this.quoteForm.setValidators(StatusValidator.validStatus("disabled"));
        if (mode === 'cancel') {
            // if (index >= 0) {
            //     this.editIndex[index] = false;
            // }
            return;
        }
        else if (mode === 'new') {
            // const pieces = this.group.get('quote').get('pieces') as FormArray;
            // pieces.at(index - 1).setValue(pieceForm);
            // var TotalWeight = 0, TotalNOP = 0;
            // for (let c of pieces.controls) {
            //     const pieceValue = c.value as Piece;
            //     TotalWeight += pieceValue.weight;
            //     TotalNOP += pieceValue.numberOfPieces;
            // }
            // if (TotalWeight == 0)
            //     TotalWeight = 0.5
            // if (TotalNOP == 0)
            //     TotalNOP = 1
            // this.group.get('quote').get('weight').setValue(TotalWeight);
            // this.group.get('quote').get('numberOfPieces').setValue(TotalNOP);
        }
        else if (mode === 'delete') {
            // const pieces = this.quoteForm.get('pieces') as FormArray;
            // pieces.removeAt(index - 1);
            // var TotalWeight = 0, TotalNOP = 0;
            // for (let c of pieces.controls) {
            //     const pieceValue = c.value as Piece;
            //     TotalWeight += pieceValue.weight;
            //     TotalNOP += pieceValue.numberOfPieces;
            // }
            // if (TotalWeight == 0)
            //     TotalWeight = 0.5
            // this.group.get('quote').get('weight').setValue(TotalWeight);
            // if (TotalNOP == 0)
            //     TotalNOP = 1;
            // this.group.get('quote').get('numberOfPieces').setValue(TotalNOP);
            // if (pieceForm != undefined && pieceForm.pieceId != 0) {
            //     this._quoteService.deletePieces(pieceForm.pieceId)
            // }
            // if (pieces.controls.length == 0)
            //     pieces.clearValidators()
        }
        // this.calculateChargeableWeight();
    };
    QuotesQuickQuoteComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.quoteForm = this.createQuoteForm();
        this.quoteForm.setControl('cods', this._formBuilder.array([]));
        this.prepareShippingTypes();
        this.subscribeShippingTypeChanged();
        this.progressValue = 0;
        this.quoteForm.valueChanges.subscribe(function (_) { return (_this.formChanged = true); });
        this.fromCityControl.valueChanges.subscribe(function (value) {
            _this.fromCitySelected(value);
            if (!_this.readyDirection) {
                _this.checkServiceType();
                _this.updateQuoteIcon();
            }
        });
        this.toCityControl.valueChanges.subscribe(function (value) {
            _this.toCitySelected(value);
            if (!_this.readyDirection) {
                _this.checkServiceType();
                _this.updateQuoteIcon();
            }
        });
        if (this.readyDirection) {
            this.checkServiceType();
            this.updateQuoteIcon();
        }
        this.setDecimalValidation();
        this.domesticCountryId = parseInt(localStorage.getItem('countryId').toString());
        // this.addNewCod();
    };
    QuotesQuickQuoteComponent.prototype.setDecimalValidation = function () {
        var length = this.quoteForm.get('length');
        var width = this.quoteForm.get('width');
        var height = this.quoteForm.get('height');
        if (this.currentDirection == ServiceDirection.Domestic) {
            length.setValidators([DecimalValidator.validDecimal(0, 200)]);
            width.setValidators([DecimalValidator.validDecimal(0, 150)]);
            height.setValidators([DecimalValidator.validDecimal(0, 150)]);
        }
        else {
            length.setValidators([DecimalValidator.validDecimal(0, 150)]);
            width.setValidators([DecimalValidator.validDecimal(0, 150)]);
            height.setValidators([DecimalValidator.validDecimal(0, 150)]);
        }
        var weight = this.quoteForm.get('weight');
        weight.setValidators([WeightValidator.validWeight(this.currentDirection), Validators.required]);
        weight.updateValueAndValidity();
    };
    QuotesQuickQuoteComponent.prototype.weightChanged = function (event) {
        var weight = +event.target.value;
        if (weight !== undefined && weight !== null && !isNaN(weight) && weight < 0.5) {
            this.quoteForm.get('weight').setValue('0.5');
        }
        // if (this.quoteForm.get('shippingTypeId').value == undefined || this.quoteForm.get('shippingTypeId').value == '') // tarif product not found
        // {
        //     this.quoteForm.get('shippingTypeId').setValue(0);
        // }
    };
    QuotesQuickQuoteComponent.prototype.ngOnDestroy = function () {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    };
    QuotesQuickQuoteComponent.prototype.updateQuoteIcon = function () {
        this.currentDirection = this.getDirection();
        if (this.currentDirection === ServiceDirection.Domestic) {
            // domestic
            this.quoteIcon = 'local_shipping';
            this.currentServiceType = MyNetServiceType.Domestic;
        }
        else if (this.currentDirection === ServiceDirection.Export) {
            // Export
            this.quoteIcon = 'flight_takeoff';
            this.currentServiceType = MyNetServiceType.Export;
        }
        else if (this.currentDirection === ServiceDirection.Import) {
            // Import
            this.quoteIcon = 'flight_land';
            this.currentServiceType = MyNetServiceType.Import;
        }
        else {
            // Not Supported
            this.quoteIcon = '';
            this.currentServiceType = undefined;
        }
    };
    QuotesQuickQuoteComponent.prototype.subscribeShippingTypeChanged = function () {
        var _this = this;
        this.quoteForm
            .get('shippingTypeId')
            .valueChanges.pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (shippingTypeId) {
            if (shippingTypeId && _this.shippingTypes) {
                _this.shippingTypeName = _this.shippingTypes.find(function (x) { return x.id === shippingTypeId; }).name;
            }
        });
    };
    QuotesQuickQuoteComponent.prototype.prepareShippingTypes = function (direction, serviceType) {
        var _this = this;
        if (direction === void 0) { direction = ServiceDirection.Domestic; }
        if (serviceType === void 0) { serviceType = ServiceType.Parcels; }
        // Direction is domestic since we are in domestic component
        // Service Type is always Parcels, no docs in Lebanon domestic
        if (this.getShippingTypesAction && !this.getShippingTypesAction.closed) {
            this.getShippingTypesAction.unsubscribe();
        }
        this.loadingShippingTypes = true;
        this.shippingTypes = [];
        this.getShippingTypesAction = this._quoteService.getShippingTypes(direction, serviceType).subscribe(function (result) {
            _this.shippingTypes = result;
            _this.loadingShippingTypes = false;
            if (_this.shippingTypes && _this.shippingTypes.length === 1) {
                _this.quoteForm.get('shippingTypeId').setValue(_this.shippingTypes[0].id);
                _this.shippingTypeName = _this.shippingTypes[0].name;
            }
            _this.setDecimalValidation();
            if (_this.shippingTypes.length == 0) {
                var shippingTypeId = _this.quoteForm.get('shippingTypeId');
                shippingTypeId.setValue(0);
                _this.quoteForm.updateValueAndValidity();
            }
        });
    };
    // private prepareShippingTypes(
    //     direction: ServiceDirection = ServiceDirection.Domestic,
    //     serviceType: ServiceType = ServiceType.Parcels ): void {
    //     // Direction is domestic since we are in domestic component
    //     // Service Type is always Parcels, no docs in Lebanon domestic
    //     if (this.getShippingTypesAction && !this.getShippingTypesAction.closed) {
    //         this.getShippingTypesAction.unsubscribe();
    //     }
    //     this.shippingTypes = [];
    //     this.getShippingTypesAction = this._quoteService.getShippingTypes(direction, serviceType).subscribe(result => {
    //         this.shippingTypes = result;
    //         if (this.shippingTypes && this.shippingTypes.length === 1) {
    //             this.quoteForm.get('shippingTypeId').setValue(this.shippingTypes[0].id);
    //             this.shippingTypeName = this.shippingTypes[0].name;
    //         }
    //     });
    // }
    QuotesQuickQuoteComponent.prototype.createQuoteForm = function () {
        var formGroup = this._formBuilder.group(new QuickQuote());
        formGroup.get('fromCityId').setValidators(Validators.required);
        formGroup.get('toCityId').setValidators(Validators.required);
        return formGroup;
    };
    Object.defineProperty(QuotesQuickQuoteComponent.prototype, "quoteCods", {
        get: function () {
            return this.quoteForm.get('cods').controls;
        },
        enumerable: true,
        configurable: true
    });
    QuotesQuickQuoteComponent.prototype.fromCitySelected = function (option) {
        this.fromCityCleared();
        if (option && option.cityId && option.cityId > 0 && option.countryId && option.countryId > 0) {
            this.quoteForm.get('fromCityId').setValue(option.cityId);
            this.selectedFromCityName = option.displayName;
            this.fromCountryId = option.countryId;
        }
        else {
            this.fromCityControl.setErrors({ required: true });
        }
    };
    QuotesQuickQuoteComponent.prototype.fromCityCleared = function () {
        this.quoteForm.get('fromCityId').setValue('');
        this.selectedFromCityName = '';
        this.fromCityControl.clearValidators();
        this.fromCountryId = undefined;
    };
    QuotesQuickQuoteComponent.prototype.toCitySelected = function (option) {
        this.toCityCleared();
        if (option && option.cityId && option.cityId > 0 && option.countryId && option.countryId > 0) {
            this.quoteForm.get('toCityId').setValue(option.cityId);
            this.selectedToCityName = option.name;
            this.toCountryId = option.countryId;
        }
        else {
            this.toCityControl.setErrors({ required: true });
        }
    };
    QuotesQuickQuoteComponent.prototype.toCityCleared = function () {
        this.quoteForm.get('toCityId').setValue('');
        this.selectedToCityName = '';
        this.toCityControl.clearValidators();
        this.toCountryId = undefined;
    };
    QuotesQuickQuoteComponent.prototype.checkServiceType = function () {
        var _this = this;
        this.quoteForm.removeControl('serviceTypeId');
        this.currentDirection = this.getDirection();
        if (this.currentDirection === ServiceDirection.Export) {
            // Export
            this.neededServiceType = true;
            this.maxWeight = WeightValidator.MaxExportWeight;
        }
        else if (this.currentDirection === ServiceDirection.Import) {
            // Import
            this.neededServiceType = true;
            this.maxWeight = undefined;
        }
        else if (this.currentDirection === ServiceDirection.Domestic) {
            // Domestic
            this.neededServiceType = false;
            this.maxWeight = WeightValidator.MaxDomesticWeight;
        }
        else {
            // Not Supported
            this.maxWeight = undefined;
        }
        this.quoteForm.get('shippingTypeId').setValue('');
        if (this.neededServiceType) {
            this.quoteForm.addControl('serviceTypeId', new FormControl('', Validators.required));
            this.shippingTypes = [];
            this.quoteForm.get('serviceTypeId').valueChanges.subscribe(function (serviceTypeId) {
                _this.prepareShippingTypes(_this.currentDirection, serviceTypeId);
            });
        }
        else {
            this.prepareShippingTypes();
        }
        var weight = this.quoteForm.get('weight');
        weight.setValue('');
        weight.setValidators([WeightValidator.validWeight(this.currentDirection), Validators.required]);
        weight.updateValueAndValidity();
    };
    QuotesQuickQuoteComponent.prototype.dimensionsChanged = function (content) {
        var validHeight = this.quoteForm.get('height').hasError('validDecimal');
        var validWidth = this.quoteForm.get('width').hasError('validDecimal');
        var validLength = this.quoteForm.get('length').hasError('validDecimal');
        if (validHeight || validWidth || validLength)
            this.confirmDimension = this._matDialog.open(ConfirmDimesionsComponent, {
                panelClass: 'confirm-Dimension'
            });
    };
    QuotesQuickQuoteComponent.prototype.getDirection = function () {
        if (this.readyDirection) {
            return this.readyDirection;
        }
        if (!this.fromCountryId || !this.toCountryId) {
            return undefined;
        }
        if (this.fromCountryId === this.domesticCountryId && this.toCountryId === this.domesticCountryId) {
            // domestic
            return ServiceDirection.Domestic;
        }
        else if (this.fromCountryId === this.domesticCountryId) {
            // Export
            return ServiceDirection.Export;
        }
        else if (this.toCountryId === this.domesticCountryId) {
            // Import
            return ServiceDirection.Import;
        }
        else {
            // Not Supported
            return undefined;
        }
    };
    QuotesQuickQuoteComponent.prototype.submitForm = function () {
        var _this = this;
        var currentDirection = this.getDirection();
        this.loading = true;
        if (currentDirection === ServiceDirection.Export || currentDirection === ServiceDirection.Import) {
            var serviceType = this.quoteForm.get('serviceTypeId').value;
            if (!serviceType ||
                serviceType === 0 ||
                (serviceType !== ServiceType.Docs && serviceType !== ServiceType.Parcels)) {
                this.quoteForm.get('serviceTypeId').setErrors({ required: true });
                return;
            }
        }
        if (this.quoteForm.invalid) {
            return;
        }
        var international = false;
        if (currentDirection === ServiceDirection.Domestic) {
            // domestic
            this.selectedDirectionName = ServiceDirection[ServiceDirection.Domestic];
            this.quoteIcon = 'local_shipping';
            this.allowedToProceed = true;
        }
        else if (currentDirection === ServiceDirection.Export) {
            // Export
            this.selectedDirectionName = ServiceDirection[ServiceDirection.Export];
            this.quoteIcon = 'flight_takeoff';
            this.allowedToProceed = true;
            international = true;
        }
        else if (currentDirection === ServiceDirection.Import) {
            // Import
            this.selectedDirectionName = ServiceDirection[ServiceDirection.Import];
            this.quoteIcon = 'flight_land';
            this.allowedToProceed = true;
            international = true;
        }
        else {
            // Not Supported
            this.selectedDirectionName = 'Not Supported';
            this.quoteIcon = 'not_interested';
            this.allowedToProceed = false;
        }
        var generateQuote;
        var formValue = this.quoteForm.value;
        formValue.cods = this.quoteForm.get('cods').value;
        if (international) {
            formValue.fromCountryId = this.fromCountryId;
            if (this.quoteForm.get('shippingTypeId').value == undefined || this.quoteForm.get('shippingTypeId').value == '') // tarif product not found
             {
                this.quoteForm.get('shippingTypeId').setValue(0);
                formValue.shippingTypeId = 0;
            }
            formValue.toCountryId = this.toCountryId;
            generateQuote = this._quoteService.generateInternationalQuote(formValue);
        }
        else {
            formValue.serviceTypeId = ServiceType.Parcels;
            generateQuote = this._quoteService.generateDomesticQuote(formValue);
        }
        generateQuote.subscribe(function (result) {
            _this.estimatedCost = result.price;
            _this.quoteCurrency = result.currency;
            console.log(result);
            _this.quoteResult = new QuoteResult({
                fromCity: _this.selectedFromCityName,
                icon: _this.quoteIcon,
                direction: _this.selectedDirectionName,
                toCity: _this.selectedToCityName,
                estimatedCost: _this.estimatedCost,
                currency: _this.quoteCurrency,
                shippingType: _this.shippingTypeName,
                weight: _this.quoteForm.get('weight').value,
                width: _this.quoteForm.get('width').value,
                height: _this.quoteForm.get('height').value,
                length: _this.quoteForm.get('length').value
            });
            if (_this.neededServiceType) {
                _this.quoteResult.serviceType = ServiceType[_this.quoteForm.get('serviceTypeId').value];
            }
            _this.quoteCalculated = true;
            _this.formChanged = false;
            _this.loading = false;
        });
    };
    QuotesQuickQuoteComponent.prototype.numberOnly = function (event) {
        var charCode = event.which ? event.which : event.keyCode;
        var invalidChars = ['-', '+', 'e'];
        var valid = ['.'];
        if (valid.includes(event.key)) {
            return true;
        }
        if (charCode > 31 && (charCode < 48 || charCode > 57) && invalidChars.includes(event.key)) {
            return false;
        }
        return true;
    };
    QuotesQuickQuoteComponent.prototype.goToPlaceAnOrder = function () {
        var _this = this;
        this.loading = true;
        var quoteData = new QuoteData(this.quoteForm.value);
        quoteData.fromCity = this.selectedFromCityName;
        quoteData.toCity = this.selectedToCityName;
        quoteData.shippingType = this.shippingTypeName;
        quoteData.quotePrice = this.estimatedCost;
        quoteData.quoteCurrency = this.quoteCurrency;
        var currencySelected = this.quoteCurrency;
        var currencyType = CurrencyType[currencySelected.toString()];
        quoteData.quoteCurrencyId = currencyType;
        quoteData.type = this.getDirection();
        quoteData.productTypeId = ServiceType.Parcels;
        if (quoteData.type === ServiceDirection.Domestic) {
            quoteData.fromCountryId = this.domesticCountryId;
            quoteData.toCountryId = this.domesticCountryId;
        }
        this._quoteService.addQuote(quoteData).subscribe(function (result) {
            quoteData.quoteId = result.quoteId;
            var navigationExtras = {
                state: quoteData
            };
            _this.loading = false;
            if (!_this._authenticationService.isLoggedIn()) {
                _this._router.navigate([Routes.newAnonymousOrder], navigationExtras);
            }
            else {
                _this._router.navigate([Routes.newOrder], navigationExtras);
            }
        }, function (error) {
            _this.loading = false;
            alert('Error while adding quote');
        });
    };
    return QuotesQuickQuoteComponent;
}());
export { QuotesQuickQuoteComponent };
