import { ContactsContactListComponent } from './contact-list/contact-list.component';
import { AuthGuard } from 'app/_guards/auth.guard';
import { ContactsContactListService } from './contact-list/contact-list.service';
var ɵ0 = ContactsContactListService;
var routes = [
    {
        path: 'contacts',
        canActivate: [AuthGuard],
        component: ContactsContactListComponent,
        resolve: {
            data: ɵ0
        }
    }
];
var ContactsModule = /** @class */ (function () {
    function ContactsModule() {
    }
    return ContactsModule;
}());
export { ContactsModule };
export { ɵ0 };
