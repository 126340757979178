import { OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { ServiceDirection } from 'app/_enums/ServiceDirection.enum';
import { FormBuilder, FormControl } from '@angular/forms';
import { Routes } from 'app/Routes';
import { takeUntil } from 'rxjs/operators';
import { AuthenticationService } from 'app/authentication/authentication.service';
import { Location } from '@angular/common';
import { QuoteData } from 'app/main/orders/new-order/quote-data.model';
import { DistributionsOrderDetailsComponent } from './distribution-details/distribution-details.component';
import { DistributionService } from '../distribution.service';
import { DistributionDetail } from './distribution.model';
import { OrderService } from 'app/main/orders/order.service';
var NewDistributionComponent = /** @class */ (function () {
    function NewDistributionComponent(_router, _formBuilder, _distributionService, _activatedRoute, _authService, _orderService, _location) {
        var _this = this;
        this._router = _router;
        this._formBuilder = _formBuilder;
        this._distributionService = _distributionService;
        this._activatedRoute = _activatedRoute;
        this._authService = _authService;
        this._orderService = _orderService;
        this._location = _location;
        this._unsubscribeAll = new Subject();
        this.ServiceDirection = ServiceDirection;
        this.step = PanelOption.MyInfo;
        this.PanelOption = PanelOption;
        this.hasSubsidiaryId = true;
        this.imageUploadLoading = false;
        this.requiredUploadIdImage = false;
        this.newQuote = new FormControl(true);
        var navigation = this._router.getCurrentNavigation();
        var state = navigation.extras.state;
        this.data = state || new QuoteData();
        this.distributionDetailGroup = this._formBuilder.group(new DistributionDetail());
        this.distributionDetailGroup.setControl('quote', this._formBuilder.group(this.data));
        this.distributionDetailGroup.setControl('quoteId', this._formBuilder.control(this.data.quoteId));
        // this.billingDetailsGroup = this._formBuilder.group(new BillingDetails());
        this._activatedRoute.params.subscribe(function (params) {
            var id = params.id;
            if (id === 'new') {
                _this.distributionId = 0;
            }
            else {
                _this.distributionId = +id;
                _this.mode = 'edit';
            }
        });
        var t = new Promise(function (resolve) {
            _this.loadScript();
            resolve(true);
        });
    }
    Object.defineProperty(NewDistributionComponent.prototype, "orderDetail", {
        get: function () {
            var order = new DistributionDetail(this.distributionDetailGroup.getRawValue());
            order.shipperName = this.orderDetailComponent.contacts.find(function (c) { return c.contactId === order.shipperId; }).fullName;
            // order.receiverName = this.orderDetailComponent.contacts.find(c => c.contactId === order.receiverId).fullName;
            order.shipperAddress = this.orderDetailComponent.toLongDisplayString(this.orderDetailComponent.shipperAddresses.find(function (c) { return c.addressId === order.shipperAddressId; }));
            // order.receiverAddress = this.orderDetailComponent.toLongDisplayString(
            //     this.orderDetailComponent.receiverAddresses.find(c => c.addressId === order.receiverAddressId)
            // );
            order.distributionId = this.distributionId;
            return order;
        },
        enumerable: true,
        configurable: true
    });
    NewDistributionComponent.prototype.ngOnInit = function () {
        var _this = this;
        // this._authService.hasSubsidiaryId().subscribe(result => {
        //     this.hasSubsidiaryId = result.status;
        //     this._orderService.getMyInfo().subscribe((info: BillingDetails) => {
        //         this.billingDetailsGroup = this._formBuilder.group(info);
        //         this.firstTime = !this.billingDetailsGroup.get('confirmedPhoneNumber').value;
        //         if (this.firstTime) {
        //             this.step = PanelOption.MyInfo;
        //         }
        //     });
        //     if (this.hasSubsidiaryId) {
        //         this.step = PanelOption.OrderDetails;
        //     }
        // });
        this.step = PanelOption.OrderDetails;
        if (this.mode === 'edit') {
            this.initEditMode(this._distributionService.selectedOrder);
        }
        this._distributionService.onDistributionSelected.pipe(takeUntil(this._unsubscribeAll)).subscribe(function (order) {
            _this.initEditMode(order);
        });
        this.data.type = this.getDirection(this.data.fromCountryId, this.data.toCountryId);
        if (this.data && this.mode === 'new') {
            var orderDetail = new DistributionDetail({ quoteId: this.data.quoteId, quote: this.data });
            this.distributionDetailGroup = this._formBuilder.group(orderDetail);
            this.distributionDetailGroup.setControl('quote', this._formBuilder.group(this.data));
        }
        this._distributionService.onDistributionAdded.subscribe(function (orderId) {
            if (_this.distributionId === 0) {
                // this.billingDetailsGroup.get('orderId').setValue(orderId);
                _this.distributionDetailGroup.get('distributionId').setValue(orderId);
                _this.distributionId = orderId;
            }
        });
    };
    NewDistributionComponent.prototype.getDirection = function (fromCountryId, toCountryId) {
        if (!fromCountryId || !toCountryId) {
            return undefined;
        }
        if (fromCountryId === 158 /* Lebanon */ && toCountryId === 158 /* Lebanon */) {
            // domestic
            return ServiceDirection.Domestic;
        }
        else if (fromCountryId === 158 /* Lebanon */) {
            // Export
            return ServiceDirection.Export;
        }
        else if (toCountryId === 158 /* Lebanon */) {
            // Import
            return ServiceDirection.Import;
        }
        else {
            // Not Supported
            return undefined;
        }
    };
    NewDistributionComponent.prototype.initEditMode = function (distribution) {
        if (distribution === null) {
            return;
        }
        if (this.distributionId === 0) {
            this._router.navigateByUrl(Routes.orders);
        }
        distribution.detail.quote.type = this.getDirection(distribution.detail.quote.fromCountryId, distribution.detail.quote.toCountryId);
        this.distributionDetailGroup.patchValue(distribution.detail);
        this.distributionDetailGroup.get('quote').patchValue(distribution.detail.quote);
        // this.billingDetailsGroup.patchValue(order.billingDetails);
    };
    NewDistributionComponent.prototype.ngOnDestroy = function () {
        this._distributionService.onDistributionSelected.complete();
        this._distributionService.onDistributionAdded.complete();
        this._distributionService.selectedOrder = null;
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    };
    NewDistributionComponent.prototype.setStep = function (option) {
        this.step = option;
    };
    NewDistributionComponent.prototype.nextStep = function (option) {
        this.step = option;
    };
    NewDistributionComponent.prototype.prevStep = function (option) {
        this.step = option;
    };
    NewDistributionComponent.prototype.goBackFromPayment = function () {
        var quote = this.distributionDetailGroup.value.quote;
        var direction = this.getDirection(quote.fromCountryId, quote.toCountryId);
        if (!this.hasSubsidiaryId) {
            this.prevStep(PanelOption.MyInfo);
        }
        else if (this.hasSubsidiaryId && direction === ServiceDirection.Export && this.requiredUploadIdImage) {
            this.prevStep(PanelOption.UploadIDPhoto);
        }
        else {
            this.prevStep(PanelOption.OrderDetails);
        }
    };
    NewDistributionComponent.prototype.saveOrderDetails = function () {
        var _this = this;
        var data = this.distributionDetailGroup.getRawValue();
        data.distributionId = this.distributionId;
        if (this.distributionId === 0) {
            this._distributionService.addDistributionDetails(data).then(function (result) {
                _this.afterSaveOrder(result);
            });
        }
        else if (this.distributionId > 0) {
            this._distributionService.updateDistributionDetails(data).then(function (result) {
                _this.afterSaveOrder(result);
            });
        }
    };
    NewDistributionComponent.prototype.confirmOrderDetails = function () {
        var _this = this;
        var data = this.distributionDetailGroup.getRawValue();
        data.distributionId = this.distributionId;
        if (this.distributionId === 0) {
            this._distributionService.addDistributionDetails(data).then(function (result) {
                _this.afterSaveConfirmOrder(result);
            });
        }
        else if (this.distributionId > 0) {
            this._distributionService.updateDistributionDetails(data).then(function (result) {
                // localStorage.setItem('distributionId', result.distributionId.toString());
                _this.afterSaveConfirmOrder(result);
            });
        }
    };
    NewDistributionComponent.prototype.afterSaveConfirmOrder = function (result) {
        var quoteForm = this.distributionDetailGroup.get('quote');
        quoteForm.get('quoteId').setValue(result.quoteId, { emitEvent: false });
        this.distributionDetailGroup.get('quoteId').setValue(result.quoteId, { emitEvent: false });
        this.distributionId = result.distributionId;
        if (this.requiredUploadIdImage) {
            this.nextStep(PanelOption.UploadIDPhoto);
        }
        else {
            this.nextStep(PanelOption.Payment);
        }
        this._location.replaceState('/distributions/' + this.distributionId);
    };
    NewDistributionComponent.prototype.afterSaveOrder = function (result) {
        var quoteForm = this.distributionDetailGroup.get('quote');
        quoteForm.get('quoteId').setValue(result.quoteId, { emitEvent: false });
        this.distributionDetailGroup.get('quoteId').setValue(result.quoteId, { emitEvent: false });
        this.distributionId = result.distributionId;
        if (this.requiredUploadIdImage) {
            this.nextStep(PanelOption.UploadIDPhoto);
        }
        else {
            this.nextStep(PanelOption.Payment);
        }
        this._location.replaceState('/distributions/' + this.distributionId);
    };
    NewDistributionComponent.prototype.saveBillingDetails = function () {
        var _this = this;
        this._distributionService.addBillingDetails(this.billingDetailsGroup.value).then(function (user) {
            _this._authService.onUserInfoUpdated.next(user);
            _this.nextStep(PanelOption.OrderDetails);
            // update list of contacts to display the new Home contact (if created)
            _this.orderDetailComponent.getContactsForOrders(false);
        });
    };
    NewDistributionComponent.prototype.uploadIDPhoto = function (files) {
        var _this = this;
        this.message = '';
        if (files.length === 0) {
            return;
        }
        var mimeType = files[0].type;
        if (mimeType.match(/image\/*/) == null) {
            this.message = 'Only images are supported.';
            this.imgURL = null;
            return;
        }
        var reader = new FileReader();
        this.imagePath = files;
        reader.readAsDataURL(files[0]);
        reader.onload = function (_event) {
            _this.imgURL = reader.result;
        };
    };
    NewDistributionComponent.prototype.uploadIdPhotoAndContinue = function () {
        var _this = this;
        if (!this.imagePath) {
            this.message = 'You need to upload your ID';
            return;
        }
        this.imageUploadLoading = true;
        this._authService.uploadUserIDPhoto(this.imagePath[0]).subscribe(function (user) {
            _this.imageUploadLoading = false;
            _this.nextStep(PanelOption.Payment);
        }, function (error) {
            _this.message = 'Error while uploading your image ID to the server.';
            _this.imageUploadLoading = false;
        });
    };
    NewDistributionComponent.prototype.goBackFromUploadId = function () {
        this.prevStep(PanelOption.OrderDetails);
    };
    NewDistributionComponent.prototype.loadScript = function () {
        var isFound = false;
        var scripts = document.getElementsByTagName('script');
        // tslint:disable-next-line: prefer-for-of
        for (var i = 0; i < scripts.length; ++i) {
            if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').includes('checkout.js')) {
                isFound = true;
            }
        }
        if (!isFound) {
            var node = document.createElement('script');
            node.src = 'https://ap-gateway.mastercard.com/checkout/version/51/checkout.js';
            node.type = 'text/javascript';
            node.async = false;
            node.setAttribute('data-cancel', 'cancelCallback');
            node.setAttribute('data-error', 'errorCallback');
            node.setAttribute('data-complete', window.location.origin + '/orders/payments/redirect');
            document.getElementsByTagName('head')[0].appendChild(node);
        }
    };
    return NewDistributionComponent;
}());
export { NewDistributionComponent };
export var PanelOption;
(function (PanelOption) {
    PanelOption[PanelOption["OrderDetails"] = 0] = "OrderDetails";
    PanelOption[PanelOption["MyInfo"] = 1] = "MyInfo";
    PanelOption[PanelOption["UploadIDPhoto"] = 2] = "UploadIDPhoto";
    PanelOption[PanelOption["Payment"] = 3] = "Payment";
})(PanelOption || (PanelOption = {}));
