import { AuthGuard } from 'app/_guards/auth.guard';
import { ContactsListComponent } from './c-list/c-list.component';
import { ContactsListService } from './c-list/c-list.service';
var ɵ0 = ContactsListService;
var routes = [
    {
        path: 'contactList',
        canActivate: [AuthGuard],
        component: ContactsListComponent,
        resolve: {
            data: ɵ0
        }
    }
];
var ContactsListModule = /** @class */ (function () {
    function ContactsListModule() {
    }
    return ContactsListModule;
}());
export { ContactsListModule };
export { ɵ0 };
