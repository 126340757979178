import { AuthGuard } from 'app/_guards/auth.guard';
import { DistributionService } from './distribution.service';
import { DistributionListService } from './list/distribution-list.service';
import { DistributionListComponent } from './list/distribution-list.component';
import { NewDistributionComponent } from './new-distribution/new-distribution.component';
import { DistributionViewComponent } from './view/distribution-view.component';
import { DistributionViewService } from './view/distribution-view.service';
import { DistributionManifestComponent } from './manifest/manifest.component';
import { DistributionInvoiceComponent } from './distribution-invoice/distribution-invoice.component';
var ɵ0 = DistributionListService, ɵ1 = DistributionService, ɵ2 = DistributionViewService, ɵ3 = DistributionViewService, ɵ4 = DistributionViewService;
var routes = [
    {
        path: 'distributions',
        canActivate: [AuthGuard],
        component: DistributionListComponent,
        resolve: {
            data: ɵ0
        }
    },
    {
        path: 'distributions/:id',
        canActivate: [AuthGuard],
        component: NewDistributionComponent,
        resolve: {
            data: ɵ1
        }
    },
    // {
    //     path: 'distributions/:id',
    //     canActivate: [AuthGuard],
    //     component: NewDistributionComponent,
    //     resolve: {
    //         data: DistributionService
    //     }
    // },
    {
        path: 'distributions/view/:id',
        canActivate: [AuthGuard],
        component: DistributionViewComponent,
        resolve: {
            data: ɵ2
        }
    },
    {
        path: 'distributions/print/:id/:id2',
        canActivate: [AuthGuard],
        component: DistributionInvoiceComponent,
        resolve: {
            data: ɵ3
        }
    },
    {
        path: 'distributions/manifest/:id',
        canActivate: [AuthGuard],
        component: DistributionManifestComponent,
        resolve: {
            data: ɵ4
        }
    }
];
var ɵ5 = { duration: 3000 };
var DistributionModule = /** @class */ (function () {
    function DistributionModule() {
    }
    return DistributionModule;
}());
export { DistributionModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5 };
