import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ServiceDirection, MyNetServiceType } from 'app/_enums/ServiceDirection.enum';
import { Address } from 'app/main/contacts/contact-form/contact.model';
import { ContactsContactListService } from 'app/main/contacts/contact-list/contact-list.service';
import { FormGroup, FormControl, Validators, FormBuilder, NgForm } from '@angular/forms';
import { OrderDetail, CashOnDelivery, Piece, OrderSku } from '../order.model';
import { scan, takeUntil } from 'rxjs/operators';
import { InternationalQuote } from 'app/main/quotes/international/international.model';
import { ServiceType, CurrencyType } from 'app/_enums/ServiceType.enum';
import { QuoteService } from 'app/main/quotes/quote.service';
import { NetPointLocationsComponent } from '../netpoint-locations/netpoint-locations.component';
import { NetPointService } from '../netpoint-locations/netpoint.services';
import { OrderService } from '../../order.service';
import { ContactEditorComponent } from 'app/main/contacts/contact-editor/contact-editor.component';
import { CommodityService } from 'app/_shared/commodity.service';
import { WeightValidator } from 'app/_validators/weight.validator';
import { DecimalPipe } from '@angular/common';
import { DecimalValidator } from 'app/_validators/decimal.validator';
import { Router } from '@angular/router';
import { ConfirmOrderComponent } from './confirm-order/confirm-order.component';
import { Routes } from 'app/Routes';
import { LocationService } from 'app/_shared/location-service.service';
import { ConfirmDimesionsComponent } from 'app/main/profile/confirm-dimensions/confirm-dimensions';
import { isNullOrUndefined } from 'util';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DateAdapter } from '@angular/material/core';
import { StatusValidator } from 'app/_validators/status.validator';
import { SkuValidator } from 'app/_validators/sku.validator';
import { CurrencyValidator } from 'app/_validators/currency.validator';
var OrdersOrderDetailsComponent = /** @class */ (function () {
    function OrdersOrderDetailsComponent(_router, _contactService, _matDialog, _quoteService, _commodityService, _orderService, _netpointService, _snackBar, _numberPipe, _locationService, _formBuilder, dateAdapter) {
        this._router = _router;
        this._contactService = _contactService;
        this._matDialog = _matDialog;
        this._quoteService = _quoteService;
        this._commodityService = _commodityService;
        this._orderService = _orderService;
        this._netpointService = _netpointService;
        this._snackBar = _snackBar;
        this._numberPipe = _numberPipe;
        this._locationService = _locationService;
        this._formBuilder = _formBuilder;
        this.dateAdapter = dateAdapter;
        this.hasSubsidiaryId = true;
        this.submited = new EventEmitter(true);
        this.canceled = new EventEmitter(true);
        this.confirmed = new EventEmitter(true);
        this.contacts = [];
        this.shipperAddresses = [];
        this.receiverAddresses = [];
        this.AddressType = AddressType;
        this.ServiceDirection = ServiceDirection;
        this.addingShipper = false;
        this.addingReceiver = false;
        this.isExport = false;
        this.generatingQuote = false;
        this.confirmingOrder = false;
        this.loadingShippingTypes = false;
        this.ServiceType = ServiceType;
        this._onInitShipper = false;
        this._onInitReceiver = false;
        this._unsubscribeAll = new Subject();
        this.isNetPointShipper = false;
        this.isNetPointReceiver = false;
        this.minDate = new Date(new Date().setDate(new Date().getDate()));
        this.toNetPointButtonText = 'To NetPoint?';
        this.fromNetPointButtonText = 'From NetPoint?';
        this.shipperAddressLabel = 'Shipper Address';
        this.receiverAddressLabel = 'Receiver Address';
        this.showDimensionsHelp = false;
        // orderCod: CashOnDelivery[] = [];
        this.orderSurcharges = [];
        // surchargesFromOrder: OrderSurcharges[];
        this.proformaPanelState = true;
        this.editIndex = [];
        this.currencyTypes = {};
        this.formLoaded = false;
        this.domesticCountryId = 158;
        this.domesticCountryName = "Lebanon";
        this.contactDisplayCount = 5;
        this.shipperFilterCtrl = new FormControl();
        this.receiverFilterCtrl = new FormControl();
        // public filteredContacts: ReplaySubject<ContactListItem[]> = new ReplaySubject<ContactListItem[]>();
        this.shipperOptions = new BehaviorSubject([]);
        this.receiverOptions = new BehaviorSubject([]);
        this.limit = 10;
        this.offset = 0;
        this.disableEdit = false;
        this.disableCOD = false;
        this.isEdit = false;
        this.showShipmentValue = true;
        this.specialAccount = false;
        this.isMFERP = false;
        this.shipperOptions$ = this.shipperOptions.asObservable().pipe(scan(function (acc, curr) {
            return acc.concat(curr);
        }, []));
        this.receiverOptions$ = this.receiverOptions.asObservable().pipe(scan(function (acc, curr) {
            return acc.concat(curr);
        }, []));
    }
    Object.defineProperty(OrdersOrderDetailsComponent.prototype, "groupValue", {
        get: function () {
            return this.group.getRawValue();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrdersOrderDetailsComponent.prototype, "selectedShipper", {
        get: function () {
            var _this = this;
            return this.contacts.find(function (x) { return x.contactId === _this.groupValue.shipperId; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrdersOrderDetailsComponent.prototype, "selectedReceiver", {
        get: function () {
            var _this = this;
            return this.contacts.find(function (x) { return x.contactId === _this.groupValue.receiverId; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrdersOrderDetailsComponent.prototype, "selectedShipperAddress", {
        get: function () {
            var _this = this;
            return this.shipperAddresses.find(function (x) { return x.addressId === _this.groupValue.shipperAddressId; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrdersOrderDetailsComponent.prototype, "orderDetail", {
        get: function () {
            var order = new OrderDetail(this.group.getRawValue());
            order.quote.productTypeId = ServiceType.Parcels;
            order.shipperName = this.contacts.find(function (c) { return c.contactId === order.shipperId; }).fullName;
            order.receiverName = this.contacts.find(function (c) { return c.contactId === order.receiverId; }).fullName;
            order.shipperAddress = this.toLongDisplayString(this.shipperAddresses.find(function (c) { return c.addressId === order.shipperAddressId; }));
            order.receiverAddress = this.toLongDisplayString(this.receiverAddresses.find(function (c) { return c.addressId === order.receiverAddressId; }));
            return order;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrdersOrderDetailsComponent.prototype, "quoteCods", {
        get: function () {
            return this.group.get('quote').get('cods').controls;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrdersOrderDetailsComponent.prototype, "orderPieces", {
        get: function () {
            return this.group.get('quote').get('pieces').controls;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrdersOrderDetailsComponent.prototype, "selectedReceiverAddress", {
        get: function () {
            var _this = this;
            return this.receiverAddresses.find(function (x) { return x.addressId === _this.groupValue.receiverAddressId; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrdersOrderDetailsComponent.prototype, "quoteSkus", {
        get: function () {
            return this.group.get('quote').get('skus').controls;
        },
        enumerable: true,
        configurable: true
    });
    OrdersOrderDetailsComponent.prototype.creatCodForm = function (element) {
        return this._formBuilder.group({
            quoteId: this.group.get('quoteId'),
            codAmount: [element.codAmount, Validators.required],
            codCurrencyId: element.codCurrencyId
        });
    };
    OrdersOrderDetailsComponent.prototype.createCodArray = function () {
        var _this = this;
        var arr = [];
        this.group.get('quote').get('cods').value.forEach(function (element) {
            arr.push(_this.creatCodForm(element));
        });
        var quoteForm = this.group.get('quote');
        quoteForm.setControl('cods', this._formBuilder.array(arr));
    };
    OrdersOrderDetailsComponent.prototype.createSkuForm = function (element) {
        var skuSelected = this.wmsSKUs.filter(function (x) { return x.name == element.sku; })[0];
        return this._formBuilder.group({
            sku: [element.sku, Validators.required],
            quantity: [element.quantity, [Validators.max(skuSelected.currentStock), Validators.min(1)]],
            available: skuSelected.currentStock
        });
    };
    OrdersOrderDetailsComponent.prototype.createSkuArray = function () {
        var _this = this;
        var arr = [];
        var count = this.group.get('quote').get('skus').value.length;
        this.group.get('quote').get('skus').value.forEach(function (element) {
            arr.push(_this.createSkuForm(element));
        });
        var quoteForm = this.group.get('quote');
        quoteForm.setControl('skus', this._formBuilder.array(arr));
        // for (let i = 0; i < count; i++) {
        //     arr.push(this.createItem());
        // }
        // const skuSelected = this.wmsSKUs.filter(x=> x.name == event.value)[0];
        // this.availableQuantity = skuSelected.currentStock;
        // this.sku.get('quantity').setValidators([Validators.max(this.availableQuantity), Validators.min(1)]);
    };
    OrdersOrderDetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        console.log(this.group);
        this.loggedInUser = JSON.parse(localStorage.getItem('loggedInUser'));
        // console.log(this.group.get('quote').get('cods').value.length);
        this.userRole = localStorage.getItem('role');
        // console.log(this.group.get('quote').get('skus').value);
        // console.log(this.quoteSkus);
        if (this.loggedInUser.countryId === 90) {
            this.domesticCountryId = 90;
        }
        // const skus = this.group.get('quote').get('skus')
        // if (skus.value.length > 0) {
        //     skus.value.forEach(element => {
        //         this.orderSKUs = new FormGroup({
        //             sku: new FormControl(element.sku),
        //             quantity: new FormControl(element.quantity),
        //         });
        //         // console.log(form);
        //         // this.orderSKUs.patchValue(form);
        //     });
        // }
        // console.log(this.orderSKUs);
        // this.group.get('quote').get('skus').setValue(this.orderSKUs);
        // console.log(this.group.get('quote').get('skus') as FormArray);
        // const form = new FormGroup({
        //     sku: new FormControl('sku'),
        //     last: new FormControl('Drew'),
        //   });
        this._onInitReceiver = true;
        this._onInitShipper = true;
        this.isNetPointShipper = this.group.get('isNetPointShipper').value;
        this.isNetPointReceiver = this.group.get('isNetPointReceiver').value;
        this.getAllContacts();
        this.loadSurcharges();
        console.log('getting time ranges');
        this.getTimeRanges();
        this.subscribeShipperChanged();
        this.subscribeReceiverChanged();
        this.subscribeContactsChanged();
        this.subscribeContactAdded();
        this.subscribeShipperAddressChanged();
        this.subscribeReceiverAddressChanged();
        this.subscribeDirectionChanged();
        this.subscribeShippingTypeChanged();
        this.subscribeQuoteChanged();
        this.subscribeShipperReferenceChanged();
        this.setDirection();
        this.getCommodities();
        this.setDecimalValidation();
        this.getCountries();
        this.canUpdateOrder(this.group.get('orderId').value).then(function (canUpdate) {
            if (canUpdate == null) {
                _this.disableEdit = null;
                return;
            }
            _this.disableEdit = !canUpdate;
            var pickupDate = _this.group.get('pickupDate').value;
            _this.minDate = pickupDate;
        });
        this.getCodAmounts();
        // this.setValidations();
        // this.shipperFilterCtrl.valueChanges
        //     .pipe(takeUntil(this._unsubscribeAll))
        //     .subscribe(() => {
        //         this.getNextShipperBatch("");
        //     });
        // this.receiverFilterCtrl.valueChanges
        //     .pipe(takeUntil(this._unsubscribeAll))
        //     .subscribe(() => {
        //         this.getNextReceiverBatch();
        //     });
        this.calculateChargeableWeight();
        this.group.get('quote').get('chargeableWeight').disable();
        // this.group.get('quote').get('numberofpieces').setValidators([Validators.min(0),Validators.max(20) , Validators.max(30)])
        this.currencyTypes = this.enumSelector(CurrencyType).slice(7, 13);
        this.formLoaded = true;
        this.dateAdapter.setLocale('en-GB');
        this._orderService.getMyInfo().subscribe(function (info) {
            _this.domesticCountryId = info.countryId;
            _this.domesticCountryName = info.countryName;
            _this.myInfo = info;
            console.log('getMyInfo');
            console.log(_this.myInfo);
        });
        var quoteCountry = this.group.get('quote').get('toCountryId').value;
        if (!isNullOrUndefined(quoteCountry))
            if (quoteCountry != this.domesticCountryId && quoteCountry != 0)
                this.isExport = true;
        var storerKey = localStorage.getItem('netPointKey');
        var quoteForm = this.group.get('quote');
        this.fetchStock(storerKey, "all")
            .then(function () {
            if (_this.group.get('quote').get('skus').value != null)
                _this.createSkuArray();
            else
                quoteForm.setControl('skus', _this._formBuilder.array([]));
        });
        if (this.group.get('quote').get('cods').value !== null && this.group.get('quote').get('cods').value.length > 0) {
            this.createCodArray();
            this.group.get('quote').get('cods').disable();
        }
        if (this.group.get('orderId').value !== 0) {
            this.isEdit = true;
        }
        if (this.isEdit && this.group.get('quote').get('cods').value.length === 0) {
            this.disableCOD = true;
        }
        this.specialAccount = this.loggedInUser.specialAccount === true ? true : false;
        this.isMFERP = this.loggedInUser.isMFERP;
        console.log(this.specialAccount);
        console.log(this.domesticCountryId);
        // if(this.canUpdateOrder(this.group.get('orderId').value)){
        //     this.disableEdit = true;
        //     // this.group.get('shipperId').disable();
        //     // this.group.get('shipperAddressId').disable();
        // }
        // else
        //     this.disableEdit = false;
    };
    OrdersOrderDetailsComponent.prototype.setValidations = function () {
        var quoteGroup = this.group.get('quote');
        quoteGroup.get('numberofpieces').setValidators([Validators.min(0), Validators.max(20), Validators.max(30)]);
    };
    OrdersOrderDetailsComponent.prototype.enumSelector = function (definition) {
        return Object.keys(definition)
            .map(function (key) { return ({ value: definition[key], title: key, disabled: false }); });
    };
    OrdersOrderDetailsComponent.prototype.calculateChargeableWeight = function () {
        var pieces = this.group.get('quote').get('pieces');
        var pieceTotalWeight = 0;
        var pieceTotalVolume = 0;
        for (var _i = 0, _a = pieces.controls; _i < _a.length; _i++) {
            var c = _a[_i];
            var pieceValue = c.value;
            if (this.currentDirection == ServiceDirection.Domestic && this.domesticCountryId !== 90 && this.domesticCountryId != 280) {
                pieceTotalVolume += ((pieceValue.length * pieceValue.width * pieceValue.height) / 4000) * pieceValue.numberOfPieces;
            }
            else
                pieceTotalVolume += ((pieceValue.length * pieceValue.width * pieceValue.height) / 5000) * pieceValue.numberOfPieces;
            pieceTotalWeight += pieceValue.weight;
        }
        if (pieceTotalWeight == 0)
            pieceTotalWeight = 0.5;
        if (pieceTotalVolume == 0)
            pieceTotalVolume = 0.5;
        if (pieceTotalWeight >= pieceTotalVolume)
            this.group.get('quote').get('chargeableWeight').setValue(pieceTotalWeight);
        else
            this.group.get('quote').get('chargeableWeight').setValue(pieceTotalVolume);
    };
    OrdersOrderDetailsComponent.prototype.onCheckBoxChanges = function (e, id) {
        this.newQuote.setValue(true);
        var index = this.orderSurcharges.findIndex(function (_) { return _.surchargeId == id; });
        if (!(index > -1))
            return;
        this.orderSurcharges[index].checked = e.checked;
        if (this.orderSurcharges[index].surchargeId == 3) //insurance
         {
            if (e.checked)
                this.group.get('quote').get('shipmentValue').setValidators(Validators.min(1));
            else
                this.group.get('quote').get('shipmentValue').setValidators(null);
            this.group.get('quote').get('shipmentValue').updateValueAndValidity();
        }
    };
    OrdersOrderDetailsComponent.prototype.setDecimalValidation = function () {
        var quoteGroup = this.group.get('quote');
        var length = quoteGroup.get('length');
        var width = quoteGroup.get('width');
        var height = quoteGroup.get('height');
        if (this.currentDirection == ServiceDirection.Domestic) {
            length.setValidators([DecimalValidator.validDecimal(0, 200)]);
            width.setValidators([DecimalValidator.validDecimal(0, 150)]);
            height.setValidators([DecimalValidator.validDecimal(0, 150)]);
        }
        else {
            length.setValidators([DecimalValidator.validDecimal(0, 150)]);
            width.setValidators([DecimalValidator.validDecimal(0, 150)]);
            height.setValidators([DecimalValidator.validDecimal(0, 150)]);
        }
        var shipmentValue = quoteGroup.get('shipmentValue');
        shipmentValue.setValidators([DecimalValidator.validDecimal(0, 9999999999999999)]);
    };
    OrdersOrderDetailsComponent.prototype.addNewCod = function () {
        var cods = this.group.get('quote').get('cods');
        console.log(cods);
        var cod = this._formBuilder.group(new CashOnDelivery());
        // cod.setValidators();
        cod.get('codAmount').setValidators(Validators.min(1));
        cod.get('codCurrencyId').setValidators(Validators.min(1));
        if (cods.length > 0) {
            console.log(cods.at(cods.length - 1).get('codCurrencyId'));
            cod.get('codCurrencyId').setValidators([Validators.min(1), CurrencyValidator.validCurrency(cods.at(cods.length - 1).get('codCurrencyId').value)]);
            cod.get('codCurrencyId').setValue(2);
        }
        else {
            cod.get('codCurrencyId').setValue(1);
        }
        cods.push(cod);
        console.log(cods);
    };
    OrdersOrderDetailsComponent.prototype.codAdded = function (data) {
        var codForm = data.value;
        var mode = data.mode;
        var index = data.index;
        this.group.get('quote').setValidators(StatusValidator.validStatus("disabled"));
        if (mode === 'cancel') {
            // if (index >= 0) {
            //     this.editIndex[index] = false;
            // }
            return;
        }
        else if (mode === 'new') {
            // const pieces = this.group.get('quote').get('pieces') as FormArray;
            // pieces.at(index - 1).setValue(pieceForm);
            // var TotalWeight = 0, TotalNOP = 0;
            // for (let c of pieces.controls) {
            //     const pieceValue = c.value as Piece;
            //     TotalWeight += pieceValue.weight;
            //     TotalNOP += pieceValue.numberOfPieces;
            // }
            // if (TotalWeight == 0)
            //     TotalWeight = 0.5
            // if (TotalNOP == 0)
            //     TotalNOP = 1
            // this.group.get('quote').get('weight').setValue(TotalWeight);
            // this.group.get('quote').get('numberOfPieces').setValue(TotalNOP);
        }
        else if (mode === 'delete') {
            console.log(this.group.get('quote'));
            // const pieces = this.quoteForm.get('pieces') as FormArray;
            // pieces.removeAt(index - 1);
            // var TotalWeight = 0, TotalNOP = 0;
            // for (let c of pieces.controls) {
            //     const pieceValue = c.value as Piece;
            //     TotalWeight += pieceValue.weight;
            //     TotalNOP += pieceValue.numberOfPieces;
            // }
            // if (TotalWeight == 0)
            //     TotalWeight = 0.5
            // this.group.get('quote').get('weight').setValue(TotalWeight);
            // if (TotalNOP == 0)
            //     TotalNOP = 1;
            // this.group.get('quote').get('numberOfPieces').setValue(TotalNOP);
            // if (pieceForm != undefined && pieceForm.pieceId != 0) {
            //     this._quoteService.deletePieces(pieceForm.pieceId)
            // }
            // if (pieces.controls.length == 0)
            //     pieces.clearValidators()
        }
        // this.calculateChargeableWeight();
    };
    OrdersOrderDetailsComponent.prototype.weightChanged = function (event) {
        var weight = +event.target.value;
        if (weight !== undefined && weight !== null && !isNaN(weight) && weight < 0.5) {
            this.group
                .get('quote')
                .get('weight')
                .setValue('0.5');
        }
    };
    OrdersOrderDetailsComponent.prototype.getTimeRanges = function () {
        var _this = this;
        this._orderService.getTimeRanges().subscribe(function (result) {
            _this.timeRanges = result;
            var timeRangeLookupId = _this.group.get('timeRangeLookupId').value;
            if (isNullOrUndefined(timeRangeLookupId)) {
                _this.group.get('timeRangeLookupId').setValue(_this.timeRanges[0].id);
            }
        });
    };
    OrdersOrderDetailsComponent.prototype.getCommodities = function () {
        var _this = this;
        var serviceTypeId;
        this.setDirection();
        switch (this.currentDirection) {
            case ServiceDirection.Domestic:
                serviceTypeId = MyNetServiceType.Domestic;
                break;
            case ServiceDirection.Import:
                serviceTypeId = MyNetServiceType.Import;
                break;
            case ServiceDirection.Export:
                serviceTypeId = MyNetServiceType.Export;
                break;
            default:
                serviceTypeId = 0;
                break;
        }
        if (!serviceTypeId) {
            return;
        }
        var forceGetCommodities = false;
        if (this._commodityService.commodities && this._commodityService.commodities.length > 0) {
            var sameServiceType = this._commodityService.commodities.filter(function (x) { return x.serviceTypeId === serviceTypeId; }).length > 0;
            if (sameServiceType) {
                this.commodities = this._commodityService.commodities;
            }
            else {
                forceGetCommodities = true;
            }
        }
        else {
            forceGetCommodities = true;
        }
        if (forceGetCommodities) {
            this._commodityService.getCommodities(serviceTypeId).then(function (result) {
                _this.commodities = result;
            });
        }
    };
    OrdersOrderDetailsComponent.prototype.getAllContacts = function (firstTime) {
        var _this = this;
        if (firstTime === void 0) { firstTime = true; }
        this._contactService.getContactsForOrders().then(function (contacts) {
            _this.contacts = contacts;
            if (_this.group.value.shipperId == null && firstTime) {
                var defaultShipper = _this.contacts.filter(function (x) { return x.isDefault == true; });
                if (defaultShipper.length > 0) {
                    _this.group.get('shipperId').setValue(defaultShipper[0].contactId);
                }
            }
            if (_this.group.value.receiverId && firstTime) {
                var result = _this.contacts.filter(function (x) { return x.contactId == _this.group.value.receiverId; }).slice(0, 0 + _this.limit);
                _this.receiverOptions.next(result);
                _this.getNextReceiverBatch();
                _this.receiverChanged(_this.group.value.receiverId);
            }
            else {
                _this.offset = 0;
                var result = _this.contacts.slice(_this.offset, _this.offset + _this.limit);
                _this.receiverOptions.next(result);
                _this.offset = _this.offset + _this.limit;
            }
            if (_this.group.value.shipperId && firstTime) {
                var result = _this.contacts.filter(function (x) { return x.contactId == _this.group.value.shipperId; }).slice(0, 0 + _this.limit);
                _this.shipperOptions.next(result);
                _this.getNextShipperBatch();
                _this.shipperChanged(_this.group.value.shipperId);
            }
            else {
                _this.offset = 0;
                var result = _this.contacts.slice(_this.offset, _this.offset + _this.limit);
                _this.shipperOptions.next(result);
                _this.offset = _this.offset + _this.limit;
            }
        });
    };
    OrdersOrderDetailsComponent.prototype.subscribeQuoteChanged = function () {
        var _this = this;
        var quoteGroup = this.group.get('quote');
        quoteGroup.valueChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe(function (_) {
            _this.newQuote.setValue(true);
        });
    };
    OrdersOrderDetailsComponent.prototype.subscribeShippingTypeChanged = function () {
        var _this = this;
        var quoteGroup = this.group.get('quote');
        quoteGroup
            .get('shippingTypeId')
            .valueChanges.pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (shippingTypeId) {
            if (shippingTypeId) {
                var shippingType = _this.shippingTypes.find(function (s) { return s.id === shippingTypeId; });
                quoteGroup.get('shippingType').setValue(shippingType.name);
            }
            else {
                quoteGroup.get('shippingType').setValue('');
            }
        });
    };
    OrdersOrderDetailsComponent.prototype.prepareShippingTypes = function (direction, serviceType) {
        var _this = this;
        if (direction === void 0) { direction = ServiceDirection.Domestic; }
        if (serviceType === void 0) { serviceType = ServiceType.Parcels; }
        // Direction is domestic since we are in domestic component
        // Service Type is always Parcels, no docs in Lebanon domestic
        this.loadingShippingTypes = true;
        this._quoteService.getShippingTypes(direction, serviceType).subscribe(function (result) {
            _this.shippingTypes = result;
            _this.loadingShippingTypes = false;
            if (_this.shippingTypes && (_this.shippingTypes.length === 1 || _this.shippingTypes.length === 2)) {
                var quoteGroup = _this.group.get('quote');
                quoteGroup.get('shippingTypeId').setValue(_this.shippingTypes[0].id);
                quoteGroup.get('shippingType').setValue(_this.shippingTypes[0].name);
                _this.getSurchargesFromTarif(_this.shippingTypes[0].id);
                if (_this.shippingTypes[0].id == 9)
                    _this.showShipmentValue = false;
                else
                    _this.showShipmentValue = true;
            }
            else {
                var quoteGroup = _this.group.get('quote');
                quoteGroup.get('shippingTypeId').setValue('');
                quoteGroup.get('shippingType').setValue('');
                _this.orderSurcharges = [];
            }
            console.log('this.shippingTypes');
            console.log(_this.shippingTypes);
        });
    };
    OrdersOrderDetailsComponent.prototype.subscribeDirectionChanged = function () {
        var _this = this;
        var quoteGroup = this.group.get('quote');
        quoteGroup
            .get('type')
            .valueChanges.pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (type) {
            _this.getCommodities();
            if (type === undefined) {
                return;
            }
            // if (this.currentDirection == type)
            //     return;
            _this.isExport = type === ServiceDirection.Export;
            // here
            quoteGroup.get('shippingType').setValue('');
            quoteGroup.get('shippingTypeId').setValue('');
            quoteGroup.get('shippingTypeId').markAsUntouched();
            //if export
            if (_this.isExport) {
                quoteGroup.addControl('serviceTypeId', new FormControl('', Validators.required));
                _this.shippingTypes = [];
                quoteGroup.get('shippingType').setValue('');
                var value = quoteGroup.get('serviceTypeId').value;
                // value is = to 0 <---
                if (value) {
                    _this.prepareShippingTypes(type, value);
                }
                quoteGroup.get('serviceTypeId').valueChanges.subscribe(function (serviceTypeId) {
                    _this.prepareShippingTypes(type, serviceTypeId);
                });
            }
            //if domestic
            else {
                quoteGroup.removeControl('serviceTypeId');
                _this.prepareShippingTypes();
            }
        });
    };
    OrdersOrderDetailsComponent.prototype.subscribeShipperAddressChanged = function () {
        var _this = this;
        this.group
            .get('shipperAddressId')
            .valueChanges.pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (addressId) {
            var quoteGroup = _this.group.get('quote');
            var problems = false;
            if (addressId) {
                var selectedAddress = _this.shipperAddresses.find(function (a) { return a.addressId === addressId; });
                if (selectedAddress) {
                    var address = _this.toLongDisplayString(selectedAddress);
                    if (_this.isNetPointShipper) {
                        quoteGroup.get('fromCity').setValue(selectedAddress.display + ', ' + address);
                    }
                    else {
                        quoteGroup.get('fromCity').setValue(address);
                    }
                    quoteGroup.get('fromCityId').setValue(selectedAddress.cityId);
                    quoteGroup.get('fromCountryId').setValue(selectedAddress.countryId);
                }
                else {
                    problems = true;
                }
            }
            else {
                problems = true;
            }
            if (problems) {
                quoteGroup.get('fromCity').setValue('');
                quoteGroup.get('fromCityId').setValue('');
                quoteGroup.get('fromCountryId').setValue('');
            }
            _this.updateDirection();
        });
    };
    OrdersOrderDetailsComponent.prototype.subscribeReceiverAddressChanged = function () {
        var _this = this;
        this.group
            .get('receiverAddressId')
            .valueChanges.pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (addressId) {
            var quoteGroup = _this.group.get('quote');
            var problems = false;
            if (addressId) {
                var selectedAddress = _this.receiverAddresses.find(function (a) { return a.addressId === addressId; });
                if (selectedAddress) {
                    var address = _this.toLongDisplayString(selectedAddress);
                    if (_this.isNetPointReceiver) {
                        quoteGroup.get('toCity').setValue(selectedAddress.display + ', ' + address);
                    }
                    else {
                        quoteGroup.get('toCity').setValue(address);
                    }
                    quoteGroup.get('toCityId').setValue(selectedAddress.cityId);
                    quoteGroup.get('toCountryId').setValue(selectedAddress.countryId);
                }
                else {
                    problems = true;
                }
            }
            else {
                problems = true;
            }
            if (problems) {
                quoteGroup.get('toCity').setValue('');
                quoteGroup.get('toCityId').setValue('');
                quoteGroup.get('toCountryId').setValue('');
            }
            _this.updateDirection();
        });
    };
    OrdersOrderDetailsComponent.prototype.updateDirection = function () {
        var quoteGroup = this.group.get('quote');
        var direction = quoteGroup.get('type').value;
        this.setDirection();
        if (!isNullOrUndefined(direction) && direction == this.currentDirection)
            return;
        quoteGroup.get('type').setValue(this.currentDirection);
    };
    OrdersOrderDetailsComponent.prototype.setDirection = function () {
        var quoteGroup = this.group.get('quote');
        var fromCountryId = quoteGroup.get('fromCountryId').value;
        var toCountryId = quoteGroup.get('toCountryId').value;
        if (!fromCountryId || !toCountryId) {
            return undefined;
        }
        if (fromCountryId === this.domesticCountryId && toCountryId === this.domesticCountryId) {
            // domestic
            this.maxWeight = WeightValidator.MaxDomesticWeight;
            this.currentDirection = ServiceDirection.Domestic;
        }
        else if (fromCountryId === this.domesticCountryId) {
            // Export
            this.maxWeight = WeightValidator.MaxExportWeight;
            this.currentDirection = ServiceDirection.Export;
        }
        else if (toCountryId === this.domesticCountryId) {
            // Import
            this.maxWeight = undefined;
            this.currentDirection = ServiceDirection.Import;
        }
        else {
            // Not Supported
            this.maxWeight = undefined;
            this.currentDirection = undefined;
        }
        var weight = quoteGroup.get('weight');
        weight.setValidators([WeightValidator.validWeight(this.currentDirection), Validators.required]);
        weight.updateValueAndValidity();
    };
    OrdersOrderDetailsComponent.prototype.toLongDisplayString = function (address) {
        var message = address.cityName + ', ' + address.countryName + ', ' + address.street;
        if (address.building) {
            message += ', ' + address.building;
        }
        if (address.floor) {
            message += ', ' + address.floor;
        }
        if (address.zipCode) {
            message += ', ' + address.zipCode;
        }
        if (address.landmark) {
            message += ', ' + address.landmark;
        }
        return message;
    };
    OrdersOrderDetailsComponent.prototype.subscribeShipperReferenceChanged = function () {
        var _this = this;
        this.group
            .get('quote')
            .get('shipperReference')
            .valueChanges.pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (shipperId) {
            _this.shipperReferenceChanged(shipperId);
        });
    };
    OrdersOrderDetailsComponent.prototype.shipperReferenceChanged = function (shipperReference) {
        var _this = this;
        shipperReference = shipperReference.replace("#", "@");
        shipperReference = shipperReference.replace("/", "|");
        if (shipperReference != "")
            this._contactService.IsShipperReferenceExists(shipperReference).then(function (exists) {
                if (exists.status)
                    if (exists.status || exists.find(function (element) { return element == shipperReference; })) {
                        _this.group
                            .get('quote')
                            .get('shipperReference').setErrors(({ 'exists': true }));
                    }
            });
    };
    OrdersOrderDetailsComponent.prototype.subscribeShipperChanged = function () {
        var _this = this;
        this.group
            .get('shipperId')
            .valueChanges.pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (shipperId) {
            _this.shipperChanged(shipperId);
        });
    };
    OrdersOrderDetailsComponent.prototype.shipperChanged = function (shipperId) {
        var _this = this;
        if (!this._onInitShipper) {
            this.group.get('shipperAddressId').reset();
            this.shippingTypes = [];
            this.resetShipperNetPoint();
        }
        else {
            this._onInitShipper = false;
        }
        this._contactService.getContact(shipperId).then(function (contact) {
            if (contact.addresses) {
                _this.shipperAddresses = contact.addresses.filter(function (item) { return item.countryId === _this.domesticCountryId; });
            }
            if (_this.group.get('isNetPointShipper').value) {
                if (_this._netpointService.netpoints && _this._netpointService.netpoints.length > 0) {
                    _this.selectShipperNetPoint(_this._netpointService.netpoints.slice());
                }
                else {
                    _this._netpointService.onNetPointsRetreived.subscribe(function (locations) {
                        _this.selectShipperNetPoint(locations.slice());
                    });
                }
            }
            else if (_this.shipperAddresses && _this.shipperAddresses.length === 1) {
                _this.group.get('shipperAddressId').setValue(_this.shipperAddresses[0].addressId);
            }
            // else
            //     this.group.get('shipperAddressId').setValue(undefined);
        });
    };
    OrdersOrderDetailsComponent.prototype.selectShipperNetPoint = function (locations) {
        var _this = this;
        var location = locations.find(function (l) { return l.addressId === _this.group.get('shipperAddressId').value; });
        this.resetShipperNetPoint();
        this.netpointSelect(location, AddressType.Shipper);
    };
    OrdersOrderDetailsComponent.prototype.subscribeReceiverChanged = function () {
        var _this = this;
        this.group
            .get('receiverId')
            .valueChanges.pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (receiverId) {
            _this.receiverChanged(receiverId);
        });
    };
    OrdersOrderDetailsComponent.prototype.receiverChanged = function (receiverId) {
        var _this = this;
        if (!this._onInitReceiver) {
            this.group.get('receiverAddressId').reset();
            this.shippingTypes = [];
            this.resetReceiverNetPoint();
        }
        else {
            this._onInitReceiver = false;
        }
        this._contactService.getContact(receiverId).then(function (contact) {
            _this.receiverAddresses = contact.addresses;
            if (_this.group.get('isNetPointReceiver').value) {
                if (_this._netpointService.netpoints && _this._netpointService.netpoints.length > 0) {
                    _this.selectReceiverNetPoint(_this._netpointService.netpoints.slice());
                }
                else {
                    _this._netpointService.onNetPointsRetreived.subscribe(function (locations) {
                        _this.selectReceiverNetPoint(locations.slice());
                    });
                }
            }
            else if (_this.receiverAddresses && _this.receiverAddresses.length === 1) {
                _this.group.get('receiverAddressId').setValue(_this.receiverAddresses[0].addressId);
            }
        });
    };
    OrdersOrderDetailsComponent.prototype.selectReceiverNetPoint = function (locations) {
        var _this = this;
        var location = locations.find(function (l) { return l.addressId === _this.group.get('receiverAddressId').value; });
        this.resetReceiverNetPoint();
        this.netpointSelect(location, AddressType.Receiver);
    };
    OrdersOrderDetailsComponent.prototype.subscribeContactAdded = function () {
        var _this = this;
        this._contactService.onContactAdded.subscribe(function (contactId) {
            if (_this.addingShipper) {
                _this.group.get('shipperId').setValue(contactId);
            }
            else if (_this.addingReceiver) {
                _this.group.get('receiverId').setValue(contactId);
            }
            _this.addingShipper = false;
            _this.addingReceiver = false;
        });
    };
    OrdersOrderDetailsComponent.prototype.subscribeContactsChanged = function () {
    };
    OrdersOrderDetailsComponent.prototype.submitForm = function () {
        if (this.group.invalid) {
            return;
        }
        this.group.get('isNetPointShipper').setValue(this.isNetPointShipper);
        this.group.get('isNetPointReceiver').setValue(this.isNetPointReceiver);
        this.saveCodAmounts(this.group.get('quoteId').value);
        this.submited.emit(5);
    };
    OrdersOrderDetailsComponent.prototype.confirmOrder = function () {
        if (this.group.invalid) {
            return;
        }
        this.orderDetail.orderId = this.group.get('orderId').value;
        this.group.get('isNetPointShipper').setValue(this.isNetPointShipper);
        this.group.get('isNetPointReceiver').setValue(this.isNetPointReceiver);
        this.ConfirmDialog();
    };
    OrdersOrderDetailsComponent.prototype.ConfirmDialog = function () {
        var _this = this;
        if (!this._matDialog.openDialogs || this._matDialog.openDialogs.length > 0) {
            return;
        }
        var data = this.group.getRawValue();
        this.confirmOrderDialogRef = this._matDialog.open(ConfirmOrderComponent, {
            panelClass: 'confirm-order',
            data: {
                order: this.orderDetail,
                myInfo: this.myInfo
            }
        });
        this.confirmOrderDialogRef.afterClosed().subscribe(function (response) {
            var getData = function (data) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                return tslib_1.__generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, data];
                        case 1: return [2 /*return*/, _a.sent()];
                    }
                });
            }); };
            if (!response) {
                _this.confirmingOrder = false;
                return;
            }
            var status = response[0];
            if (response === 'cancel') {
                _this.confirmingOrder = false;
                return;
            }
            _this.confirmingOrder = true;
            var ConfirmedOrderDetail = response[1];
            if (ConfirmedOrderDetail.orderId === 0) {
                _this._orderService.addOrderDetails(data).then(function (result) {
                    localStorage.setItem('orderId', result.orderId.toString());
                    _this.confirmingOrder = true;
                    var pConfirm = _this._orderService.confirmOrder(result.orderId);
                    getData(pConfirm).then(function (result) {
                        if (result != null)
                            setTimeout(function () { return (_this._router.navigateByUrl(Routes.paymentRedirect)); }, 10);
                    });
                    _this.confirmingOrder = false;
                });
            }
            else if (ConfirmedOrderDetail.orderId > 0) {
                _this._orderService.updateOrderDetails(data).then(function (result) {
                    localStorage.setItem('orderId', result.orderId.toString());
                    _this.confirmingOrder = true;
                    var pConfirm = _this._orderService.confirmOrder(result.orderId);
                    getData(pConfirm).then(function (result) {
                        if (result != null)
                            setTimeout(function () { return (_this._router.navigateByUrl(Routes.paymentRedirect)); }, 10);
                    });
                    _this.confirmingOrder = false;
                });
            }
        });
    };
    OrdersOrderDetailsComponent.prototype.afterSaveConfirmOrder = function (result) {
        var quoteForm = this.group.get('quote');
        quoteForm.get('quoteId').setValue(result.quoteId, { emitEvent: false });
        this.group.get('quoteId').setValue(result.quoteId, { emitEvent: false });
        this.saveCodAmounts(result.quoteId);
        this.saveOrderPieces(result.quoteId);
        this.saveOrderSurcharges(result.orderId);
    };
    OrdersOrderDetailsComponent.prototype.saveOrderPieces = function (quoteId) {
        var _this = this;
        var pieces = this.group.get('quote').get('pieces');
        var piecesValue = pieces.value;
        if (piecesValue.length == 0)
            return;
        for (var _i = 0, _a = pieces.value; _i < _a.length; _i++) {
            var c = _a[_i];
            c.quoteId = quoteId;
        }
        this._quoteService.savePieces(pieces.value).subscribe(function (result) {
            _this.group.get('quote').get('pieces').updateValueAndValidity();
            _this.newQuote.setValue(false);
        });
        ;
        this.newQuote.setValue(false);
    };
    OrdersOrderDetailsComponent.prototype.saveOrderSurcharges = function (orderId) {
        if (this.orderSurcharges.length != 0) {
            this.orderSurcharges.forEach(function (element) {
                element.orderId = orderId;
            });
            if (this.orderSurcharges)
                this._quoteService.saveSurcharges(this.orderSurcharges).subscribe(function (result) {
                });
        }
    };
    OrdersOrderDetailsComponent.prototype.saveCodAmounts = function (quoteId) {
        // if (this.orderCod.length > 0) {
        //     this.orderCod = this.orderCod.map(item => {
        //         item.quoteId = quoteId
        //         return item;
        //     });
        //     this._quoteService.saveCodAmount(this.orderCod).subscribe(result => {
        //     });
        // }
        // else if (this.group.get('quoteId').value != 0)
        //     this._quoteService.deleteCodAmount(this.group.get('quoteId').value).subscribe(result => {
        //     });
    };
    OrdersOrderDetailsComponent.prototype.getCodAmounts = function () {
        // var orderId = this.group.get('orderId').value;
        // if (orderId > 0)
        //     this._quoteService.getCodAmounts(orderId).then(result => {
        //         this.orderCod = result;
        //         if (this.orderCod.length > 0) {
        //             var CODsurcharge = this.orderSurcharges.filter(x => x.description.toString().toLowerCase() == 'cash on delivery')
        //             if (CODsurcharge.length > 0) {
        //                 const index = this.orderSurcharges.findIndex(_ => _.surchargeId == CODsurcharge[0].surchargeId);
        //                 if (!(index > -1)) return;
        //                 this.orderSurcharges[index].disabled = true;
        //                 this.orderSurcharges[index].checked = true;
        //             }
        //         }
        //         else {
        //             if (this.disableEdit != null && this.disableEdit == false)
        //                 this.disableCOD = true;
        //         }
        //     });
    };
    OrdersOrderDetailsComponent.prototype.cancel = function () {
        this.canceled.emit();
    };
    OrdersOrderDetailsComponent.prototype.backToOrders = function () {
        this._router.navigateByUrl(Routes.orders);
    };
    OrdersOrderDetailsComponent.prototype.generateNewQuote = function (showPrice) {
        var _this = this;
        if (showPrice === void 0) { showPrice = true; }
        var codValid = true;
        if (this.group.invalid || this.generatingQuote) {
            this.group.markAllAsTouched();
            return;
        }
        if (this.isExport && this.group.get('quote').get('serviceTypeId').value === 0) {
            this._snackBar.open('Select either Parcels or Docs', 'Dismiss', { duration: 10000 });
            return;
        }
        // this.orderCod.forEach(element => {
        //     if (element.codCurrencyId == 0 || isNullOrUndefined(element.codCurrencyId) || element.codAmount == 0 || isNullOrUndefined(element.codAmount)) {
        //         codValid = false;
        //     }
        // });
        // if (!codValid)
        //     return;
        var timeRangeLookupId = this.group.get('timeRangeLookupId').value;
        var pickupDateTime;
        if (!isNullOrUndefined(timeRangeLookupId)) {
            var tRange = this.timeRanges.find(function (x) { return x.id == timeRangeLookupId; }).name;
            var pickupDate = this.group.get('pickupDate').value;
            var splitted = tRange.split(" - ");
            var hour = splitted[0].split(":");
            pickupDateTime = new Date(pickupDate);
            // if (Number(hour[0]) == 12)
            //     pickupDateTime.setHours(Number(hour[0]));
            // else
            //     pickupDateTime.setHours(12.01);
        }
        this.group.get('pickupDate').setValue(pickupDateTime);
        this.generatingQuote = true;
        var generateQuote;
        var quoteForm = this.group.get('quote');
        var quote = quoteForm.value;
        quote.direction = quote.type;
        var surchargeValues = '6,1 | '; //Default Values
        this.orderSurcharges.filter(function (x) { return x.checked == true; }).forEach(function (x) { return surchargeValues += x.surchargeId.toString() + ',1|'; });
        quote.surchargesValue = surchargeValues;
        quote.chargeableWeight = this.group.get('quote').get('chargeableWeight').value;
        quote.cods = this.group.get('quote').get('cods').value;
        var skusSelected = this.group.get('quote').get('skus').value;
        quote.skus = skusSelected;
        if (quote.type === ServiceDirection.Export) {
            if (quote.shippingTypeId.toString().trim() == "")
                quote.shippingTypeId = 0;
            generateQuote = this._quoteService.generateInternationalQuote(quote);
        }
        else {
            generateQuote = this._quoteService.generateDomesticQuote(quote);
        }
        // const proformaDetails = this.group.get('proforma').value;
        // const orderId = this.group.get('orderId').value;
        // if (proformaDetails != null) {
        //     proformaDetails.forEach(x =>
        //         x.orderId = orderId)
        //     this._orderService.addProformaDetails(proformaDetails).then(result => {
        //     });
        // }
        generateQuote.subscribe(function (result) {
            quoteForm.get('quotePrice').setValue(result.price);
            quoteForm.get('quoteCurrency').setValue(result.currency.toString());
            var enumValue = CurrencyType[result.currency.toString()];
            quoteForm.get('quoteCurrencyId').setValue(enumValue);
            _this.generatingQuote = false;
            if (showPrice) {
                _this._snackBar.open('Total charges: ' + result.currency + ' ' + _this._numberPipe.transform(result.price, '.2-2'), 'Dismiss', {
                    duration: 10000
                });
                if (_this.hasSubsidiaryId)
                    _this.confirmed.emit(result);
                else
                    _this.updateQuotePrice(result.price, result.currency);
            }
            else if (_this.hasSubsidiaryId)
                _this.confirmed.emit(result);
            else
                _this.updateQuotePrice(result.price, result.currency);
        });
    };
    OrdersOrderDetailsComponent.prototype.updateQuote = function (showPrice) {
        var _this = this;
        if (showPrice === void 0) { showPrice = true; }
        var pickupTime = this.group.get('pickupDate').value;
        var codValid = true;
        if (this.group.invalid || this.generatingQuote) {
            this.group.markAllAsTouched();
            return;
        }
        // this.orderCod.forEach(element => {
        //     if (element.codCurrencyId == 0 || isNullOrUndefined(element.codCurrencyId) || element.codAmount == 0 || isNullOrUndefined(element.codAmount)) {
        //         codValid = false;
        //     }
        // });
        // if (!codValid)
        //     return;
        var timeRangeLookupId = this.group.get('timeRangeLookupId').value;
        var pickupDateTime;
        if (!isNullOrUndefined(timeRangeLookupId)) {
            var tRange = this.timeRanges.find(function (x) { return x.id == timeRangeLookupId; }).name;
            var pickupDate = this.group.get('pickupDate').value;
            var splitted = tRange.split(" - ");
            var hour = splitted[0].split(":");
            pickupDateTime = new Date(pickupDate);
            // if (Number(hour[0]) == 12)
            //     pickupDateTime.setHours(Number(hour[0]));
            // else
            //     pickupDateTime.setHours(12.01);
        }
        this.group.get('pickupDate').setValue(pickupDateTime);
        var generateQuote;
        var quoteForm = this.group.get('quote');
        var quote = quoteForm.value;
        quote.direction = quote.type;
        var surchargeValues = '6,1 |'; //Default Values
        this.orderSurcharges.filter(function (x) { return x.checked == true; }).forEach(function (x) { return surchargeValues += x.surchargeId.toString() + ',1|'; });
        quote.surchargesValue = surchargeValues;
        quote.chargeableWeight = this.group.get('quote').get('chargeableWeight').value;
        quote.cods = this.group.get('quote').get('cods').value;
        if (quote.cods.find(function (cod) { return cod.codCurrencyId === 2; }) !== undefined) {
            quote.cods.find(function (cod) { return cod.codCurrencyId === 2; }).codAmount = quote.cods.find(function (cod) { return cod.codCurrencyId === 2; }).codAmount;
        }
        if (quote.type === ServiceDirection.Export) {
            if (quote.shippingTypeId.toString().trim() == "")
                quote.shippingTypeId = 0;
            generateQuote = this._quoteService.generateInternationalQuote(quote);
        }
        else {
            generateQuote = this._quoteService.generateDomesticQuote(quote);
        }
        generateQuote.subscribe(function (result) {
            quoteForm.get('quotePrice').setValue(result.price);
            quoteForm.get('quoteCurrency').setValue(result.currency.toString());
            var enumValue = CurrencyType[result.currency.toString()];
            quoteForm.get('quoteCurrencyId').setValue(enumValue);
            // this.generatingQuote = false;
            if (showPrice) {
                _this._snackBar.open('Total charges: ' + result.currency + ' ' + _this._numberPipe.transform(result.price, '.2-2'), 'Dismiss', {
                    duration: 10000
                });
                if (_this.hasSubsidiaryId) {
                    _this.updateQuotePrice(result.price, result.currency);
                    var data_1 = _this.group.getRawValue();
                    data_1.quote.cods = _this.group.get('quote').get('cods').value;
                    _this._orderService.updateConfirmedOrder(data_1).then(function (result) {
                        _this._orderService.updateOrderDetails(data_1).then(function (result) {
                            localStorage.setItem('orderId', result.orderId.toString());
                            var quoteForm = _this.group.get('quote');
                            _this.updateQuotePrice(quoteForm.get('quotePrice').value, quoteForm.get('quoteCurrency').value);
                            _this.saveOrderSurcharges(result.orderId);
                            _this.saveCodAmounts(result.quoteId);
                            _this.saveOrderPieces(result.quoteId);
                            data_1.quote.cods = _this.group.get('quote').get('cods').value;
                        });
                    });
                    _this._router.navigateByUrl(Routes.orders);
                }
            }
        });
    };
    OrdersOrderDetailsComponent.prototype.updateQuotePrice = function (price, currency) {
        var quoteForm = this.group.get('quote');
        quoteForm.get('quotePrice').setValue(price);
        quoteForm.get('quoteCurrency').setValue(currency.toString());
        var enumValue = CurrencyType[currency.toString()];
        quoteForm.get('quoteCurrencyId').setValue(enumValue);
        this.newQuote.setValue(false);
        this.generatingQuote = false;
    };
    OrdersOrderDetailsComponent.prototype.updateQuoteAndOrderId = function (orderid, quoteid) {
        this.group.get('quoteId').setValue(quoteid);
        this.group.get('orderId').setValue(orderid);
        var quoteForm = this.group.get('quote');
        quoteForm.get('quoteId').setValue(quoteid);
        this.newQuote.setValue(false);
        this.generatingQuote = false;
    };
    OrdersOrderDetailsComponent.prototype.addNewShipper = function () {
        var _this = this;
        this.addNewContact(function (_) { return (_this.addingShipper = true); });
    };
    OrdersOrderDetailsComponent.prototype.addNewReceiver = function () {
        var _this = this;
        this.addNewContact(function (_) { return (_this.addingReceiver = true); });
    };
    OrdersOrderDetailsComponent.prototype.addNewContact = function (beforeSave) {
        var _this = this;
        this.contactDialogRef = this._matDialog.open(ContactEditorComponent, {
            panelClass: 'contact-form-dialog',
            data: {
                action: 'new',
                countries: this.countries
            }
        });
        this.contactDialogRef.afterClosed().subscribe(function (response) {
            if (!response) {
                return;
            }
            var status = response[0];
            if (status === 'cancel') {
                return;
            }
            var contact = response[1];
            var tags = response[2];
            contact.tags = tags;
            beforeSave();
            _this._contactService.addNewContact(contact).then(function (_) { return _this.getAllContacts(true); });
        });
    };
    OrdersOrderDetailsComponent.prototype.getCountries = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this._locationService.getCountriesFromDB().subscribe(function (success) {
                resolve(success);
                _this.countries = success;
            });
        });
    };
    OrdersOrderDetailsComponent.prototype.getSurchargesFromTarif = function (productId) {
        var _this = this;
        this.loadingSurcharges = true;
        return new Promise(function (resolve, reject) {
            _this._quoteService.getSurchargesFromTarif(productId).subscribe(function (success) {
                resolve(success);
                _this.orderSurcharges = success;
                _this.orderSurcharges = _this.orderSurcharges.map(function (item) {
                    item.orderId = 0;
                    item.checked = item.selected;
                    return item;
                });
                _this.loadingSurcharges = false;
            });
        });
    };
    OrdersOrderDetailsComponent.prototype.dimensionsChanged = function (content) {
        var validHeight = this.group.get('quote').get('height').hasError('validDecimal');
        var validWidth = this.group.get('quote').get('width').hasError('validDecimal');
        var validLength = this.group.get('quote').get('length').hasError('validDecimal');
        if (validHeight || validWidth || validLength)
            this.confirmDimension = this._matDialog.open(ConfirmDimesionsComponent, {
                panelClass: 'confirm-Dimension'
            });
    };
    OrdersOrderDetailsComponent.prototype.getSurcharges = function (orderId) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this._orderService.getSurchargesForOrder(orderId).subscribe(function (success) {
                resolve(success);
                _this.orderSurcharges = success;
                _this.orderSurcharges = _this.orderSurcharges.map(function (item) {
                    item.disabled = true;
                    return item;
                });
            });
        });
    };
    OrdersOrderDetailsComponent.prototype.loadSurcharges = function () {
        var data = this.group.getRawValue();
        var orderId = data.orderId;
        if (orderId && orderId > 0)
            this.getSurcharges(orderId);
        else {
            var quote = this.group.value.quote;
            if (quote.type === undefined || quote.type === null || quote.type === ServiceDirection.Domestic) {
                this.prepareShippingTypes();
            }
            else if (quote.type !== undefined && quote.type !== null && quote.serviceTypeId !== 0) {
                this.prepareShippingTypes(quote.type, quote.serviceTypeId);
            }
        }
    };
    OrdersOrderDetailsComponent.prototype.editContact = function (contactId) {
        var _this = this;
        this._contactService.getContact(contactId).then(function (c) {
            _this.contactDialogRef = _this._matDialog.open(ContactEditorComponent, {
                panelClass: 'contact-form-dialog',
                data: {
                    action: 'edit',
                    contact: c,
                    countries: _this.countries
                }
            });
            _this.contactDialogRef.afterClosed().subscribe(function (response) {
                if (!response) {
                    return;
                }
                var status = response[0];
                if (status === 'cancel') {
                    return;
                }
                var contact = response[1];
                var tags = response[2];
                contact.tags = tags;
                _this._contactService.editContact(contact).then(function (_) { return _this.getAllContacts(true); });
            });
        });
    };
    OrdersOrderDetailsComponent.prototype.numberOnly = function (event) {
        var charCode = event.which ? event.which : event.keyCode;
        var invalidChars = ['-', '+', 'e'];
        var valid = ['.'];
        if (valid.includes(event.key)) {
            return true;
        }
        if (charCode > 31 && (charCode < 48 || charCode > 57) && invalidChars.includes(event.key)) {
            return false;
        }
        return true;
    };
    OrdersOrderDetailsComponent.prototype.openNetPointDialog = function (type, action) {
        var _this = this;
        if (action === void 0) { action = 'new'; }
        if (action === 'new') {
            switch (type) {
                case AddressType.Receiver:
                    if (this.isNetPointReceiver) {
                        this.resetReceiverNetPoint();
                        return;
                    }
                    break;
                case AddressType.Shipper:
                    if (this.isNetPointShipper) {
                        this.resetShipperNetPoint();
                        return;
                    }
                    break;
                default:
                    break;
            }
        }
        this.netpointLocationsDialogRef = this._matDialog.open(NetPointLocationsComponent, {
            panelClass: 'netpoint-locations-dialog'
        });
        this.netpointLocationsDialogRef.afterClosed().subscribe(function (response) {
            _this.netpointSelect(response, type);
        });
    };
    OrdersOrderDetailsComponent.prototype.resetReceiverNetPoint = function () {
        var _this = this;
        this.isNetPointReceiver = false;
        this.toNetPointButtonText = 'To NetPoint?';
        this.receiverAddressLabel = 'Receiver Address';
        this.receiverAddresses = this.receiverAddresses.filter(function (a) { return a.addressId !== _this.group.get('receiverAddressId').value; });
        this.group.get('receiverAddressId').enable();
        if (this.receiverAddresses && this.receiverAddresses.length === 1) {
            this.group.get('receiverAddressId').setValue(this.receiverAddresses[0].addressId);
        }
        else {
            this.group.get('receiverAddressId').setValue(undefined);
        }
    };
    OrdersOrderDetailsComponent.prototype.resetShipperNetPoint = function () {
        var _this = this;
        this.isNetPointShipper = false;
        this.fromNetPointButtonText = 'From NetPoint?';
        this.shipperAddressLabel = 'Shipper Address';
        this.shipperAddresses = this.shipperAddresses.filter(function (a) { return a.addressId !== _this.group.get('shipperAddressId').value; });
        this.group.get('shipperAddressId').enable();
        if (this.shipperAddresses && this.shipperAddresses.length === 1) {
            this.group.get('shipperAddressId').setValue(this.shipperAddresses[0].addressId);
        }
        // else {
        //     this.group.get('shipperAddressId').setValue(undefined);
        // }
    };
    OrdersOrderDetailsComponent.prototype.netpointSelect = function (netpointAddress, type) {
        if (!netpointAddress) {
            return;
        }
        var netpointInstance = new Address(netpointAddress);
        netpointInstance.isNetPoint = true;
        switch (type) {
            case AddressType.Receiver:
                this.receiverAddresses = this.receiverAddresses.filter(function (a) { return !a.isNetPoint; });
                this.isNetPointReceiver = true;
                this.toNetPointButtonText = 'To Receiver?';
                this.receiverAddressLabel = 'To NetPoint Location';
                this.receiverAddresses.push(netpointInstance);
                this.group.get('receiverAddressId').setValue(netpointInstance.addressId);
                this.group.get('receiverAddressId').disable({ onlySelf: true });
                break;
            case AddressType.Shipper:
                this.shipperAddresses = this.shipperAddresses.filter(function (a) { return !a.isNetPoint; });
                this.isNetPointShipper = true;
                this.fromNetPointButtonText = 'From Shipper?';
                this.shipperAddressLabel = 'From NetPoint Location';
                this.shipperAddresses.push(netpointInstance);
                this.group.get('shipperAddressId').setValue(netpointInstance.addressId);
                this.group.get('shipperAddressId').disable({ onlySelf: true });
                break;
            default:
                break;
        }
    };
    OrdersOrderDetailsComponent.prototype.updateProformaPanelState = function () {
        this.proformaPanelState = true;
    };
    OrdersOrderDetailsComponent.prototype.saveProforma = function (input) {
        this.group.get('proforma').setValue(input);
        this.proformaPanelState = false;
        // var orderId = this.group.get('orderId').value;
        // input.forEach(x =>
        //     x.orderId = orderId)
        // this._orderService.addProformaDetails(input).then(result => {
        // });
    };
    OrdersOrderDetailsComponent.prototype.addNewPiece = function () {
        var quoteGroup = this.group.get('quote');
        var pieces = quoteGroup.get('pieces');
        pieces.push(this._formBuilder.group(new Piece()));
    };
    OrdersOrderDetailsComponent.prototype.pieceAdded = function (data) {
        var pieceForm = data.value;
        var mode = data.mode;
        var index = data.index;
        if (mode === 'cancel') {
            if (index >= 0) {
                this.editIndex[index] = false;
            }
            return;
        }
        else if (mode === 'new') {
            var pieces = this.group.get('quote').get('pieces');
            pieces.at(index - 1).setValue(pieceForm);
            var TotalWeight = 0, TotalNOP = 0;
            for (var _i = 0, _a = pieces.controls; _i < _a.length; _i++) {
                var c = _a[_i];
                var pieceValue = c.value;
                TotalWeight += pieceValue.weight;
                TotalNOP += pieceValue.numberOfPieces;
            }
            if (TotalWeight == 0)
                TotalWeight = 0.5;
            if (TotalNOP == 0)
                TotalNOP = 1;
            this.group.get('quote').get('weight').setValue(TotalWeight);
            this.group.get('quote').get('numberOfPieces').setValue(TotalNOP);
        }
        else if (mode === 'delete') {
            var pieces = this.group.get('quote').get('pieces');
            pieces.removeAt(index - 1);
            var TotalWeight = 0, TotalNOP = 0;
            for (var _b = 0, _c = pieces.controls; _b < _c.length; _b++) {
                var c = _c[_b];
                var pieceValue = c.value;
                TotalWeight += pieceValue.weight;
                TotalNOP += pieceValue.numberOfPieces;
            }
            if (TotalWeight == 0)
                TotalWeight = 0.5;
            this.group.get('quote').get('weight').setValue(TotalWeight);
            if (TotalNOP == 0)
                TotalNOP = 1;
            this.group.get('quote').get('numberOfPieces').setValue(TotalNOP);
            if (pieceForm != undefined && pieceForm.pieceId != 0) {
                this._quoteService.deletePieces(pieceForm.pieceId);
            }
            if (pieces.controls.length == 0)
                pieces.clearValidators();
        }
        this.calculateChargeableWeight();
    };
    OrdersOrderDetailsComponent.prototype.filterContacts = function () {
        if (!this.contacts) {
            return;
        }
        // this.getNextShipperBatch();
        // get the search keyword
        // let search = this.contactFilterCtrl.value;
        // if (!search) {
        //     this.filteredContacts.next(this.contacts.slice(0, 10));
        //     return;
        // } else {
        //     search = search.toLowerCase();
        // }
        // // filter the banks
        // this.filteredContacts.next(
        //     this.contacts.filter(contact => contact.fullName.toLowerCase().indexOf(search) > -1)
        // );
    };
    OrdersOrderDetailsComponent.prototype.canUpdateOrder = function (orderId) {
        var canUpdate = this._orderService.canUpdateOrder(orderId);
        return canUpdate;
    };
    OrdersOrderDetailsComponent.prototype.ngOnDestroy = function () {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    };
    // search(query: string) {
    //     this.getNextShipperBatch(query);
    //     // let result = this.select(query)
    //     // this.selectedAreas = result;
    // }
    OrdersOrderDetailsComponent.prototype.handleInput = function (event) {
        event.stopPropagation();
    };
    OrdersOrderDetailsComponent.prototype.getNextShipperBatch = function (searchShipper) {
        if (isNullOrUndefined(searchShipper) || searchShipper == "") {
            if (searchShipper == "")
                this.offset = 0;
            var result = this.contacts.slice(this.offset, this.offset + this.limit);
            this.shipperOptions.next(result);
        }
        else {
            this.offset = 0;
            result = this.contacts.filter(function (x) { return x.fullName.toLowerCase().includes(searchShipper.toString().toLowerCase()); }).slice(this.offset, this.offset + this.limit);
            this.shipperOptions$ = this.shipperOptions.asObservable().pipe(scan(function (acc, curr) {
                return acc.concat(curr);
            }, []));
            this.shipperOptions.next(result);
        }
        this.offset += this.limit;
    };
    OrdersOrderDetailsComponent.prototype.getNextReceiverBatch = function (searchReceiver) {
        if (isNullOrUndefined(searchReceiver) || searchReceiver == "") {
            if (searchReceiver == "")
                this.offset = 0;
            var result = this.contacts.slice(this.offset, this.offset + this.limit);
            this.receiverOptions.next(result);
        }
        else {
            this.offset = 0;
            result = this.contacts.filter(function (x) { return x.fullName.toLowerCase().includes(searchReceiver.toString().toLowerCase()); }).slice(this.offset, this.offset + this.limit);
            this.receiverOptions$ = this.receiverOptions.asObservable().pipe(scan(function (acc, curr) {
                return acc.concat(curr);
            }, []));
            this.receiverOptions.next(result);
        }
        this.offset += this.limit;
    };
    OrdersOrderDetailsComponent.prototype.addNewSku = function () {
        var skus = this.group.get('quote').get('skus');
        console.log(skus);
        this.group.get('quote').get('skus').setValidators(SkuValidator.inValidSku("disabled"));
        var sku = this._formBuilder.group(new OrderSku());
        sku.get('sku').setValidators(Validators.required);
        sku.get('quantity').setValidators(Validators.min(1));
        skus.push(sku);
    };
    OrdersOrderDetailsComponent.prototype.skuAdded = function (data) {
    };
    OrdersOrderDetailsComponent.prototype.fetchStock = function (storerKey, sku) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this._quoteService.FetchStockAsync(storerKey, sku).subscribe(function (result) {
                resolve(result);
                _this.wmsSKUs = result;
            });
        });
    };
    return OrdersOrderDetailsComponent;
}());
export { OrdersOrderDetailsComponent };
export var AddressType;
(function (AddressType) {
    AddressType[AddressType["Shipper"] = 0] = "Shipper";
    AddressType[AddressType["Receiver"] = 1] = "Receiver";
})(AddressType || (AddressType = {}));
