import * as tslib_1 from "tslib";
import { OnInit, ChangeDetectorRef, QueryList } from '@angular/core';
import { OrderAnonymousBaseComponent } from '../anonymous-base/anonymous-base.component';
import { PackageService } from './package.service';
import { FormControl, FormBuilder, Validators } from '@angular/forms';
import { WeightValidator } from 'app/_validators/weight.validator';
import { ServiceDirection } from 'app/_enums/ServiceDirection.enum';
import { MatExpansionPanel } from '@angular/material';
import { QuoteService } from 'app/main/quotes/quote.service';
import { debounceTime } from 'rxjs/operators';
var OrdersAnonymousShipmentPackagesComponent = /** @class */ (function (_super) {
    tslib_1.__extends(OrdersAnonymousShipmentPackagesComponent, _super);
    function OrdersAnonymousShipmentPackagesComponent(_packageService, _formBuilder, _cd, _quoteService) {
        var _this = _super.call(this) || this;
        _this._packageService = _packageService;
        _this._formBuilder = _formBuilder;
        _this._cd = _cd;
        _this._quoteService = _quoteService;
        _this.submited = false;
        _this.onlyOnePackage = true;
        return _this;
    }
    Object.defineProperty(OrdersAnonymousShipmentPackagesComponent.prototype, "orderPackages", {
        get: function () {
            return this.group.get('packages').controls;
        },
        enumerable: true,
        configurable: true
    });
    OrdersAnonymousShipmentPackagesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this._packageService.getPackages().subscribe(function (packages) { return (_this.packages = packages); });
        this.group
            .get('packageType')
            .valueChanges.pipe(debounceTime(500))
            .subscribe(function (result) {
            var currentDirection = _this.form.get('currentDirection').value;
            _this._quoteService.getShippingTypesPromise(currentDirection, result);
        });
        this.form
            .get('currentDirection')
            .valueChanges.pipe(debounceTime(500))
            .subscribe(function (result) {
            var packageType = _this.group.get('packageType').value;
            _this._quoteService.getShippingTypesPromise(result, packageType);
        });
    };
    OrdersAnonymousShipmentPackagesComponent.prototype.weightChanged = function (event, index) {
        var weight = +event.target.value;
        if (weight !== undefined && weight !== null && !isNaN(weight) && weight < 0.5) {
            var packagesArray = this.group.get('packages');
            packagesArray.controls[index].get('weight').setValue('0.5');
        }
    };
    OrdersAnonymousShipmentPackagesComponent.prototype.packgeChanged = function (event, index) {
        var packageGroup = this.orderPackages[index];
        var packageItem = this.packages.find(function (x) { return x.packageId === event; });
        var dimension = packageGroup.get('dimension');
        if (packageItem) {
            dimension.get('length').setValue(packageItem.length);
            dimension.get('width').setValue(packageItem.width);
            dimension.get('height').setValue(packageItem.height);
            dimension.setControl('description', new FormControl(packageItem.description));
            dimension.setControl('title', new FormControl(packageItem.title));
        }
        else {
            dimension.get('length').setValue(null);
            dimension.get('width').setValue(null);
            dimension.get('height').setValue(null);
            dimension.setControl('description', new FormControl(''));
            dimension.setControl('title', new FormControl(''));
        }
    };
    OrdersAnonymousShipmentPackagesComponent.prototype.addPackage = function () {
        var _this = this;
        this.matExpansionPanelQueryList.changes.subscribe(function (change) {
            _this._cd.detectChanges();
        });
        var packagesArray = this.group.get('packages');
        var packageControl = this.initPackage(this._formBuilder);
        if (this.form.get('currentDirection')) {
            var weight = packageControl.get('weight');
            weight.setValue(0.5);
            var currentDirection = this.form.get('currentDirection').value;
            weight.setValidators([WeightValidator.validWeight(currentDirection), Validators.required]);
            weight.updateValueAndValidity();
        }
        packageControl.get('expanded').setValue(true);
        packagesArray.controls.forEach(function (group) {
            group.get('expanded').setValue(false);
        });
        packagesArray.push(packageControl);
    };
    OrdersAnonymousShipmentPackagesComponent.prototype.removePackage = function (index) {
        var packagesArray = this.group.get('packages');
        packagesArray.removeAt(index);
    };
    OrdersAnonymousShipmentPackagesComponent.prototype.validateAndContinue = function () {
        if (this.group.valid) {
            this.continue();
        }
        else {
            this.submited = true;
            this.group.updateValueAndValidity();
            this.group.markAllAsTouched();
        }
    };
    OrdersAnonymousShipmentPackagesComponent.prototype.packagePanelOpened = function (index) {
        this.togglePanel(index, true);
    };
    OrdersAnonymousShipmentPackagesComponent.prototype.packagePanelClosed = function (index) {
        this.togglePanel(index, false);
    };
    OrdersAnonymousShipmentPackagesComponent.prototype.getPanelDescription = function (index) {
        var packagesArray = this.group.get('packages');
        var packageControl = packagesArray.controls[index];
        var packageId = packageControl.get('packageId').value;
        var packageBox = this.packages.find(function (i) { return i.packageId === packageId; });
        var packageBoxTitle = packageId === 0 && !packageBox ? 'Custom' : packageBox.title;
        var message = '<strong>Package:</strong> ' + packageBoxTitle;
        var dimension = packageControl.get('dimension');
        if (dimension.get('width') &&
            dimension.get('width').value &&
            dimension.get('width').value >= 0 &&
            dimension.get('height') &&
            dimension.get('height').value &&
            dimension.get('height').value >= 0 &&
            dimension.get('length') &&
            dimension.get('length').value &&
            dimension.get('length').value >= 0) {
            message += ', <strong>L:</strong> ' + dimension.get('width').value + ' cm';
            message += ' x <strong>W:</strong>: ' + dimension.get('length').value + ' cm';
            message += ' x <strong>H:</strong>: ' + dimension.get('height').value + ' cm';
        }
        if (packageControl.get('weight').value) {
            message += ', <strong>Weight:</strong> ' + packageControl.get('weight').value + ' kg';
        }
        return message;
    };
    OrdersAnonymousShipmentPackagesComponent.prototype.togglePanel = function (index, value) {
        var packagesArray = this.group.get('packages');
        var packageControl = packagesArray.controls[index];
        packageControl.get('expanded').setValue(value);
    };
    return OrdersAnonymousShipmentPackagesComponent;
}(OrderAnonymousBaseComponent));
export { OrdersAnonymousShipmentPackagesComponent };
