import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { FormControl, Validators, FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material';
import { PhoneNumberDialogComponent } from '../orders/new-order/billing-details/phone-number-dialog/phone-number-dialog.component';
import { UserService } from 'app/_shared/user.services';
import { City } from 'app/_models/city.model';
import { PhoneValidator } from 'app/_validators/phone.validator';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { ProfileService } from './profile.service';
import { AuthenticationService } from 'app/authentication/authentication.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { LocationService } from 'app/_shared/location-service.service';
import { isNullOrUndefined } from 'util';
var DEFAULT_PROFILE = 'https://media.wired.com/photos/59268c5dcfe0d93c474309a2/master/w_1300,c_limit/BSP_054.jpg';
var ProfileComponent = /** @class */ (function () {
    function ProfileComponent(_location, _router, _userService, _formBuilder, _profileService, _matDialog, _authService, _locationService) {
        this._location = _location;
        this._router = _router;
        this._userService = _userService;
        this._formBuilder = _formBuilder;
        this._profileService = _profileService;
        this._matDialog = _matDialog;
        this._authService = _authService;
        this._locationService = _locationService;
        this.submited = new EventEmitter();
        this.canceled = new EventEmitter();
        this._unsubscribeAll = new Subject();
        // countries: Entity<number>[] = [];
        // countries: Country[];
        this.cities = [];
        this.needPhoneVerification = false;
        this.checkingPhoneNumber = false;
        this.phoneNumberExists = false;
        this.imageUploadLoading = false;
        this.submitted = false;
        this.imagedownloading = false;
        this.customStyle = {
            backgroundColor: "#ffffff",
            border: "1px solid #7e7e7e",
            borderRadius: "50%",
            color: "#7e7e7e",
            cursor: "pointer"
        };
        this.profilePhotoDisabled = false;
        this.profilePhoto = DEFAULT_PROFILE;
        this.defaultPhotoUrl = 'assets/images/avatars/profile.jpg';
        this.blobToFile = function (theBlob, fileName) {
            var b = theBlob;
            b.lastModifiedDate = new Date();
            b.name = fileName;
            return theBlob;
        };
    }
    ProfileComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.getPaperSize();
        this.getCountries();
        this.group = this._formBuilder.group(this._profileService.userDetailOnChanged.value);
        this.cityControl = new FormControl('', Validators.required);
        if (this.group) {
            var countryId = this.group.get('countryId').value;
            var countryName = this.group.get('countryName').value;
            var cityId = this.group.get('cityId').value;
            var cityName = this.group.get('cityName').value;
            if (countryId && countryName && cityId && cityName) {
                var selectedCity = new City();
                selectedCity.cityId = cityId;
                selectedCity.countryId = countryId;
                selectedCity.name = cityName + ', ' + countryName;
                this.cityControl.setValue(selectedCity);
            }
            // this.countryChange(countryId);
        }
        this.cityControl.valueChanges.subscribe(function (value) {
            _this.citySelected(value);
        });
        var phoneCountryIso = this.group.get('phoneCountryIso').value;
        var country = new FormControl({ iso: phoneCountryIso }, Validators.required);
        var phoneNumberValid = this.group.get('validPhoneNumber');
        if (!phoneNumberValid) {
            this.group.setControl('validPhoneNumber', this._formBuilder.control(false));
            phoneNumberValid = this.group.get('validPhoneNumber');
        }
        if (this.group.get('phoneNumber').value) {
            this.needPhoneVerification = !this.group.get('validPhoneNumber').value;
        }
        this.phoneNumber = this.group.get('phoneNumber').value;
        this.paperSizeSet = this.group.get('paperSize').value;
        this.group.get('phoneNumber').clearValidators();
        this.group.get('phoneNumber').setValidators(Validators.required);
        this.group.get('phoneNumber').setValidators(PhoneValidator.validCountryMobile(country, phoneNumberValid));
        phoneNumberValid.valueChanges
            .pipe(takeUntil(this._unsubscribeAll), debounceTime(0))
            .subscribe(function (value) {
            _this.phoneNumberExists = false;
            if (_this.phoneNumber === _this.group.get('phoneNumber').value) {
                return;
            }
            _this.needPhoneVerification = value;
        });
        this.loadPhotos();
    };
    ProfileComponent.prototype.getPaperSize = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this._profileService.getPaperSize()];
                    case 1:
                        _a.paperSizes = _b.sent();
                        console.log(this.paperSizes);
                        return [2 /*return*/];
                }
            });
        });
    };
    ProfileComponent.prototype.countryChange = function (value) {
        var _this = this;
        // this.contactForm.get('phoneNumber').setValue('');
        console.log(this.phoneCountries);
        var phoneCountry = this.phoneCountries.find(function (country) { return country.countryId == value; });
        var country = new FormControl({ iso: phoneCountry }, Validators.required);
        var phoneNumberValid = this.group.get('validPhoneNumber');
        phoneNumberValid.setErrors(Validators.required);
        this.group.get('phoneNumber').setErrors(Validators.required);
        if (!phoneNumberValid) {
            this.group.setControl('validPhoneNumber', this._formBuilder.control(false));
            phoneNumberValid = this.group.get('validPhoneNumber');
        }
        this.group
            .get('phoneNumber')
            .setValidators([Validators.required, PhoneValidator.validCountryMobile(country, phoneNumberValid)]);
        phoneNumberValid.valueChanges
            .pipe(takeUntil(this._unsubscribeAll), debounceTime(0))
            .subscribe(function (value) {
            if (!value) {
                _this.group.get('phoneNumber').setErrors(Validators.required);
            }
        });
        this.group
            .get('phoneNumber').updateValueAndValidity();
    };
    ProfileComponent.prototype.paperSizeChange = function (value) {
        this.group.get('paperSize').setValue(value.value);
    };
    ProfileComponent.prototype.ngOnDestroy = function () {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    };
    ProfileComponent.prototype.cancelForm = function () {
        this._location.back();
    };
    ProfileComponent.prototype.submitForm = function () {
        if (this.group.invalid) {
            return;
        }
        this.submitted = true;
        this.saveBillingDetails();
        this.group.markAsUntouched();
        this._authService.loggedInUser.photoUrl = this.group.get('photo').value;
        localStorage.setItem('loggedInUser', JSON.stringify(this._authService.loggedInUser));
        this._authService.onUserInfoUpdated.next(this._authService.loggedInUser);
        this._location.back();
    };
    ProfileComponent.prototype.saveBillingDetails = function () {
        var _this = this;
        this._userService.addUserDetails(this.group.value).then(function (user) {
            _this._authService.onUserInfoUpdated.next(user);
        });
    };
    ProfileComponent.prototype.cancel = function () {
        // this.canceled.emit();
    };
    ProfileComponent.prototype.citySelected = function (option) {
        this.cityCleared();
        if (option && option.cityId && option.cityId > 0 && option.countryId && option.countryId > 0) {
            this.group.get('cityId').setValue(option.cityId);
            this.group.get('countryId').setValue(option.countryId);
            this.countryId = option.countryId;
            // this.countryChange(option.countryId);
        }
        else {
            this.cityControl.setErrors({ required: true });
        }
    };
    ProfileComponent.prototype.cityCleared = function () {
        this.group.get('cityId').setValue('');
        this.group.get('countryId').setValue('');
        this.cityControl.clearValidators();
        this.countryId = undefined;
    };
    ProfileComponent.prototype.openPhoneNumberDialog = function () {
        var _this = this;
        if (!this._matDialog.openDialogs || this._matDialog.openDialogs.length > 0) {
            return;
        }
        this.checkingPhoneNumber = false;
        // this._userService.isPhoneNumberExist(this.group.get('phoneNumber').value).subscribe(result => {
        //     this.checkingPhoneNumber = false;
        //     this.phoneNumberExists = result;
        //     if (!result) {
        this.phoneNumberDialogRef = this._matDialog.open(PhoneNumberDialogComponent, {
            panelClass: 'phone-number-dialog',
            data: {
                phoneNumber: this.group.get('phoneNumber').value
            }
        });
        this.phoneNumberDialogRef.afterClosed().subscribe(function (response) {
            if (response) {
                _this.phoneNumber = _this.group.get('phoneNumber').value;
                _this.group.get('confirmedPhoneNumber').setValue(true);
                _this.group.get('validPhoneNumber').setValue(true);
                _this.needPhoneVerification = false;
            }
        });
        //     } else {
        //         this.group.get('phoneNumber').setErrors({ phoneNumberExists: true });
        //     }
        // });
    };
    ProfileComponent.prototype.uploadPhoto = function (files) {
        var _this = this;
        this.message = '';
        if (files.length === 0) {
            return;
        }
        var mimeType = files[0].type;
        if (mimeType.match(/image\/*/) == null) {
            this.message = 'Only images are supported.';
            this.imgURL = null;
            return;
        }
        var reader = new FileReader();
        this.imagePath = files;
        reader.readAsDataURL(files[0]);
        reader.onload = function (_event) {
            _this.imgURL = reader.result;
        };
        this.uploadPhotoAndContinue();
    };
    ProfileComponent.prototype.uploadPhotoAndContinue = function () {
        var _this = this;
        if (!this.imagePath) {
            this.message = 'You need to upload your ID';
            return;
        }
        this.imageUploadLoading = true;
        this._authService.uploadUserPhoto(this.imagePath[0]).subscribe(function (user) {
            _this.imageUploadLoading = false;
        }, function (error) {
            _this.message = 'Error while uploading your image ID to the server.';
            _this.imageUploadLoading = false;
        });
    };
    ProfileComponent.prototype.uploadIDPhoto = function (files) {
        var _this = this;
        this.message = '';
        if (files.length === 0) {
            return;
        }
        var mimeType = files[0].type;
        if (mimeType.match(/image\/*/) == null) {
            this.message = 'Only images are supported.';
            this.idImgURL = null;
            return;
        }
        var reader = new FileReader();
        this.imagePath = files;
        reader.readAsDataURL(files[0]);
        reader.onload = function (_event) {
            _this.idImgURL = reader.result;
        };
        this.uploadIdPhotoAndContinue();
    };
    ProfileComponent.prototype.uploadIdPhotoAndContinue = function () {
        var _this = this;
        if (!this.imagePath) {
            this.message = 'You need to upload your ID';
            return;
        }
        this.imageUploadLoading = true;
        this._authService.uploadUserIDPhoto(this.imagePath[0]).subscribe(function (result) {
            _this.imageUploadLoading = false;
        }, function (error) {
            _this.message = 'Error while uploading your image ID to the server.';
            _this.imageUploadLoading = false;
        });
    };
    ProfileComponent.prototype.loadPhotos = function () {
        this.loadPhoto();
        this.loadIdPhoto();
    };
    ProfileComponent.prototype.loadIdPhoto = function () {
        var _this = this;
        this.imagedownloading = true;
        this._authService.downloadUserIDPhoto().subscribe(function (result) {
            if (result) {
                _this.imagedownloading = false;
                var myBlob = result;
                if (myBlob.size == 0) {
                    return;
                }
                var myFile = _this.blobToFile(myBlob, "my-image.png");
                var reader_1 = new FileReader();
                // this.imagePath = myFile;
                reader_1.readAsDataURL(myFile);
                reader_1.onload = function (_event) {
                    _this.idImgURL = reader_1.result;
                };
            }
        }, function (error) {
            _this.message = 'Error while downloading your image from the server.';
            _this.imagedownloading = false;
        });
    };
    ProfileComponent.prototype.getCountries = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this._locationService.getCountriesFromDB().subscribe(function (success) {
                resolve(success);
                _this.phoneCountries = success;
                var countryId = _this.group.get('countryId').value;
                console.log(countryId);
                if (!isNullOrUndefined(countryId))
                    _this.countryChange(countryId);
            });
        });
    };
    ProfileComponent.prototype.loadPhoto = function () {
        var _this = this;
        this._authService.downloadUserPhoto().subscribe(function (result) {
            if (result) {
                _this.imagedownloading = false;
                var myBlob = result;
                if (myBlob.size == 0) {
                    _this.imgURL = _this.defaultPhotoUrl;
                    return;
                }
                var myFile = _this.blobToFile(myBlob, "my-image.png");
                var reader_2 = new FileReader();
                reader_2.readAsDataURL(myFile);
                reader_2.onload = function (_event) {
                    _this.imgURL = reader_2.result;
                };
            }
        }, function (error) {
            _this.message = 'Error while downloading your image from the server.';
            _this.imagedownloading = false;
        });
    };
    return ProfileComponent;
}());
export { ProfileComponent };
