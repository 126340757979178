import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { OrderAnonymousBaseComponent } from '../anonymous-base/anonymous-base.component';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { PhoneValidator } from 'app/_validators/phone.validator';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { Address } from '../new-order.model';
import { WeightValidator } from 'app/_validators/weight.validator';
import { MyNetServiceType, ServiceDirection } from 'app/_enums/ServiceDirection.enum';
import { CommodityService } from 'app/_shared/commodity.service';
var OrdersAnonymousContactComponent = /** @class */ (function (_super) {
    tslib_1.__extends(OrdersAnonymousContactComponent, _super);
    function OrdersAnonymousContactComponent(_formBuilder, _commodityService) {
        var _this = _super.call(this) || this;
        _this._formBuilder = _formBuilder;
        _this._commodityService = _commodityService;
        _this.submited = false;
        _this.cityControl = new FormControl('', Validators.required);
        _this.countryControl = new FormControl('', Validators.required);
        _this.countryClear = false;
        return _this;
    }
    OrdersAnonymousContactComponent.prototype.ngOnInit = function () {
        var _this = this;
        var country = new FormControl({ iso: 'LB' }, Validators.required);
        this.mobileNumberValid = this._formBuilder.control(false);
        this.group
            .get('mobileNumber')
            .setValidators([Validators.required, PhoneValidator.validCountryMobile(country, this.mobileNumberValid)]);
        this.mobileNumberValid.valueChanges
            .pipe(takeUntil(this._unsubscribeAll), debounceTime(0))
            .subscribe(function (value) {
            if (!value) {
                _this.group.get('mobileNumber').setErrors(Validators.required);
                _this.group.get('mobileNumber').setErrors({ validCountryPhone: true });
            }
        });
        this.group.setControl('address', this._formBuilder.group(new Address()));
        var addressGroup = this.group.get('address');
        addressGroup.get('countryId').setValidators(Validators.required);
        addressGroup.get('cityId').setValidators(Validators.required);
        addressGroup.setControl('cityControl', this.cityControl);
        addressGroup.setControl('countryControl', this.countryControl);
        this.cityControl.valueChanges.subscribe(function (value) {
            _this.citySelected(value);
        });
        this.countryControl.valueChanges.subscribe(function (value) {
            _this.countrySelected(value);
            _this.updateOrderDirection();
        });
    };
    OrdersAnonymousContactComponent.prototype.citySelected = function (option) {
        this.cityCleared();
        if (option && option.cityId && option.cityId > 0) {
            var addressGroup = this.group.get('address');
            addressGroup.get('cityId').setValue(option.cityId);
        }
        else {
            this.cityControl.setErrors({ required: true });
        }
    };
    OrdersAnonymousContactComponent.prototype.cityCleared = function () {
        var addressGroup = this.group.get('address');
        addressGroup.get('cityId').setValue('');
        this.cityControl.clearValidators();
    };
    OrdersAnonymousContactComponent.prototype.countrySelected = function (option) {
        this.countryCleared();
        if (option && option.countryId && option.countryId > 0) {
            var addressGroup = this.group.get('address');
            addressGroup.get('countryId').setValue(option.countryId);
        }
        else {
            this.countryControl.setErrors({ required: true });
        }
    };
    OrdersAnonymousContactComponent.prototype.countryCleared = function () {
        var addressGroup = this.group.get('address');
        addressGroup.get('countryId').setValue('');
        addressGroup.get('cityId').setValue('');
        this.countryControl.clearValidators();
        this.cityControl.clearValidators();
        this.cityControl.setValue('', { onlySelf: true });
    };
    OrdersAnonymousContactComponent.prototype.validateAndContinue = function () {
        if (this.group.valid) {
            this.continue();
        }
        else {
            this.submited = true;
            this.group.updateValueAndValidity();
            this.group.markAllAsTouched();
        }
    };
    OrdersAnonymousContactComponent.prototype.updateOrderDirection = function () {
        var fromCountryId = this.form.get('shipper.address.countryId').value;
        var toCountryId = this.form.get('receiver.address.countryId').value;
        if (fromCountryId === 158 /* Lebanon */ && toCountryId === 158 /* Lebanon */) {
            // domestic
            this.form.setControl('maxWeight', new FormControl(WeightValidator.MaxDomesticWeight));
            this.form.get('currentDirection').setValue(ServiceDirection.Domestic);
            this.updateCommodities();
            this.updatePackagesValidation();
        }
        else if (fromCountryId === 158 /* Lebanon */) {
            // Export
            this.form.setControl('maxWeight', new FormControl(WeightValidator.MaxExportWeight));
            this.form.get('currentDirection').setValue(ServiceDirection.Export);
            this.updateCommodities();
        }
        else if (toCountryId === 158 /* Lebanon */) {
            // Import
            this.form.setControl('maxWeight', new FormControl(undefined));
            this.form.get('currentDirection').setValue(ServiceDirection.Import);
            this.updateCommodities();
        }
        else {
            // Not Supported
            this.form.setControl('maxWeight', new FormControl(undefined));
            this.form.get('currentDirection').setValue(undefined);
        }
    };
    OrdersAnonymousContactComponent.prototype.updatePackagesValidation = function () {
        var packagesArray = this.form.get('packages');
        if (packagesArray) {
            for (var _i = 0, _a = packagesArray.controls; _i < _a.length; _i++) {
                var packageItem = _a[_i];
                var packageControl = packageItem;
                var weight = packageControl.get('weight');
                var currentDirection = this.form.get('currentDirection').value;
                weight.setValidators([WeightValidator.validWeight(currentDirection), Validators.required]);
                weight.updateValueAndValidity();
            }
        }
    };
    OrdersAnonymousContactComponent.prototype.updateCommodities = function () {
        var serviceType = this.form.get('currentDirection').value;
        var mynetServiceType;
        switch (serviceType) {
            case ServiceDirection.Export:
                mynetServiceType = MyNetServiceType.Export;
                break;
            case ServiceDirection.Import:
                mynetServiceType = MyNetServiceType.Import;
                break;
            case ServiceDirection.Domestic:
                mynetServiceType = MyNetServiceType.Domestic;
                break;
            default:
                break;
        }
        this._commodityService.getCommodities(mynetServiceType);
    };
    return OrdersAnonymousContactComponent;
}(OrderAnonymousBaseComponent));
export { OrdersAnonymousContactComponent };
