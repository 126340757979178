import { OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MatChipInputEvent } from '@angular/material';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { Contact, Address } from './contact.model';
import { Subject } from 'rxjs';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { PhoneValidator } from 'app/_validators/phone.validator';
var ContactFormComponent = /** @class */ (function () {
    function ContactFormComponent(matDialogRef, _data, _formBuilder) {
        this.matDialogRef = matDialogRef;
        this._data = _data;
        this._formBuilder = _formBuilder;
        this.selectedTab = 0;
        this.isNewAddressOpen = false;
        this.editIndex = [];
        this._unsubscribeAll = new Subject();
        this.visible = true;
        this.selectable = true;
        this.removable = true;
        this.addOnBlur = true;
        this.separatorKeysCodes = [ENTER, COMMA];
        this.tags = [];
        // Set the defaults
        this.action = _data.action;
        if (this.action === 'edit') {
            this.contact = _data.contact;
        }
        else {
            this.contact = new Contact();
        }
        this.createContactForm();
        this.createAddressForm();
    }
    Object.defineProperty(ContactFormComponent.prototype, "formValue", {
        get: function () {
            return this.contactForm.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ContactFormComponent.prototype, "addNewIndex", {
        get: function () {
            var value = this.contactForm.value;
            return value && value.addresses ? value.addresses.length - 1 : -1;
        },
        enumerable: true,
        configurable: true
    });
    ContactFormComponent.prototype.ngOnInit = function () { };
    ContactFormComponent.prototype.ngOnDestroy = function () {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    };
    ContactFormComponent.prototype.createAddressForm = function () {
        this.activeAddress = this._formBuilder.group(new Address());
        this.activeAddress.get('cityId').disable();
    };
    ContactFormComponent.prototype.createContactForm = function () {
        var _this = this;
        this.tags = this.contact.tags.slice();
        this.contact.tags = [];
        this.contactForm = this._formBuilder.group(this.contact);
        this.contactForm.setControl('addresses', this._formBuilder.array([], Validators.required));
        var addressesFormArray = this.contactForm.get('addresses');
        this.contact.addresses.forEach(function (address) {
            addressesFormArray.push(_this._formBuilder.group(address));
            _this.editIndex.push(false);
        });
        this.contactForm.get('email').setValidators(Validators.email);
        var country = new FormControl({ iso: 'LB' }, Validators.required);
        this.contactForm.get('phoneNumber').setValidators(PhoneValidator.validCountryMobile(country));
    };
    ContactFormComponent.prototype.addNewAddress = function () {
        this.isNewAddressOpen = true;
        this.createAddressForm();
    };
    ContactFormComponent.prototype.addressAdded = function (data) {
        var addressForm = data.value;
        var mode = data.mode;
        var index = data.index;
        if (mode === 'cancel') {
            if (this.isNewAddressOpen && index === -1) {
                this.isNewAddressOpen = false;
            }
            else if (index >= 0) {
                this.editIndex[index] = false;
            }
            return;
        }
        var addressesFormArray = this.contactForm.get('addresses');
        var address = this._formBuilder.group(addressForm);
        if (mode === 'new') {
            addressesFormArray.push(address);
            this.isNewAddressOpen = false;
        }
        else if (mode === 'edit') {
            addressesFormArray.setControl(index, address);
            this.editIndex[index] = false;
        }
    };
    ContactFormComponent.prototype.removeAddress = function (index) {
        var addressesFormArray = this.contactForm.get('addresses');
        addressesFormArray.removeAt(index);
    };
    ContactFormComponent.prototype.editAddress = function (index) {
        // const addressesFormArray = this.contactForm.get('addresses') as FormArray;
        // this.activeEditAddress = this._formBuilder.group(addressesFormArray.controls[index].value);
        this.editIndex[index] = true;
    };
    ContactFormComponent.prototype.add = function (event) {
        var input = event.input;
        var value = event.value;
        // Add our fruit
        if ((value || '').trim()) {
            this.tags.push(value.trim());
        }
        // Reset the input value
        if (input) {
            input.value = '';
        }
    };
    ContactFormComponent.prototype.remove = function (tag) {
        var index = this.tags.indexOf(tag);
        if (index >= 0) {
            this.tags.splice(index, 1);
        }
    };
    ContactFormComponent.prototype.saveNew = function () {
        this.matDialogRef.close(['new', this.contactForm.value, this.tags]);
    };
    ContactFormComponent.prototype.saveExisting = function () {
        this.matDialogRef.close(['edit', this.contactForm.value, this.tags]);
    };
    return ContactFormComponent;
}());
export { ContactFormComponent };
