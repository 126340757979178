import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { OrderAnonymousBaseComponent } from '../anonymous-base/anonymous-base.component';
import { PaymentService } from '../../new-order/payment/payment.services';
import { MatSnackBar } from '@angular/material';
import { environment } from 'environments/environment';
var OrdersAnonymousShipmentPaymentComponent = /** @class */ (function (_super) {
    tslib_1.__extends(OrdersAnonymousShipmentPaymentComponent, _super);
    function OrdersAnonymousShipmentPaymentComponent(_paymentService, _snackBar) {
        var _this = _super.call(this) || this;
        _this._paymentService = _paymentService;
        _this._snackBar = _snackBar;
        _this.retryAllowed = false;
        _this.checkoutLoaded = false;
        _this.payClicked = false;
        _this.loadCheckout = new Promise(function (resolve) {
            _this.loadScript();
            resolve(true);
        });
        return _this;
    }
    OrdersAnonymousShipmentPaymentComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loadCheckout.then(function (_) {
            _this.getCheckoutSession();
        });
    };
    OrdersAnonymousShipmentPaymentComponent.prototype.getCheckoutSession = function () {
        var _this = this;
        this.retryAllowed = false;
        this._paymentService.createCheckoutSession(this.form.value.orderId).subscribe(function (result) {
            _this.paymentSessionId = result.sessionId;
            _this.uniqueId = result.uniqueId;
            if (_this.paymentSessionId == null || _this.uniqueId === '00000000-0000-0000-0000-000000000000') {
                _this._snackBar.open('Error occured while generating payment session!');
                _this.retryAllowed = true;
            }
            var mainLoopId = setInterval(function () {
                if (!_this.checkoutLoaded) {
                    _this.configureCheckoutJs();
                    _this.checkoutLoaded = true;
                    clearInterval(mainLoopId);
                }
            }, 1000);
        }, function (error) {
            _this._snackBar.open('Error occured while generating payment session!');
            _this.retryAllowed = true;
        });
    };
    OrdersAnonymousShipmentPaymentComponent.prototype.configureCheckoutJs = function () {
        Checkout.configure({
            merchant: environment.merchantId,
            order: {
                amount: this.form.get('totalPrice').value,
                currency: 'USD',
                description: 'New Shipment Order',
                id: this.uniqueId
            },
            session: {
                id: this.paymentSessionId
            },
            interaction: {
                merchant: {
                    name: 'The Net Holding',
                    address: {
                        line1: 'Sin El Fil - Horsh Tabet Center 288 - Ground Floor',
                        line2: 'Beirut, Lebanon'
                    },
                    email: 'info@thenet.group',
                    phone: '+961 (1) 500-047',
                    logo: 'https://www.thenet.group/images/logo.svg'
                }
            }
        });
    };
    OrdersAnonymousShipmentPaymentComponent.prototype.loadScript = function () {
        var isFound = false;
        var scripts = document.getElementsByTagName('script');
        // tslint:disable-next-line: prefer-for-of
        for (var i = 0; i < scripts.length; ++i) {
            if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').includes('checkout.js')) {
                isFound = true;
            }
        }
        if (!isFound) {
            var node = document.createElement('script');
            node.src = 'https://ap-gateway.mastercard.com/checkout/version/51/checkout.js';
            node.type = 'text/javascript';
            node.async = false;
            node.setAttribute('data-cancel', 'cancelCallback');
            node.setAttribute('data-error', 'errorCallback');
            node.setAttribute('data-complete', window.location.origin + '/orders/payments/redirect');
            document.getElementsByTagName('head')[0].appendChild(node);
        }
    };
    OrdersAnonymousShipmentPaymentComponent.prototype.confirm = function () {
        this.payClicked = true;
        localStorage.setItem('orderId', this.form.value.orderId.toString());
        Checkout.showPaymentPage();
    };
    OrdersAnonymousShipmentPaymentComponent.prototype.paymentError = function (event) {
    };
    OrdersAnonymousShipmentPaymentComponent.prototype.paymentCanceled = function (event) {
    };
    return OrdersAnonymousShipmentPaymentComponent;
}(OrderAnonymousBaseComponent));
export { OrdersAnonymousShipmentPaymentComponent };
