import { OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { ServiceDirection } from 'app/_enums/ServiceDirection.enum';
import { QuoteData } from './quote-data.model';
import { FormGroup, FormBuilder, FormControl, FormArray, Validators } from '@angular/forms';
import { OrderDetail, Piece } from './order.model';
import { OrderService } from '../order.service';
import { Routes } from 'app/Routes';
import { takeUntil } from 'rxjs/operators';
import { AuthenticationService } from 'app/authentication/authentication.service';
import { Location } from '@angular/common';
import { OrdersOrderDetailsComponent } from './order-details/order-details.component';
import { QuoteService } from 'app/main/quotes/quote.service';
var OrdersNewOrderComponent = /** @class */ (function () {
    function OrdersNewOrderComponent(_router, _formBuilder, _orderService, _quoteService, _activatedRoute, _authService, _location) {
        var _this = this;
        this._router = _router;
        this._formBuilder = _formBuilder;
        this._orderService = _orderService;
        this._quoteService = _quoteService;
        this._activatedRoute = _activatedRoute;
        this._authService = _authService;
        this._location = _location;
        this._unsubscribeAll = new Subject();
        this.ServiceDirection = ServiceDirection;
        this.step = PanelOption.MyInfo;
        this.PanelOption = PanelOption;
        this.hasSubsidiaryId = true;
        this.imageUploadLoading = false;
        this.requiredUploadIdImage = false;
        this.firstTime = true;
        this.domesticCountryId = 158;
        // @ViewChild('cashOrderDetailComponent', { static: false }) cashOrderDetailComponent: OrdersCashOrderComponent;
        this.newQuote = new FormControl(true);
        this.blobToFile = function (theBlob, fileName) {
            var b = theBlob;
            b.lastModifiedDate = new Date();
            b.name = fileName;
            return theBlob;
        };
        var navigation = this._router.getCurrentNavigation();
        var state = navigation.extras.state;
        this.data = state || new QuoteData();
        this.proforma = [];
        // this.pieces = [];
        this.orderDetailGroup = this._formBuilder.group(new OrderDetail());
        this.orderDetailGroup.setControl('quote', this._formBuilder.group(this.data));
        this.orderDetailGroup.setControl('quoteId', this._formBuilder.control(this.data.quoteId));
        var quoteForm = this.orderDetailGroup.get('quote');
        quoteForm.setControl('pieces', this._formBuilder.array([]));
        this._activatedRoute.params.subscribe(function (params) {
            var id = params.id;
            if (id === 'new') {
                _this.orderId = 0;
                quoteForm.setControl('cods', _this._formBuilder.array([]));
                // this.mode = 'new'
            }
            // else if (id === 'cash') {
            //     this.orderId = 0;
            //     this.isCashOrder = true;
            //     this.newQuote.setValue(false);
            // }
            else {
                _this.orderId = +id;
                _this.mode = 'edit';
            }
        });
        // const t = new Promise(resolve => {
        //     this.loadScript();
        //     resolve(true);
        // });
    }
    Object.defineProperty(OrdersNewOrderComponent.prototype, "orderDetail", {
        get: function () {
            var order = new OrderDetail(this.orderDetailGroup.getRawValue());
            order.shipperName = this.orderDetailComponent.contacts.find(function (c) { return c.contactId === order.shipperId; }).fullName;
            order.receiverName = this.orderDetailComponent.contacts.find(function (c) { return c.contactId === order.receiverId; }).fullName;
            order.shipperAddress = this.orderDetailComponent.toLongDisplayString(this.orderDetailComponent.shipperAddresses.find(function (c) { return c.addressId === order.shipperAddressId; }));
            order.receiverAddress = this.orderDetailComponent.toLongDisplayString(this.orderDetailComponent.receiverAddresses.find(function (c) { return c.addressId === order.receiverAddressId; }));
            order.orderId = this.orderId;
            // order.proforma = this.orderDetailComponent.contacts.find(c => c.contactId === order.receiverId).fullName;
            return order;
        },
        enumerable: true,
        configurable: true
    });
    OrdersNewOrderComponent.prototype.ngOnInit = function () {
        var _this = this;
        // this._authService.hasSubsidiaryId().subscribe(result => {
        //     this.hasSubsidiaryId = result.status;
        //     this._orderService.getMyInfo().subscribe((info: BillingDetails) => {
        //         this.billingDetailsGroup = this._formBuilder.group(info);
        //         this.firstTime = !this.billingDetailsGroup.get('confirmedPhoneNumber').value;
        //         if (this.firstTime) {
        //             this.step = PanelOption.MyInfo;
        //         }
        //     });
        //     if (this.hasSubsidiaryId) {
        //         this.step = PanelOption.OrderDetails;
        //     }
        // });
        this.step = PanelOption.OrderDetails;
        // this._authService.IsWalkIn().subscribe(result => {
        //     this.isWalkIn = result.status;
        // });
        var quote = this.orderDetailGroup.get('quote');
        quote.get('type').valueChanges.subscribe(function (type) {
            if (type === ServiceDirection.Export) {
                _this.requiredUploadIdImage = true;
            }
        });
        // this._authService.hasUserIDImage().subscribe(result => {
        //     const hasUserIDImage = result.status;
        //     if (!hasUserIDImage) {
        //         const quote = this.orderDetailGroup.get('quote');
        //         quote.get('type').valueChanges.subscribe((type: ServiceDirection) => {
        //             if (type === ServiceDirection.Export) {
        //                 this.requiredUploadIdImage = true;
        //             }
        //         });
        //     }
        // });
        if (this.mode === 'edit') {
            this.initEditMode(this._orderService.selectedOrder);
        }
        this._orderService.onOrderSelected.pipe(takeUntil(this._unsubscribeAll)).subscribe(function (order) {
            _this.initEditMode(order);
        });
        this.data.type = this.getDirection(this.data.fromCountryId, this.data.toCountryId);
        if (this.data && this.mode === 'new') {
            var orderDetail = new OrderDetail({ quoteId: this.data.quoteId, quote: this.data });
            this.orderDetailGroup = this._formBuilder.group(orderDetail);
            this.orderDetailGroup.setControl('quote', this._formBuilder.group(this.data));
        }
        this._orderService.onOrderAdded.subscribe(function (orderId) {
            if (_this.orderId === 0) {
                _this.orderDetailGroup.get('orderId').setValue(orderId);
                _this.orderId = orderId;
            }
        });
        this.domesticCountryId = parseInt(localStorage.getItem('countryId').toString());
        // this.loadIdPhoto();
    };
    OrdersNewOrderComponent.prototype.getDirection = function (fromCountryId, toCountryId) {
        if (!fromCountryId || !toCountryId) {
            return undefined;
        }
        if (fromCountryId === this.domesticCountryId && toCountryId === this.domesticCountryId) {
            // domestic
            return ServiceDirection.Domestic;
        }
        else if (fromCountryId === this.domesticCountryId) {
            // Export
            return ServiceDirection.Export;
        }
        else if (toCountryId === this.domesticCountryId) {
            // Import
            return ServiceDirection.Import;
        }
        else {
            // Not Supported
            return undefined;
        }
    };
    OrdersNewOrderComponent.prototype.initEditMode = function (order) {
        var _this = this;
        if (order === null) {
            return;
        }
        if (this.orderId === 0) {
            this._router.navigateByUrl(Routes.orders);
        }
        order.detail.quote.type = this.getDirection(order.detail.quote.fromCountryId, order.detail.quote.toCountryId);
        // fill up the quote data with the order details that is being sent when we edit an existing order.
        this.data = order.detail.quote;
        this.orderDetailGroup.patchValue(order.detail);
        var prof = order.detail.proforma;
        this.orderDetailGroup.get('proforma').patchValue(prof);
        var pieces = order.detail.quote.pieces;
        this.orderDetailGroup.get('quote').get('pieces').patchValue(pieces);
        var quoteForm = this.orderDetailGroup.get('quote');
        quoteForm.setControl('pieces', this._formBuilder.array([], Validators.required));
        // this.contactForm.setControl('addresses', this._formBuilder.array([], Validators.required));
        var piecesFormArray = quoteForm.get('pieces');
        // this.group.get('quote').get('pieces').value.forEach(piece => {
        //     piecesFormArray.push(this._formBuilder.group(piece));
        // });
        if (this.data.cods.length === 0) {
            quoteForm.setControl('cods', this._formBuilder.array([]));
        }
        var pieces = order.detail.quote.pieces;
        if (pieces.length > 0) {
            pieces.forEach(function (piece) {
                piecesFormArray.push(_this._formBuilder.group(piece));
            });
        }
        else {
            piecesFormArray.push(this._formBuilder.group(new Piece()));
        }
    };
    OrdersNewOrderComponent.prototype.ngOnDestroy = function () {
        this._orderService.onOrderSelected.complete();
        this._orderService.onOrderAdded.complete();
        this._orderService.selectedOrder = null;
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    };
    OrdersNewOrderComponent.prototype.setStep = function (option) {
        this.step = option;
    };
    OrdersNewOrderComponent.prototype.nextStep = function (option) {
        this.step = option;
    };
    OrdersNewOrderComponent.prototype.prevStep = function (option) {
        this.step = option;
    };
    OrdersNewOrderComponent.prototype.goBackFromPayment = function () {
        var quote = this.orderDetailGroup.value.quote;
        var direction = this.getDirection(quote.fromCountryId, quote.toCountryId);
        if (!this.hasSubsidiaryId || this.firstTime) {
            this.prevStep(PanelOption.MyInfo);
        }
        else if (this.hasSubsidiaryId && direction === ServiceDirection.Export && this.requiredUploadIdImage) {
            this.prevStep(PanelOption.UploadIDPhoto);
        }
        else {
            this.prevStep(PanelOption.OrderDetails);
        }
    };
    OrdersNewOrderComponent.prototype.saveOrderDetails = function () {
        var _this = this;
        var data = this.orderDetailGroup.getRawValue();
        data.orderId = this.orderId;
        if (this.orderId === 0) {
            this._orderService.addOrderDetails(data).then(function (result) {
                _this.afterSaveOrder(result);
            });
        }
        else if (this.orderId > 0) {
            this._orderService.updateOrderDetails(data).then(function (result) {
                _this.afterSaveOrder(result);
            });
        }
    };
    // saveOrderSurcharges(orderId: number) {
    //     var orderSurchargesToSave = this.orderDetailComponent.orderSurcharges;
    //     if (orderSurchargesToSave.length != 0) {
    //         orderSurchargesToSave.forEach(element => {
    //             element.orderId = orderId;
    //         });
    //         if (orderSurchargesToSave)
    //             this._quoteService.saveSurcharges(orderSurchargesToSave).subscribe(result => {
    //             });
    //     }
    // }
    // saveCodAmounts(quoteId) {
    //     var orderCod = this.orderDetailComponent.orderCod;
    //     if (orderCod.length > 0) {
    //         orderCod = orderCod.map(item => {
    //             item.QuoteId = quoteId
    //             return item;
    //         });
    //         this._quoteService.saveCodAmount(orderCod).subscribe(result => {
    //         });
    //     }
    // }
    OrdersNewOrderComponent.prototype.confirmOrderDetails = function (event) {
        var _this = this;
        var data = this.orderDetailGroup.getRawValue();
        if (data.quote.shippingTypeId.toString().trim() == "")
            data.quote.shippingTypeId = 0;
        data.orderId = this.orderId;
        if (this.orderId === 0) {
            this._orderService.addOrderDetails(data).then(function (result) {
                _this.orderId = result.orderId;
                _this.orderDetailComponent.updateQuoteAndOrderId(result.orderId, result.quoteId);
                _this.orderDetailComponent.saveOrderSurcharges(result.orderId);
                _this.orderDetailComponent.saveCodAmounts(result.quoteId);
                _this.orderDetailComponent.saveOrderPieces(result.quoteId);
                _this.newQuote.setValue(false);
            });
        }
        else if (this.orderId > 0) {
            this._orderService.updateOrderDetails(data).then(function (result) {
                localStorage.setItem('orderId', result.orderId.toString());
                _this.orderId = result.orderId;
                var quoteForm = _this.orderDetailComponent.group.get('quote');
                // quoteForm.get('quotePrice').setValue(quoteForm.value.quotePrice,{ emitEvent: false });
                // this.orderDetailComponent.newQuote.setValue(false ,{ emitEvent: false });
                _this.orderDetailComponent.updateQuotePrice(quoteForm.get('quotePrice').value, quoteForm.get('quoteCurrency').value);
                _this.orderDetailComponent.saveOrderSurcharges(result.orderId);
                _this.orderDetailComponent.saveCodAmounts(result.quoteId);
                _this.orderDetailComponent.saveOrderPieces(result.quoteId);
            });
        }
    };
    OrdersNewOrderComponent.prototype.afterSaveConfirmOrder = function (result) {
        var quoteForm = this.orderDetailGroup.get('quote');
        quoteForm.get('quoteId').setValue(result.quoteId, { emitEvent: false });
        this.orderDetailGroup.get('quoteId').setValue(result.quoteId, { emitEvent: false });
        this.orderDetailComponent.orderDetail.orderId = result.orderId;
        this.orderId = result.orderId;
        if (this.requiredUploadIdImage) {
            this.nextStep(PanelOption.UploadIDPhoto);
        }
        else {
            this.nextStep(PanelOption.Payment);
        }
        this._location.replaceState('/orders/' + this.orderId);
    };
    OrdersNewOrderComponent.prototype.afterSaveOrder = function (result) {
        var quoteForm = this.orderDetailGroup.get('quote');
        this.orderDetail.orderId = result.orderId;
        quoteForm.get('quoteId').setValue(result.quoteId, { emitEvent: false });
        this.orderDetailGroup.get('quoteId').setValue(result.quoteId, { emitEvent: false });
        this.orderId = result.orderId;
        if (this.requiredUploadIdImage) {
            this.nextStep(PanelOption.UploadIDPhoto);
        }
        else {
            this.nextStep(PanelOption.Payment);
        }
        this._location.replaceState('/orders/' + this.orderId);
    };
    OrdersNewOrderComponent.prototype.saveBillingDetails = function () {
        var _this = this;
        this._orderService.addBillingDetails(this.billingDetailsGroup.value).then(function (user) {
            _this._authService.onUserInfoUpdated.next(user);
            _this.nextStep(PanelOption.OrderDetails);
            // update list of contacts to display the new Home contact (if created)
            _this.orderDetailComponent.getAllContacts(false);
        });
    };
    OrdersNewOrderComponent.prototype.uploadIDPhoto = function (files) {
        var _this = this;
        this.message = '';
        if (files.length === 0) {
            return;
        }
        var mimeType = files[0].type;
        if (mimeType.match(/image\/*/) == null) {
            this.message = 'Only images are supported.';
            this.idImgURL = null;
            return;
        }
        var reader = new FileReader();
        this.imagePath = files;
        reader.readAsDataURL(files[0]);
        reader.onload = function (_event) {
            _this.idImgURL = reader.result;
        };
        this.uploadIdPhotoAndContinue();
    };
    OrdersNewOrderComponent.prototype.uploadIdPhotoAndContinue = function () {
        var _this = this;
        if (!this.imagePath) {
            this.message = 'You need to upload your ID';
            return;
        }
        this.imageUploadLoading = true;
        this._authService.uploadUserIDPhoto(this.imagePath[0]).subscribe(function (result) {
            _this.imageUploadLoading = false;
            _this.nextStep(PanelOption.Payment);
        }, function (error) {
            _this.message = 'Error while uploading your image ID to the server.';
            _this.imageUploadLoading = false;
        });
    };
    OrdersNewOrderComponent.prototype.ContinueAfterPhoto = function () {
        this.nextStep(PanelOption.Payment);
    };
    OrdersNewOrderComponent.prototype.loadIdPhoto = function () {
        var _this = this;
        this._authService.downloadUserIDPhoto().subscribe(function (result) {
            if (result) {
                var myBlob = result;
                if (myBlob.size == 0)
                    return;
                var myFile = _this.blobToFile(myBlob, "my-image.png");
                var reader_1 = new FileReader();
                reader_1.readAsDataURL(myFile);
                reader_1.onload = function (_event) {
                    _this.idImgURL = reader_1.result;
                };
            }
        }, function (error) {
            _this.message = 'Error while downloading your image from the server.';
        });
    };
    OrdersNewOrderComponent.prototype.goBackFromUploadId = function () {
        this.prevStep(PanelOption.OrderDetails);
    };
    OrdersNewOrderComponent.prototype.loadScript = function () {
        var isFound = false;
        var scripts = document.getElementsByTagName('script');
        // tslint:disable-next-line: prefer-for-of
        for (var i = 0; i < scripts.length; ++i) {
            if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').includes('checkout.js')) {
                isFound = true;
            }
        }
        if (!isFound) {
            var node = document.createElement('script');
            node.src = 'https://ap-gateway.mastercard.com/checkout/version/51/checkout.js';
            node.type = 'text/javascript';
            node.async = false;
            node.setAttribute('data-cancel', 'cancelCallback');
            node.setAttribute('data-error', 'errorCallback');
            node.setAttribute('data-complete', window.location.origin + '/orders/payments/redirect');
            document.getElementsByTagName('head')[0].appendChild(node);
        }
    };
    return OrdersNewOrderComponent;
}());
export { OrdersNewOrderComponent };
export var PanelOption;
(function (PanelOption) {
    PanelOption[PanelOption["OrderDetails"] = 0] = "OrderDetails";
    PanelOption[PanelOption["MyInfo"] = 1] = "MyInfo";
    PanelOption[PanelOption["UploadIDPhoto"] = 2] = "UploadIDPhoto";
    PanelOption[PanelOption["Payment"] = 3] = "Payment";
})(PanelOption || (PanelOption = {}));
