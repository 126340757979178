import { EmailConfirmedComponent } from 'app/authentication/email-confirmed/email-confirmed.component';
import { EmailConfirmedService } from './email-confirmed.service';
import { AnonymousGuard } from 'app/_guards/anonymous.guard';
var ɵ0 = EmailConfirmedService;
var routes = [
    {
        path: 'auth/email-confirmed',
        component: EmailConfirmedComponent,
        resolve: {
            data: ɵ0
        },
        canActivate: [AnonymousGuard]
    }
];
var EmailConfirmedModule = /** @class */ (function () {
    function EmailConfirmedModule() {
    }
    return EmailConfirmedModule;
}());
export { EmailConfirmedModule };
export { ɵ0 };
