import { OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MatChipInputEvent, MatDialog } from '@angular/material';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { Contact, Address } from './contact.model';
import { Subject } from 'rxjs';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { PhoneValidator } from 'app/_validators/phone.validator';
import { ContactsContactListService } from '../contact-list/contact-list.service';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { LocationService } from 'app/_shared/location-service.service';
var ContactEditorComponent = /** @class */ (function () {
    function ContactEditorComponent(matDialogRef, _data, _formBuilder, _contactService, _matDialog, _locationService) {
        var _this = this;
        this.matDialogRef = matDialogRef;
        this._data = _data;
        this._formBuilder = _formBuilder;
        this._contactService = _contactService;
        this._matDialog = _matDialog;
        this._locationService = _locationService;
        this.selectedTab = 0;
        this.isNewAddressOpen = false;
        this.editIndex = [];
        this._unsubscribeAll = new Subject();
        this.visible = true;
        this.selectable = true;
        this.removable = true;
        this.addOnBlur = true;
        this.separatorKeysCodes = [ENTER, COMMA];
        this.tags = [];
        this.contactExist = false;
        this.firstload = true;
        this.cityid = 0;
        // Set the defaults
        this.action = _data.action;
        if (this.action === 'edit') {
            this.contact = _data.contact;
            this.countries = _data.countries;
            this.firstload = true;
        }
        else if (this.action === 'import') {
            this.firstload = true;
            this.contact = _data.contact;
            this.selectedCities = _data.selectedcities;
            this.countries = _data.countries;
            var contactCity = this.selectedCities.find(function (e) { return e.cityName == _this.contact.addresses[0].cityName; });
            if (contactCity) {
                this.contact.addresses[0].cityId = this.selectedCities.find(function (e) { return e.cityName == _this.contact.addresses[0].cityName; }).cityId;
                this.contact.addresses[0].countryId = this.selectedCities.find(function (e) { return e.cityName == _this.contact.addresses[0].cityName; }).countryId;
            }
        }
        else {
            this.contact = new Contact();
            this.countries = _data.countries;
        }
        this.createContactForm();
        this.createAddressForm();
        this.subscribeContactExist();
    }
    Object.defineProperty(ContactEditorComponent.prototype, "formValue", {
        get: function () {
            return this.contactForm.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ContactEditorComponent.prototype, "addNewIndex", {
        get: function () {
            var value = this.contactForm.value;
            return value && value.addresses ? value.addresses.length - 1 : -1;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ContactEditorComponent.prototype, "contactAddresses", {
        get: function () {
            return this.contactForm.get('addresses').controls;
        },
        enumerable: true,
        configurable: true
    });
    ContactEditorComponent.prototype.ngOnInit = function () {
    };
    ContactEditorComponent.prototype.ngOnDestroy = function () {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    };
    ContactEditorComponent.prototype.subscribeContactExist = function () {
        // this.contactForm
        //     .get('firstName')
        //     .valueChanges.pipe(debounceTime(500))
        //     .subscribe(_ => this.checkExist());
        // this.contactForm
        //     .get('lastName')
        //     .valueChanges.pipe(debounceTime(500))
        //     .subscribe(_ => this.checkExist());
        // this.contactForm
        //     .get('phoneNumber')
        //     .valueChanges.pipe(debounceTime(500))
        //     .subscribe(_ => this.checkExist());
        // this.contactForm
        //     .get('email')
        //     .valueChanges.pipe(debounceTime(500))
        //     .subscribe(_ => this.checkExist());
        // if (this.action === 'import') {
        //     Object.keys(this.contactForm.controls).forEach(field => {
        //         const control = this.contactForm.get(field);
        //         control.markAsTouched({ onlySelf: true });
        //     });
        // }
    };
    ContactEditorComponent.prototype.checkExist = function () {
        var _this = this;
        var contact = {
            contactId: this.contactForm.get('contactId').value,
            firstName: this.contactForm.get('firstName').value,
            lastName: this.contactForm.get('lastName').value,
            phoneNumber: this.contactForm.get('phoneNumber').value,
            email: this.contactForm.get('email').value
        };
        this._contactService.isContactExist(contact).then(function (result) {
            _this.contactExist = result.status;
            _this.contactExistId = result.contactId;
            if (_this.contactExist) {
                _this.openExistWarningDialog();
            }
        });
    };
    ContactEditorComponent.prototype.openExistWarningDialog = function () {
        this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });
        this.confirmDialogRef.componentInstance.header = 'Contact Already Exists';
        this.confirmDialogRef.componentInstance.confirm = 'Ok';
        this.confirmDialogRef.componentInstance.cancel = null;
        this.confirmDialogRef.componentInstance.confirmMessage =
            'Please note that this contact already exists in your address book!';
    };
    ContactEditorComponent.prototype.createAddressForm = function () {
        this.activeAddress = this._formBuilder.group(new Address());
        this.activeAddress.get('cityId').disable();
    };
    ContactEditorComponent.prototype.createContactForm = function () {
        var _this = this;
        this.tags = this.contact.tags.slice();
        this.contact.tags = [];
        this.contactForm = this._formBuilder.group(this.contact);
        this.contactForm.setControl('addresses', this._formBuilder.array([], Validators.required));
        var addressesFormArray = this.contactForm.get('addresses');
        this.contact.addresses.forEach(function (address) {
            addressesFormArray.push(_this._formBuilder.group(address));
            _this.editIndex.push(false);
        });
        this.contactForm.get('email').setValidators(Validators.email);
        var phoneCountryCode = this.contactForm.get('phoneCountryCode');
        if (phoneCountryCode) {
            if (phoneCountryCode.value) {
                var phoneCountry = this.countries.find(function (country) { return country.countryPhoneCode == phoneCountryCode.value; });
                if (phoneCountry)
                    var country = new FormControl({ iso: phoneCountry }, Validators.required);
            }
            else
                var country = new FormControl({ iso: 'LB' }, Validators.required);
        }
        var phoneNumberValid = this.contactForm.get('validPhoneNumber');
        if (!phoneNumberValid) {
            this.contactForm.setControl('validPhoneNumber', this._formBuilder.control(false));
            phoneNumberValid = this.contactForm.get('validPhoneNumber');
        }
        this.contactForm
            .get('phoneNumber')
            .setValidators([Validators.required, PhoneValidator.validCountryMobile(country, phoneNumberValid)]);
        phoneNumberValid.valueChanges
            .pipe(takeUntil(this._unsubscribeAll), debounceTime(0))
            .subscribe(function (value) {
            if (!value) {
                _this.contactForm.get('phoneNumber').setErrors(Validators.required);
            }
        });
        this.contactForm.valueChanges.subscribe(function (_) { return (_this.firstload = false); });
    };
    ContactEditorComponent.prototype.countryChange = function (value) {
        var _this = this;
        // this.contactForm.get('phoneNumber').setValue('');
        // && country.displayName.toString() == 
        var phoneCountry = this.countries.find(function (country) { return country.countryPhoneCode == value.value; });
        var country = new FormControl({ iso: phoneCountry }, Validators.required);
        var phoneNumberValid = this.contactForm.get('validPhoneNumber');
        phoneNumberValid.setErrors(Validators.required);
        this.contactForm.get('phoneNumber').setErrors(Validators.required);
        if (!phoneNumberValid) {
            this.contactForm.setControl('validPhoneNumber', this._formBuilder.control(false));
            phoneNumberValid = this.contactForm.get('validPhoneNumber');
        }
        this.contactForm
            .get('phoneNumber')
            .setValidators([Validators.required, PhoneValidator.validCountryMobile(country, phoneNumberValid)]);
        phoneNumberValid.valueChanges
            .pipe(takeUntil(this._unsubscribeAll), debounceTime(0))
            .subscribe(function (value) {
            if (!value) {
                _this.contactForm.get('phoneNumber').setErrors(Validators.required);
            }
        });
        this.contactForm
            .get('phoneNumber').updateValueAndValidity();
    };
    ContactEditorComponent.prototype.addNewAddress = function () {
        var addresses = this.contactForm.get('addresses');
        addresses.push(this._formBuilder.group(new Address()));
    };
    ContactEditorComponent.prototype.addressAdded = function (data) {
        var addressForm = data.value;
        var mode = data.mode;
        var index = data.index;
        if (mode === 'cancel') {
            if (this.isNewAddressOpen && index === -1) {
                this.isNewAddressOpen = false;
            }
            else if (index >= 0) {
                this.editIndex[index] = false;
            }
            return;
        }
        var addressesFormArray = this.contactForm.get('addresses');
        var address = this._formBuilder.group(addressForm);
        if (mode === 'new') {
            addressesFormArray.push(address);
            this.isNewAddressOpen = false;
        }
        else if (mode === 'edit') {
            addressesFormArray.setControl(index, address);
            this.editIndex[index] = false;
        }
    };
    ContactEditorComponent.prototype.removeAddress = function (index) {
        var addressesFormArray = this.contactForm.get('addresses');
        addressesFormArray.removeAt(index);
        this.contactForm.markAsTouched();
        this.contactForm.markAsDirty();
        this.contactForm.updateValueAndValidity();
    };
    ContactEditorComponent.prototype.editAddress = function (index) {
        this.editIndex[index] = true;
    };
    ContactEditorComponent.prototype.add = function (event) {
        var input = event.input;
        var value = event.value;
        // Add our fruit
        if ((value || '').trim()) {
            this.tags.push(value.trim());
        }
        // Reset the input value
        if (input) {
            input.value = '';
        }
    };
    ContactEditorComponent.prototype.remove = function (tag) {
        var index = this.tags.indexOf(tag);
        if (index >= 0) {
            this.tags.splice(index, 1);
        }
    };
    ContactEditorComponent.prototype.saveNew = function () {
        if (!this.contactForm.valid) {
            this.contactForm.markAllAsTouched();
            return;
        }
        this.matDialogRef.close(['new', this.contactForm.value, this.tags]);
    };
    ContactEditorComponent.prototype.saveExisting = function () {
        if (!this.contactForm.valid) {
            return;
        }
        this.matDialogRef.close(['edit', this.contactForm.value, this.tags]);
    };
    ContactEditorComponent.prototype.onSaveUsernameChanged = function (value) {
        this.saveUsername = value;
    };
    return ContactEditorComponent;
}());
export { ContactEditorComponent };
