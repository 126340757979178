import { OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MatChipInputEvent } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContactList } from './c-list.model';
import { Subject } from 'rxjs';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
var ContactListFormComponent = /** @class */ (function () {
    function ContactListFormComponent(matDialogRef, _data, _formBuilder) {
        this.matDialogRef = matDialogRef;
        this._data = _data;
        this._formBuilder = _formBuilder;
        this.selectedTab = 0;
        // activeAddress: FormGroup;
        // activeEditAddress: FormGroup;
        // isNewAddressOpen = false;
        this.editIndex = [];
        this._unsubscribeAll = new Subject();
        this.visible = true;
        this.selectable = true;
        this.removable = true;
        this.addOnBlur = true;
        this.separatorKeysCodes = [ENTER, COMMA];
        this.tags = [];
        // Set the defaults
        this.action = _data.action;
        // this.tags =  [];
        if (this.action === 'edit') {
            this.contactList = _data.contactList;
            this.contacts = _data.contacts;
            // this.contactListDetail = _data.contactListDetail;
            this.contactListDetail = _data.contactlist.contactListDetail;
        }
        else {
            this.contactList = new ContactList();
        }
        this.createContactListForm();
        // this.createAddressForm();
    }
    Object.defineProperty(ContactListFormComponent.prototype, "formValue", {
        get: function () {
            return this.contactListForm.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ContactListFormComponent.prototype, "addNewIndex", {
        get: function () {
            var value = this.contactListForm.value;
            return value;
        },
        enumerable: true,
        configurable: true
    });
    // get contactListContacts(): any {
    //     return (this.contactListForm.get('contactListDetail') as FormArray).controls;
    // }
    ContactListFormComponent.prototype.ngOnInit = function () {
    };
    ContactListFormComponent.prototype.ngOnDestroy = function () {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    };
    ContactListFormComponent.prototype.createContactListForm = function () {
        this.tags = this.contactList.tags.slice();
        this.contactListDetail = this.contactList.contactListDetail;
        this.contactList.tags = [];
        // this.contactList.contactListDetail = [];
        this.contactListForm = this._formBuilder.group(this.contactList);
        this.contactListForm.setControl('contactListDetail', this._formBuilder.array([], Validators.required));
        // const addressesFormArray = this.contactListForm.get('addresses') as FormArray;
        // this.contact.addresses.forEach(address => {
        //     addressesFormArray.push(this._formBuilder.group(address));
        //     this.editIndex.push(false);
        // });
        // this.contactListForm.get('email').setValidators(Validators.email);
        // const country = new FormControl({ iso: 'LB' }, Validators.required);
        // this.contactListForm.get('phoneNumber').setValidators(PhoneValidator.validCountryPhone(country));
    };
    // addNewAddress(): void {
    //     this.isNewAddressOpen = true;
    //     this.createAddressForm();
    // }
    // addressAdded(data: any): void {
    //     const addressForm: FormGroup = data.value;
    //     const mode: string = data.mode;
    //     const index: number = data.index;
    //     if (mode === 'cancel') {
    //         if (this.isNewAddressOpen && index === -1) {
    //             this.isNewAddressOpen = false;
    //         } else if (index >= 0) {
    //             this.editIndex[index] = false;
    //         }
    //         return;
    //     }
    //     const addressesFormArray = this.contactListForm.get('addresses') as FormArray;
    //     const address = this._formBuilder.group(addressForm);
    //     if (mode === 'new') {
    //         addressesFormArray.push(address);
    //         this.isNewAddressOpen = false;
    //     } else if (mode === 'edit') {
    //         addressesFormArray.setControl(index, address);
    //         this.editIndex[index] = false;
    //     }
    // }
    // removeAddress(index: number): void {
    //     const addressesFormArray = this.contactListForm.get('addresses') as FormArray;
    //     addressesFormArray.removeAt(index);
    // }
    // editAddress(index: number): void {
    //     // const addressesFormArray = this.contactForm.get('addresses') as FormArray;
    //     // this.activeEditAddress = this._formBuilder.group(addressesFormArray.controls[index].value);
    //     this.editIndex[index] = true;
    // }
    ContactListFormComponent.prototype.add = function (event) {
        var input = event.input;
        var value = event.value;
        // Add our fruit
        if ((value || '').trim()) {
            this.tags.push(value.trim());
        }
        // Reset the input value
        if (input) {
            input.value = '';
        }
    };
    ContactListFormComponent.prototype.remove = function (tag) {
        var index = this.tags.indexOf(tag);
        if (index >= 0) {
            this.tags.splice(index, 1);
        }
    };
    ContactListFormComponent.prototype.saveNew = function () {
        this.matDialogRef.close(['new', this.contactListForm.value, this.tags]);
    };
    ContactListFormComponent.prototype.saveExisting = function () {
        this.matDialogRef.close(['edit', this.contactListForm.value, this.tags]);
    };
    return ContactListFormComponent;
}());
export { ContactListFormComponent };
