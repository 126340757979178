import { OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { Subject } from 'rxjs';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/internal/operators';
import { CurrencyType } from 'app/_enums/ServiceType.enum';
import { Router } from '@angular/router';
var OrderReturnDialogComponent = /** @class */ (function () {
    function OrderReturnDialogComponent(_router, _formBuilder, matDialogRef, _data) {
        this._router = _router;
        this._formBuilder = _formBuilder;
        this.matDialogRef = matDialogRef;
        this._data = _data;
        this._unsubscribeAll = new Subject();
        // Workaround for angular component issue #13870
        this.disableAnimation = true;
        this.minDate = new Date(new Date().setDate(new Date().getDate() + 1));
        this.cashEnabled = false;
        this.orderCod = [];
        this.returnOrderForm = this._formBuilder.group({
            retourTypeId: ['', [Validators.required]],
            collectionDate: [this.minDate, [Validators.required]],
            specialInstructions: ['', null] //,
            // codAmount: ['', null],
            // currencyId: [CurrencyType.LBP, null]
        });
        this.returnOrdersType = _data.ReturnOrdersType;
    }
    OrderReturnDialogComponent.prototype.ngOnInit = function () {
        if (this.returnOrdersType == "1") {
            this.retourTypes = [
                { name: "Cash", value: 4 }
            ];
        }
        else if (this.returnOrdersType == "4")
            this.retourTypes = [
                { name: "Docs/Parcels", value: 1 }
            ];
        else {
            this.retourTypes = [
                { name: "Docs/Parcels", value: 1 },
                { name: "Cash", value: 4 }
            ];
        }
        this.currencyTypes = this.enumSelector(CurrencyType).slice(7, 13);
        // this.currencyTypes = this.currencyTypes.slice(2, 4);
        this.subscribeRetourTypeChanged();
    };
    OrderReturnDialogComponent.prototype.enumSelector = function (definition) {
        return Object.keys(definition)
            .map(function (key) { return ({ value: definition[key], title: key }); });
    };
    Object.defineProperty(OrderReturnDialogComponent.prototype, "formValue", {
        get: function () {
            return this.returnOrderForm.value;
        },
        enumerable: true,
        configurable: true
    });
    OrderReturnDialogComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        // timeout required to avoid the dreaded 'ExpressionChangedAfterItHasBeenCheckedError'
        setTimeout(function () { return (_this.disableAnimation = false); });
    };
    OrderReturnDialogComponent.prototype.ngOnDestroy = function () {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    };
    OrderReturnDialogComponent.prototype.subscribeRetourTypeChanged = function () {
        this.returnOrderForm
            .get('retourTypeId')
            .valueChanges.pipe(takeUntil(this._unsubscribeAll))
            .subscribe();
    };
    OrderReturnDialogComponent.prototype.confirm = function () {
        if (this.returnOrderForm.invalid)
            return;
        else
            this.matDialogRef.close(['new', this.returnOrderForm.value,]);
        // if (this.returnOrderForm.get('orderCod').value.length == 0)
        //     this.returnOrderForm.get('orderCod').setValue(null);
        // this.returnOrderForm.updateValueAndValidity();
        // if (this.returnOrderForm.get('orderCod').value.length == 0)
        //     return;
        // this.matDialogRef.close(['new', this.returnOrderForm.value,]);
    };
    OrderReturnDialogComponent.prototype.validate = function () {
    };
    OrderReturnDialogComponent.prototype.onChange = function (event) {
        if (event.value == 4) // Cash
         {
            this.cashEnabled = true;
            this.returnOrderForm.addControl('orderCod', new FormControl('', [Validators.required]));
            // this.returnOrderForm.addControl('currencyId', new FormControl(CurrencyType.LBP, [Validators.required]));
            // this.returnOrderForm.addControl('codAmount', new FormControl('', [Validators.required]));
        }
        else {
            this.returnOrderForm.removeControl('orderCod');
            // this.returnOrderForm.removeControl('currencyId');
            // this.returnOrderForm.removeControl('codAmount');
            this.cashEnabled = false;
        }
    };
    OrderReturnDialogComponent.prototype.numberOnly = function (event) {
        var charCode = event.which ? event.which : event.keyCode;
        var invalidChars = ['-', '+', 'e'];
        var valid = ['.'];
        if (valid.includes(event.key)) {
            return true;
        }
        if (charCode > 31 && (charCode < 48 || charCode > 57) && invalidChars.includes(event.key)) {
            return false;
        }
        return true;
    };
    OrderReturnDialogComponent.prototype.codAdded = function (data) {
        var _this = this;
        if (data == null) {
            this.returnOrderForm.get('orderCod').setErrors({ required: true });
            ;
            return;
        }
        this.orderCod = [];
        data.forEach(function (element) {
            // if (element.CodAmount > 0)
            _this.orderCod.push(element);
        });
        this.returnOrderForm.get('orderCod').setValue(this.orderCod);
        if (data.length > 0)
            this.returnOrderForm.get('orderCod').setErrors(null);
    };
    return OrderReturnDialogComponent;
}());
export { OrderReturnDialogComponent };
