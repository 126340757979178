import { OnInit, ChangeDetectorRef } from '@angular/core';
import { Validators, FormControl, NgForm } from '@angular/forms';
import * as XLSX from 'xlsx';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { takeUntil } from 'rxjs/operators';
import { Subject, ReplaySubject } from 'rxjs';
import { PhoneValidator } from 'app/_validators/phone.validator';
import { isNullOrUndefined } from 'util';
import { MatButton } from '@angular/material/button';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CurrencyType } from 'app/_enums/ServiceType.enum';
import { SkuStock } from '../../new-order/order.model';
var read = XLSX.read, write = XLSX.write, utils = XLSX.utils;
var ImportOrderComponent = /** @class */ (function () {
    function ImportOrderComponent(_data, _matDialog, cdr, matDialogRef) {
        this._data = _data;
        this._matDialog = _matDialog;
        this.cdr = cdr;
        this.matDialogRef = matDialogRef;
        this.wopts = { bookType: 'xlsx', type: 'array' };
        this._unsubscribeAll = new Subject();
        this.hasSKUErrors = false;
        this.invalidSku = [];
        this.storeKey = localStorage.getItem('netPointKey');
        this.bulkSettings = localStorage.getItem('bulkSettings');
        this.countryCities = [];
        this.selection = new SelectionModel(true, []);
        // contactDialogRef: MatDialogRef<ContactEditorComponent>;
        // displayedColumns: string[] = ['edit', 'shipper', 'firstname', 'lastname', 'email', 'phoneCountryCode', 'mobile',
        //     'country', 'city', 'street', 'building', 'floor', 'landmark', 'postCode', 'weight', 'shipmentValue', 'codAmount', 'codCurrency'];
        this.displayedColumns = ['edit', 'shipperReference', 'shipperId', 'firstname', 'lastname', 'companyName', 'phoneCountryCode', 'mobile',
            'city', 'street', 'building', 'floor', 'landmark', 'specialInstructions', 'weight', 'nop', 'codAmount', 'codCurrencyId', 'codAmount2', 'codCurrencyId2', 'skus'];
        this.displayedColumnsNew = ['edit', 'shipperReference', 'shipperId', 'firstname', 'lastname', 'companyName', 'phoneCountryCode', 'mobile',
            'city', 'street', 'building', 'floor', 'landmark', 'specialInstructions', 'weight', 'nop', 'codAmount', 'codCurrencyId', 'codAmount2', 'codCurrencyId2', 'skus', 'skuqty'];
        this.contactsValidated = false;
        // options = new BehaviorSubject<City[]>([]);
        // options$: Observable<City[]>;
        this.showProgressBar = true;
        this.isEditOn = false;
        this.ConfirmButtonDisabled = true;
        this.countinValid = 0;
        this.countryFilterCtrl = new FormControl();
        this.filteredCountries = new ReplaySubject(1);
        this.cityFilterCtrl = new FormControl();
        this.filteredCities = new ReplaySubject(1);
        this.countryCodeFilterCtrl = new FormControl();
        this.filteredCountryCodes = new ReplaySubject(1);
        this.orders = _data.orders;
        this.allCities = _data.cities;
        this.countries = _data.countries;
        this.countryCodes = _data.countries;
        this.contacts = _data.contacts;
        this.shipperReferences = _data.shipperReferences;
        this.existingSkus = _data.existingSkusList;
    }
    ImportOrderComponent.prototype.ngOnInit = function () {
        this.currencyTypes = this.enumSelector(CurrencyType).slice(7, 13);
    };
    ImportOrderComponent.prototype.enumSelector = function (definition) {
        return Object.keys(definition)
            .map(function (key) { return ({ value: definition[key], title: key }); });
    };
    ImportOrderComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.countryCodeFilterCtrl.valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function () {
            _this.filterCountryCodes();
        });
        this.cityFilterCtrl.valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function () {
            _this.filterCities();
        });
    };
    ImportOrderComponent.prototype.ngAfterContentInit = function () {
        var _this = this;
        this.orders.sort(function (b, a) {
            if (a.isValid === b.isValid) {
                return 0;
            }
            if (a.isValid) {
                return -1;
            }
            if (b.isValid) {
                return 1;
            }
        });
        this.invalidOrders = this.orders.filter(function (c) { return c.isValid == false; });
        setTimeout(function () {
            _this.dataSource = new MatTableDataSource(_this.orders);
            _this.dataSource.data.sort;
            _this.filteredCountryCodes.next(_this.countries.slice());
            _this.filteredCities.next(_this.allCities.slice());
            var element = document.getElementById('auto_trigger');
            element.click();
            _this.importForm.control.valueChanges.subscribe(function (x) {
                if (x) {
                    var countryCodeControl_1 = _this.importForm.form.controls['txtshipperReference-' + _this.editIndex];
                    // const codControl = this.importForm.form.controls['txtCodAmount2-' + this.editIndex];
                    // console.log(codControl.value);
                    if (countryCodeControl_1) {
                        if (_this.shipperReferences.findIndex(function (x) { return x.toString().toLowerCase() == countryCodeControl_1.value.toString().toLowerCase(); }) >= 0) {
                            countryCodeControl_1.setErrors({ 'duplicate': true });
                        }
                        else {
                            countryCodeControl_1.setErrors(null);
                        }
                    }
                }
            });
            if (_this.invalidOrders.length > 0)
                _this.ConfirmButtonDisabled = true;
            else
                _this.ConfirmButtonDisabled = false;
            if (_this.orders.some(function (o) { return !isNullOrUndefined(o.invalidSku); }) && _this.orders.some(function (o) { return o.invalidSku.length > 0; })) {
                _this.hasSKUErrors = true;
            }
            if (_this.orders.some(function (o) { return !isNullOrUndefined(o.invalidSku); }) &&
                _this.orders.some(function (o) { return !isNullOrUndefined(o.invalidSku[0]) && o.invalidSku[0].name.toLowerCase().includes('duplicate'); })) {
                _this.hasDuplicatedSKUs = true;
            }
        });
        console.log(this.orders);
        // if (this.orders.some(o => o.invalidSku.length > 0)) {
        //     if (this.orders.some(o => o.invalidSku.length > 0)) {
        //         this.hasSKUErrors = true;
        //     }
        //     for (const skuname of this.orders.find(sku => sku.invalidSku.length > 0).invalidSku) {
        //         if (skuname.name.toLowerCase().includes("duplicate")) {
        //             this.hasSKUErrors = true;
        //         }
        //     }
        // }
    };
    ImportOrderComponent.prototype.trackById = function (index, city) {
        return city.cityId;
    };
    ImportOrderComponent.prototype.AllContactsValidation = function () {
        var _this = this;
        var notvalidnb = 0;
        this.ConfirmButtonDisabled = false;
        this.invalidOrders.forEach(function (x) {
            if (!x.isValid) {
                _this.ConfirmButtonDisabled = true;
                notvalidnb = notvalidnb + 1;
            }
        });
        this.countinValid = this.invalidOrders.length - notvalidnb;
    };
    ImportOrderComponent.prototype.onEdit = function (index) {
        this.editIndex = index;
        this.isEditOn = !this.isEditOn;
        this.importForm.control.updateValueAndValidity({ onlySelf: true, emitEvent: false });
        this.importForm.form.updateValueAndValidity({ onlySelf: false, emitEvent: false });
        this.ConfirmButtonDisabled = true;
        //this.editedSKUsArray = this.orders[index].SKUs;
        this.invalidSku = this.orders[index].invalidSku;
        this.duplicatedSKU = this.orders[index].SKUs[0];
        this.orderEdit = this.orders[index];
    };
    ImportOrderComponent.prototype.saveNewData = function (order) {
        var orderExcelItem = this.orders.find(function (x) { return x == order; });
        orderExcelItem.shipmentCurrencyId = order.shipmentCurrencyId;
        orderExcelItem.codCurrencyId = order.codCurrencyId;
        orderExcelItem.shipperId = order.shipperId;
        var checkReferenceIfFound = this.shipperReferences.findIndex(function (x) { return x.toString().toLowerCase() == orderExcelItem.shipperReference.toString().toLowerCase(); });
        if (checkReferenceIfFound >= 0)
            order.isValid = false;
        orderExcelItem.shipperReference = order.shipperReference;
        orderExcelItem.specialInstructions = order.specialInstructions;
        if (order.shipperId) {
            var shipperContact = this.contacts.find(function (x) { return x.contactId == order.shipperId; });
            orderExcelItem.shipper = shipperContact.fullName;
            orderExcelItem.shipperCityId = shipperContact.cityId;
            orderExcelItem.shipperAddressId = shipperContact.addressId;
            orderExcelItem.isValid = this.importForm.form.valid;
        }
        var countryIdRetreived = this.countries.find(function (x) { return x.countryPhoneCode == order.phoneCountryCode; });
        if (countryIdRetreived) {
            var mobile = order.phoneNumber;
            var validatedPhone = PhoneValidator.ValidPhone(mobile.toString().replace("-", ""), countryIdRetreived.countryReference);
            if (validatedPhone == '') {
                order.phoneNumber = '';
                order.isValid = false;
            }
        }
        if (!isNullOrUndefined(order.addresses[0].cityId)) {
            order.addresses[0].cityName = this.allCities.find(function (x) { return x.cityId == order.addresses[0].cityId; }).cityName;
            orderExcelItem.isValid = this.importForm.form.valid;
        }
        if (!isNullOrUndefined(order.addresses[0].countryId)) {
            order.addresses[0].countryName = this.countries.find(function (x) { return x.countryId == order.addresses[0].countryId; }).name;
            orderExcelItem.isValid = this.importForm.form.valid;
        }
        if (this.editedSKUsArray !== undefined) {
            // orderExcelItem.SKUs = this.editedSKUsArray;
            console.log(orderExcelItem.SKUs);
            if (this.invalidSku.length > 0) {
                order.isValid = false;
                orderExcelItem.isValid = this.importForm.form.invalid;
                this.hasSKUErrors = true;
                this.invalidOrders.length++;
            }
            else {
                order.isValid = true;
                orderExcelItem.isValid = this.importForm.form.valid;
                this.hasSKUErrors = false;
                this.invalidSku = [];
                orderExcelItem.invalidSku = this.invalidSku;
                this.ConfirmButtonDisabled = false;
                if (this.invalidOrders.length > 0) {
                    this.invalidOrders.length--;
                }
            }
        }
        this.isEditOn = !this.isEditOn;
        this.invalidOrders = this.orders.filter(function (c) { return c.isValid === false; });
        if (this.invalidOrders.length === 0) {
            this.ConfirmButtonDisabled = false;
        }
    };
    ImportOrderComponent.prototype.saveNewDataNew = function (order) {
        var _this = this;
        var shipperReferenceList = [];
        var _loop_1 = function (i) {
            if (shipperReferenceList.find(function (x) { return x.toString() === _this.orders[i].shipperReference; })) {
                return "continue";
            }
            else {
                shipperReferenceList.push(this_1.orders[i].shipperReference);
            }
        };
        var this_1 = this;
        for (var i = 0; i < this.orders.length; i++) {
            _loop_1(i);
        }
        var orderExcelItem = this.orders.find(function (x) { return x == order; });
        orderExcelItem.shipmentCurrencyId = order.shipmentCurrencyId;
        orderExcelItem.codCurrencyId = order.codCurrencyId;
        orderExcelItem.shipperId = order.shipperId;
        var checkReferenceIfFound = this.shipperReferences.findIndex(function (x) { return x.toString().toLowerCase() == orderExcelItem.shipperReference.toString().toLowerCase(); });
        if (checkReferenceIfFound >= 0) {
            order.isValid = false;
        }
        orderExcelItem.shipperReference = order.shipperReference;
        orderExcelItem.specialInstructions = order.specialInstructions;
        if (order.shipperId) {
            var shipperContact = this.contacts.find(function (x) { return x.contactId == order.shipperId; });
            orderExcelItem.shipper = shipperContact.fullName;
            orderExcelItem.shipperCityId = shipperContact.cityId;
            orderExcelItem.shipperAddressId = shipperContact.addressId;
            orderExcelItem.isValid = this.importForm.form.valid;
        }
        var countryIdRetreived = this.countries.find(function (x) { return x.countryPhoneCode == order.phoneCountryCode; });
        if (countryIdRetreived) {
            var mobile = order.phoneNumber;
            var validatedPhone = PhoneValidator.ValidPhone(mobile.toString().replace("-", ""), countryIdRetreived.countryReference);
            if (validatedPhone == '') {
                order.phoneNumber = '';
                order.isValid = false;
            }
        }
        if (!isNullOrUndefined(order.addresses[0].cityId)) {
            order.addresses[0].cityName = this.allCities.find(function (x) { return x.cityId == order.addresses[0].cityId; }).cityName;
            orderExcelItem.isValid = this.importForm.form.valid;
        }
        if (!isNullOrUndefined(order.addresses[0].countryId)) {
            order.addresses[0].countryName = this.countries.find(function (x) { return x.countryId == order.addresses[0].countryId; }).name;
            orderExcelItem.isValid = this.importForm.form.valid;
        }
        for (var i = 0; i < shipperReferenceList.length; i++) {
            var filteredOrders = this.orders.filter(function (x) { return x.shipperReference === order.shipperReference; });
            var filteredOrdersSKU = filteredOrders.filter(function (x) { return x.shipperReference === order.shipperReference && x.SKUs[0] === order.SKUs[0]; });
            filteredOrders.forEach(function (forder) {
                if (!isNullOrUndefined(forder.addresses[0].cityId)) {
                    forder.addresses[0].cityName = _this.allCities.find(function (x) { return x.cityId == filteredOrders[0].addresses[0].cityId; }).cityName;
                    orderExcelItem.isValid = _this.importForm.form.valid;
                    forder.isValid = true;
                }
                else {
                    orderExcelItem.isValid = _this.importForm.form.invalid;
                    forder.isValid = false;
                }
                if (!isNullOrUndefined(forder.addresses[0].countryId)) {
                    forder.addresses[0].countryName = _this.countries.find(function (x) { return x.countryId == filteredOrders[0].addresses[0].countryId; }).name;
                    orderExcelItem.isValid = _this.importForm.form.valid;
                    forder.isValid = true;
                }
                else {
                    orderExcelItem.isValid = _this.importForm.form.invalid;
                    forder.isValid = false;
                }
                if (filteredOrdersSKU.length > 1) {
                    order.isValid = false;
                    if (!isNullOrUndefined(order.invalidSku)) {
                        order.invalidSku.push({ name: 'ERROR DUPLICATE', currentStock: NaN });
                        _this.hasDuplicatedSKUs = true;
                    }
                }
                else {
                    order.isValid = true;
                    order.invalidSku = [];
                }
                // THIS NEEDS A REWORK
                if (!isNullOrUndefined(forder.invalidSku) && forder.invalidSku.length > 0) {
                    forder.isValid = false;
                    _this.hasSKUErrors = true;
                    _this.invalidOrders.length++;
                }
                else {
                    orderExcelItem.isValid = true;
                    orderExcelItem.isValid = _this.importForm.form.valid;
                    _this.hasSKUErrors = false;
                    orderExcelItem.invalidSku = _this.invalidSku;
                    _this.ConfirmButtonDisabled = false;
                    if (_this.invalidOrders.length > 0) {
                        _this.invalidOrders.length--;
                    }
                    if (!isNullOrUndefined(orderExcelItem.invalidSku) && orderExcelItem.invalidSku.length > 0) {
                        orderExcelItem.isValid = false;
                        orderExcelItem.isValid = _this.importForm.form.invalid;
                        _this.hasSKUErrors = true;
                        _this.invalidOrders.length++;
                    }
                    if (filteredOrders.length > 1) {
                        if (isNullOrUndefined(orderExcelItem.SKUs[0])) {
                            orderExcelItem.isValid = false;
                            orderExcelItem.invalidSku = [];
                            orderExcelItem.invalidSku.push({ name: 'Empty SKU', currentStock: NaN });
                            _this.hasSKUErrors = true;
                        }
                    }
                }
            });
            if (!isNullOrUndefined(orderExcelItem.SKUs[0]) && orderExcelItem.SKUs[0].length >= 0 && isNullOrUndefined(orderExcelItem.SKUQty)) {
                orderExcelItem.isValid = false;
                this.hasSKUErrors = true;
            }
            else {
                this.hasSKUErrors = false;
            }
        }
        this.isEditOn = !this.isEditOn;
        this.invalidOrders = this.orders.filter(function (c) { return c.isValid === false; });
        if (this.invalidOrders.length === 0) {
            this.ConfirmButtonDisabled = false;
        }
        if (!this.orders.some(function (o) { return !isNullOrUndefined(o.invalidSku) && o.invalidSku.some(function (sku) { return sku.name === 'ERROR DUPLICATE'; }); })) {
            this.hasDuplicatedSKUs = false;
        }
    };
    ImportOrderComponent.prototype.skuChanged = function ($event) {
        var _this = this;
        var editedSkus = [];
        var duplicatedCheck = {};
        var regex = /^(.*?)(?:\((\d+)\))?$/i;
        this.editedSKUsArray = $event.split(',');
        for (var i = 0; i < this.editedSKUsArray.length; i++) {
            var matched = this.editedSKUsArray[i].match(regex);
            editedSkus.push({ name: matched[1], currentStock: parseInt(matched[2]) || 0 });
        }
        this.invalidSku = this.orders.find(function (o) { return o.SKUs.length > 0; }).invalidSku;
        this.invalidSku = editedSkus.filter(function (editedSku) { return !_this.existingSkus.some(function (existingSku) { return editedSku.name === existingSku.name &&
            editedSku.currentStock <= existingSku.currentStock; }); });
        if (editedSkus.some(function (sku) { return sku.name === ''; })) {
            this.invalidSku = [];
        }
        if (this.invalidSku.length > 0) {
            this.hasSKUErrors = true;
        }
        else {
            this.hasSKUErrors = false;
        }
        this.hasSKUErrors = editedSkus.some(function (duplicateSku) {
            if (duplicatedCheck.hasOwnProperty(duplicateSku.name)) {
                _this.invalidSku.push(duplicateSku);
                return true;
            }
            return (duplicatedCheck[duplicateSku.name] = false);
        });
        console.log(this.invalidSku, editedSkus, this.editedSKUsArray);
    };
    ImportOrderComponent.prototype.skuChangedName = function ($event) {
        var _this = this;
        var duplicatedSku = this.duplicatedSKU;
        var filteredOrders = this.orders.filter(function (o) { return o.shipperReference === _this.orders[_this.editIndex].shipperReference; });
        var editedSku = [];
        editedSku.push({ name: $event, currentStock: this.orders[this.editIndex].SKUQty });
        this.invalidSku = editedSku.filter(function (editSku) { return !_this.existingSkus.some(function (existing) { return editSku.name === existing.name && $event !== ''; }); });
        this.orders[this.editIndex].invalidSku = this.invalidSku;
        if (this.invalidSku.length > 0) {
            this.hasSKUErrors = true;
        }
        if (isNullOrUndefined($event) || $event === '') {
            filteredOrders.forEach(function (order) {
                order.isValid = false;
                order.invalidSku = [];
                order.invalidSku.push({ name: 'Empty SKU', currentStock: NaN });
            });
            this.hasSKUErrors = true;
        }
        if (this.hasDuplicatedSKUs && this.orders[this.editIndex].invalidSku.length === 0) {
            this.orders[this.editIndex].isValid = true;
            var filterRef = this.orders.filter(function (order) { return order.shipperReference === _this.orders[_this.editIndex].shipperReference; });
            var filterInvalidSKU = filterRef.filter(function (order) { return order.SKUs[0] === duplicatedSku; });
            if (filterInvalidSKU.length > 1) {
                for (var i = 0; i < 1; i++) {
                    filterInvalidSKU[i].invalidSku = [];
                    filterInvalidSKU[i].isValid = true;
                }
            }
            else {
                filterInvalidSKU.forEach(function (order) {
                    order.invalidSku = [];
                    order.isValid = true;
                });
            }
        }
        else {
            this.hasSKUErrors = false;
        }
    };
    ImportOrderComponent.prototype.skuChangedQty = function ($event) {
        var _this = this;
        var editedSku = [];
        editedSku.push({ name: this.orders[this.editIndex].SKUs[0], currentStock: $event });
        this.invalidSku = editedSku.filter(function (editSku) { return !_this.existingSkus.some(function (existing) { return editSku.name === existing.name &&
            editSku.currentStock <= existing.currentStock; }); });
        if (editedSku.some(function (sku) { return sku.name === ''; })) {
            this.invalidSku = [];
        }
        if (this.invalidSku.length > 0) {
            this.hasSKUErrors = true;
        }
        else {
            this.hasSKUErrors = false;
        }
    };
    ImportOrderComponent.prototype.editIn = function (index) {
    };
    ImportOrderComponent.prototype.onKeyUpEvent = function (event, rowIdx) {
        if (this.shipperReferences.findIndex(function (x) { return x.toString().toLowerCase() == event.target.value.toString().toLowerCase(); }) >= 0) {
            var countryCodeControl = this.importForm.controls['txtshipperReference-' + rowIdx];
            countryCodeControl.setErrors({ 'duplicate': true });
            return false;
        }
        else {
            var countryCodeControl = this.importForm.controls['txtshipperReference-' + rowIdx];
            countryCodeControl.setErrors(null);
            return true;
        }
    };
    ImportOrderComponent.prototype.filterCities = function () {
        if (!this.allCities) {
            return;
        }
        var search = this.cityFilterCtrl.value;
        if (!search) {
            // this.getNextBatch();
            this.filteredCities.next(this.allCities.slice());
            return;
        }
        else {
            search = search.toString().toLowerCase();
        }
        // this.getNextBatch(search);
        this.filteredCities.next(this.allCities.filter(function (city) { return city.displayName.toString().toLowerCase().indexOf(search) > -1; }));
    };
    ImportOrderComponent.prototype.filterCountries = function () {
        if (!this.countries) {
            return;
        }
        var search = this.countryFilterCtrl.value;
        if (!search) {
            this.filteredCountries.next(this.countries.slice());
            return;
        }
        else {
            search = search.toString().toLowerCase();
        }
        this.filteredCountries.next(this.countries.filter(function (country) { return country.displayName.toLowerCase().indexOf(search) > -1; }));
    };
    ImportOrderComponent.prototype.filterCountryCodes = function () {
        if (!this.countryCodes) {
            return;
        }
        var search = this.countryCodeFilterCtrl.value;
        if (!search) {
            this.filteredCountryCodes.next(this.countryCodes.slice());
            return;
        }
        else {
            search = search.toString().toLowerCase();
        }
        this.filteredCountryCodes.next(this.countryCodes.filter(function (country) { return country.countryPhoneCode.toString().toLowerCase().indexOf(search) > -1; }));
    };
    ImportOrderComponent.prototype.numberOnly = function (event) {
        var key = window.event ? event.keyCode : event.which;
        if (event.keyCode === 8 || event.keyCode === 46) {
            return true;
        }
        else if (key < 48 || key > 57) {
            return false;
        }
        else {
            return true;
        }
        // const charCode = event.which ? event.which : event.keyCode;
        // const invalidChars = ['-', '+', 'e'];
        // const valid = ['.'];
        // if (valid.includes(event.key)) {
        //     console.log(true);
        //     return true;
        // }
        // if (charCode > 31 && (charCode < 48 || charCode > 57) && invalidChars.includes(event.key)) {
        //     console.log(false);
        //     return false;
        // }
        // console.log(true);
        // return true;
    };
    ImportOrderComponent.prototype.ValidateImportedOrders = function () {
        var _this = this;
        if (!this.importForm)
            return;
        for (var _i = 0; _i < this.invalidOrders.length; _i++) {
            Object.keys(this.importForm.controls).filter(function (key) { return key.endsWith('-' + _i); }).forEach(function (key) {
                var abstractControl = _this.importForm.controls[key];
                if (abstractControl.invalid)
                    _this.invalidOrders[_i].isValid = false;
                if (key.startsWith('txtMobile')) {
                    var phoneCountry = _this.countries.find(function (country) { return country.countryPhoneCode == _this.invalidOrders[_i].phoneCountryCode; });
                    var countryCodeControl = _this.importForm.controls['ddlCountryCode-' + _i];
                    var mobileControl = _this.importForm.controls['txtMobile-' + _i];
                    if (phoneCountry) {
                        var country = new FormControl({ iso: phoneCountry }, Validators.required);
                        var phoneNumberValid = new FormControl(false);
                        countryCodeControl.setValue(phoneCountry.countryPhoneCode);
                        abstractControl.setValidators([Validators.required, PhoneValidator.validCountryMobile(country, phoneNumberValid)]);
                    }
                    else {
                        countryCodeControl.setErrors({ 'invalid': true });
                        mobileControl.setErrors({ 'invalid': true });
                    }
                }
                // else if (key.startsWith('txtCodAmount2')) {
                //     console.log('txtCodAmount2');
                //     const codCurrencyControl = this.importForm.controls['ddlCodCurrencyId2-' + _i];
                //     const codControl = this.importForm.controls['txtCodAmount2-' + _i];
                //     console.log('txtCodAmount2');
                //     if (codControl.value > 0) {
                //         codCurrencyControl.setValidators([Validators.required]);
                //     }
                // }
            });
        }
        return this.invalidOrders;
    };
    ImportOrderComponent.prototype.changeCountry = function (index, event) {
        if (event.value != 0) {
            this.invalidOrders[index].addresses[0].cityId = null;
            //this.invalidOrders[index].addresses[0].cities = this.allCities.filter(x => x.countryId == event.value);
        }
    };
    ImportOrderComponent.prototype.changeCountryCode = function (index, event) {
        var key = Object.keys(this.importForm.controls).filter(function (key) { return key.startsWith('txtMobile-' + index); });
        var abstractControl = this.importForm.controls[key[0]];
        var phoneCountry = this.countries.find(function (country) { return country.countryPhoneCode == event.value; });
        var country = new FormControl({ iso: phoneCountry }, Validators.required);
        var phoneNumberValid = new FormControl(false);
        abstractControl.setValidators([Validators.required, PhoneValidator.validCountryMobile(country, phoneNumberValid)]);
    };
    ImportOrderComponent.prototype.save = function (form) {
        var _this = this;
        setTimeout(function () {
            _this.ValidateImportedOrders();
        }, 1000);
        this.importForm.form.updateValueAndValidity();
        this.showProgressBar = false;
    };
    ImportOrderComponent.prototype.closeDialog = function () {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
        this.matDialogRef.close(['cancel', null]);
    };
    ImportOrderComponent.prototype.validate = function () {
        this.ValidateImportedOrders();
        this.importForm.form.updateValueAndValidity();
    };
    ImportOrderComponent.prototype.saveImported = function () {
        this.matDialogRef.close(['new', this.orders.filter(function (x) { return x.isValid == true; })]);
    };
    // getNextBatch(citySearch?: string) {
    //     if (this.countryCities.length == 0)
    //         this.countryCities = this.allCities;
    //     if (!isNullOrUndefined(citySearch))
    //         var result = this.countryCities.filter(city => city.displayName.toLowerCase().indexOf(citySearch) > -1).slice(0, 0 + this.limit);
    //     else {
    //         var result = this.countryCities.slice(0, 0 + this.limit);
    //     }
    //     this.filteredCities.next(result.slice());
    //     this.options.next(result);
    //     this.offset += this.limit;
    // }
    ImportOrderComponent.prototype.ngOnDestroy = function () {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    };
    return ImportOrderComponent;
}());
export { ImportOrderComponent };
