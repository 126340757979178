import * as tslib_1 from "tslib";
import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject, merge } from 'rxjs';
import { map } from 'rxjs/operators';
import { FuseUtils } from '@fuse/utils';
import { ServiceDirection } from 'app/_enums/ServiceDirection.enum';
var OrdersOrderListDataSource = /** @class */ (function (_super) {
    tslib_1.__extends(OrdersOrderListDataSource, _super);
    // public  _fromDate = new Date;
    // public  _toDate = new Date;
    function OrdersOrderListDataSource(_ordersOrderListService, _matPaginator, _matSort) {
        var _this = _super.call(this) || this;
        _this._ordersOrderListService = _ordersOrderListService;
        _this._matPaginator = _matPaginator;
        _this._matSort = _matSort;
        _this._filterChange = new BehaviorSubject('');
        _this._filteredDataChange = new BehaviorSubject('');
        // if (!isNullOrUndefined(this._dateFrom)) {
        //     this._dateFrom.setDate(this._dateFrom.getDate());
        //     this._dateFrom.setHours(5);
        //     this._dateFrom.setMinutes(0);
        //     this._dateFrom.setSeconds(0);
        // }
        // if (!isNullOrUndefined(this._dateTo)) {
        //     this._dateTo.setDate(this._dateTo.getDate());
        // }
        // this._ordersOrderListService.fromDate = this._dateFrom;
        // this._ordersOrderListService.fromDate = this._dateFrom;
        _this.filteredData = _this._ordersOrderListService.orders;
        _this.pagination = _this._ordersOrderListService.paginationInfo;
        return _this;
    }
    /**
     * Connect function called by the table to retrieve one stream containing the data to render.
     *
     * @returns {Observable<any[]>}
     */
    OrdersOrderListDataSource.prototype.connect = function () {
        var _this = this;
        var displayDataChanges = [
            this._ordersOrderListService.onOrdersChanged,
            this._matPaginator.page,
            this._filterChange,
            this._matSort.sortChange
        ];
        return merge.apply(void 0, displayDataChanges).pipe(map(function () {
            var data = _this._ordersOrderListService.orders.slice();
            data = _this.filterData(data);
            _this.filteredData = data.slice();
            data = _this.sortData(data);
            // Grab the page's slice of data
            var startIndex = _this._matPaginator.pageIndex * _this._matPaginator.pageSize;
            return data.splice(startIndex, _this._matPaginator.pageSize);
        }));
    };
    /**
     * Disconnect
     */
    OrdersOrderListDataSource.prototype.disconnect = function () { };
    Object.defineProperty(OrdersOrderListDataSource.prototype, "filteredData", {
        // -----------------------------------------------------------------------------------------------------
        // @ Accessors
        // -----------------------------------------------------------------------------------------------------
        // Filtered data
        get: function () {
            return this._filteredDataChange.value;
        },
        set: function (value) {
            this._filteredDataChange.next(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrdersOrderListDataSource.prototype, "filter", {
        // Filter
        get: function () {
            return this._filterChange.value;
        },
        set: function (filter) {
            this._filterChange.next(filter);
        },
        enumerable: true,
        configurable: true
    });
    // set fromDate(date: any) {
    //     this._fromDate = date;
    // }
    // get fromDate(): any {
    //     return this._fromDate;
    // }
    // set toDate(date: any) {
    //     this._toDate = date;
    // }
    // get toDate(): any {
    //     return this._toDate;
    // }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Filter data
     *
     * @param data
     * @returns {any}
     */
    OrdersOrderListDataSource.prototype.filterData = function (data) {
        // if (!this.filter) {
        //     return data;
        // }
        // return FuseUtils.filterArrayByStringAndDate(data, this.filter, this._fromDate, this._toDate);
        return FuseUtils.filterArrayByString(data, this.filter);
    };
    // setDateFrilter(fromDate , toDate): any {
    //    this._fromDate = fromDate;
    //    this._toDate = toDate;
    // }
    /**
     * Sort data
     *
     * @param data
     * @returns {any[]}
     */
    OrdersOrderListDataSource.prototype.sortData = function (data) {
        var _this = this;
        if (!this._matSort.active || this._matSort.direction === '') {
            return data;
        }
        return data.sort(function (a, b) {
            var _a, _b, _c, _d, _e, _f, _g;
            var propertyA;
            var propertyB;
            switch (_this._matSort.active) {
                case 'direction':
                    _a = [ServiceDirection[a.direction], ServiceDirection[b.direction]], propertyA = _a[0], propertyB = _a[1];
                    break;
                case 'from':
                    _b = [a.from, b.from], propertyA = _b[0], propertyB = _b[1];
                    break;
                case 'to':
                    _c = [a.to, b.to], propertyA = _c[0], propertyB = _c[1];
                    break;
                case 'pickupDate':
                    _d = [a.pickupDate, b.pickupDate], propertyA = _d[0], propertyB = _d[1];
                    break;
                case 'label':
                    _e = [a.label, b.label], propertyA = _e[0], propertyB = _e[1];
                    break;
                case 'status':
                    _f = [a.status, b.status], propertyA = _f[0], propertyB = _f[1];
                    break;
                case 'statusDate':
                    _g = [
                        new Date(Date.parse(a.statusUpdatedDateString)),
                        new Date(Date.parse(b.statusUpdatedDateString))
                    ], propertyA = _g[0], propertyB = _g[1];
                    break;
            }
            var valueA = propertyA;
            var valueB = propertyB;
            return (valueA < valueB ? -1 : 1) * (_this._matSort.direction === 'asc' ? 1 : -1);
        });
    };
    return OrdersOrderListDataSource;
}(DataSource));
export { OrdersOrderListDataSource };
