import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { OrderAnonymousBaseComponent } from '../anonymous-base/anonymous-base.component';
import { FormControl } from '@angular/forms';
import { OrderService } from '../../order.service';
import { PaymentService } from '../../new-order/payment/payment.services';
var OrdersAnonymousShipmentReviewComponent = /** @class */ (function (_super) {
    tslib_1.__extends(OrdersAnonymousShipmentReviewComponent, _super);
    function OrdersAnonymousShipmentReviewComponent(_orderService, _paymentService) {
        var _this = _super.call(this) || this;
        _this._orderService = _orderService;
        _this._paymentService = _paymentService;
        _this.invalidCoupon = false;
        return _this;
    }
    OrdersAnonymousShipmentReviewComponent.prototype.ngOnInit = function () { };
    OrdersAnonymousShipmentReviewComponent.prototype.saveOrder = function () {
        var _this = this;
        this._orderService.saveOrder(this.form.value).then(function (order) {
            var orderId = _this.form.get('orderId');
            orderId.setValue(order.orderId, { onlySelf: true, emitEvent: false });
            _this.continue();
        });
    };
    OrdersAnonymousShipmentReviewComponent.prototype.applyCoupon = function () {
        var _this = this;
        var totalPrice = this.form.get('totalPrice');
        var couponCode = this.form.get('couponCode');
        var disableGenerate = this.form.get('disableGenerate');
        this.invalidCoupon = false;
        if (couponCode.value === this.coupon) {
            return;
        }
        this._paymentService.applyCoupon(this.coupon, this.form.value.totalPrice).then(function (newAmount) {
            var oldAmount = +totalPrice.value;
            if (oldAmount === newAmount) {
                _this.invalidCoupon = true;
                totalPrice.setValue(newAmount);
            }
            totalPrice.setValue(newAmount);
            couponCode.setValue(_this.coupon);
            disableGenerate.setValue(true);
        });
    };
    OrdersAnonymousShipmentReviewComponent.prototype.removeCoupon = function () {
        var couponCode = this.form.get('couponCode');
        var disableGenerate = this.form.get('disableGenerate');
        couponCode.setValue(undefined);
        this.coupon = '';
        this.form.get('totalPrice').setValue(this.form.get('originPrice').value);
        disableGenerate.setValue(true);
    };
    return OrdersAnonymousShipmentReviewComponent;
}(OrderAnonymousBaseComponent));
export { OrdersAnonymousShipmentReviewComponent };
