import { OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { ServiceDirection, MyNetServiceType } from 'app/_enums/ServiceDirection.enum';
import { ContactsContactListService } from 'app/main/contacts/contact-list/contact-list.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { MatDialogRef, MatDialog, MatSnackBar } from '@angular/material';
import { InternationalQuote } from 'app/main/quotes/international/international.model';
import { ServiceType } from 'app/_enums/ServiceType.enum';
import { QuoteService } from 'app/main/quotes/quote.service';
import { ContactEditorComponent } from 'app/main/contacts/contact-editor/contact-editor.component';
import { CommodityService } from 'app/_shared/commodity.service';
import { WeightValidator } from 'app/_validators/weight.validator';
import { DecimalPipe } from '@angular/common';
import { DecimalValidator } from 'app/_validators/decimal.validator';
import { Router } from '@angular/router';
import { Routes } from 'app/Routes';
import { ConfirmDistributionComponent } from './confirm-distribution/confirm-distribution.component';
import { DistributionService } from '../../distribution.service';
import { DistributionDetail } from '../distribution.model';
import { NetPointService } from 'app/main/orders/new-order/netpoint-locations/netpoint.services';
import { ContactsListService } from 'app/main/c-list/c-list/c-list.service';
import { ContactListEditorComponent } from 'app/main/c-list/c-list-editor/c-list-editor.component';
import { LocationService } from 'app/_shared/location-service.service';
import { isNullOrUndefined } from 'util';
var DistributionsOrderDetailsComponent = /** @class */ (function () {
    function DistributionsOrderDetailsComponent(_router, _contactService, _contactsContactListService, _matDialog, _quoteService, _commodityService, _distributionService, _netpointService, _snackBar, _numberPipe, _locationService) {
        this._router = _router;
        this._contactService = _contactService;
        this._contactsContactListService = _contactsContactListService;
        this._matDialog = _matDialog;
        this._quoteService = _quoteService;
        this._commodityService = _commodityService;
        this._distributionService = _distributionService;
        this._netpointService = _netpointService;
        this._snackBar = _snackBar;
        this._numberPipe = _numberPipe;
        this._locationService = _locationService;
        this.hasSubsidiaryId = false;
        this.submited = new EventEmitter();
        this.canceled = new EventEmitter();
        this.confirmed = new EventEmitter();
        this.allContacts = [];
        this.contactLists = [];
        this.shipperAddresses = [];
        this.receiverAddresses = [];
        this.AddressType = AddressType;
        this.ServiceDirection = ServiceDirection;
        this.addingShipper = false;
        this.addingReceiver = false;
        this.isExport = false;
        this.generatingQuote = false;
        this.ServiceType = ServiceType;
        this._onInitShipper = false;
        this._onInitReceiver = false;
        this._unsubscribeAll = new Subject();
        this.isNetPointShipper = false;
        this.isNetPointReceiver = false;
        this.minDate = new Date(new Date().setDate(new Date().getDate() + 1));
        // toNetPointButtonText = 'To NetPoint?';
        // fromNetPointButtonText = 'From NetPoint?';
        this.shipperAddressLabel = 'Shipper Address';
        this.receiverAddressLabel = 'Receiver Address';
        this.showDimensionsHelp = false;
        this.domesticCountryId = 158;
        this.domesticCountryName = "Lebanon";
    }
    Object.defineProperty(DistributionsOrderDetailsComponent.prototype, "groupValue", {
        get: function () {
            return this.group.getRawValue();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DistributionsOrderDetailsComponent.prototype, "selectedShipper", {
        get: function () {
            var _this = this;
            return this.contacts.find(function (x) { return x.contactId === _this.groupValue.shipperId; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DistributionsOrderDetailsComponent.prototype, "selectedReceiverList", {
        // get selectedReceiver(): CListItem {
        //     return this.contactLists.find(x => x.contactListId === this.groupValue.receiverId);
        // }
        get: function () {
            var _this = this;
            return this.contactLists.find(function (x) { return x.contactListId === _this.groupValue.receiverId; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DistributionsOrderDetailsComponent.prototype, "selectedShipperAddress", {
        get: function () {
            var _this = this;
            return this.shipperAddresses.find(function (x) { return x.addressId === _this.groupValue.shipperAddressId; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DistributionsOrderDetailsComponent.prototype, "distributionDetail", {
        get: function () {
            var _this = this;
            var distribution = new DistributionDetail(this.group.getRawValue());
            distribution.shipperName = this.contacts.find(function (c) { return c.contactId === distribution.shipperId; }).fullName;
            distribution.receiverName = this.contactLists.find(function (x) { return x.contactListId === _this.groupValue.receiverId; }).description;
            distribution.receiverCount = this.contactLists.find(function (x) { return x.contactListId === _this.groupValue.receiverId; }).contactListDetail.length;
            distribution.shipperAddress = this.toLongDisplayString(this.shipperAddresses.find(function (c) { return c.addressId === distribution.shipperAddressId; }));
            // distribution.receiverAddress = this.toLongDisplayString(
            //     this.receiverAddresses.find(c => c.addressId === distribution.receiverAddressId)
            // );
            return distribution;
        },
        enumerable: true,
        configurable: true
    });
    // get selectedReceiverAddress(): Address {
    //     return this.receiverAddresses.find(x => x.addressId === this.groupValue.receiverAddressId);
    // }
    DistributionsOrderDetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.hasSubsidiaryId = true;
        this._onInitReceiver = true;
        this._onInitShipper = true;
        // this.isNetPointShipper = this.group.get('isNetPointShipper').value;
        // this.isNetPointReceiver = this.group.get('isNetPointReceiver').value;
        this._distributionService.getMyInfo().subscribe(function (info) {
            _this.domesticCountryId = info.countryId;
            _this.domesticCountryName = info.countryName;
            console.log(info);
        });
        this.getTimeRanges();
        this.getContactsForOrders();
        this.getAllContactLists();
        this.getAllContacts();
        this.subscribeAllContactsChanged();
        var quote = this.group.value.quote;
        if (quote.type === undefined || quote.type === null || quote.type === ServiceDirection.Domestic) {
            this.prepareShippingTypes();
        }
        else if (quote.type !== undefined && quote.type !== null && quote.serviceTypeId !== 0) {
            this.prepareShippingTypes(quote.type, quote.serviceTypeId);
        }
        this.subscribeShipperChanged();
        this.subscribeReceiverChanged();
        this.subscribeContactsChanged();
        this.subscribeContactAdded();
        this.subscribeContactListsChanged();
        this.subscribeContactListsAdded();
        this.subscribeShipperAddressChanged();
        this.subscribeReceiverAddressChanged();
        this.subscribeDirectionChanged();
        this.subscribeShippingTypeChanged();
        this.subscribeQuoteChanged();
        this.setDirection();
        this.getCommodities();
        this.setDecimalValidation();
        this.getCountries();
        this._contactsContactListService.getContactsForContactList().then(function (contacts) {
            _this.contactListContacts = contacts;
        });
    };
    DistributionsOrderDetailsComponent.prototype.setDecimalValidation = function () {
        var quoteGroup = this.group.get('quote');
        var length = quoteGroup.get('length');
        var width = quoteGroup.get('width');
        var height = quoteGroup.get('height');
        length.setValidators([DecimalValidator.validDecimal(0, 200)]);
        width.setValidators([DecimalValidator.validDecimal(0, 150)]);
        height.setValidators([DecimalValidator.validDecimal(0, 150)]);
        var shipmentValue = quoteGroup.get('shipmentValue');
        shipmentValue.setValidators([DecimalValidator.validDecimal(0, 9999999999999999)]);
        // const quoteGroup = this.group.get('quote') as FormGroup;
        // const length = quoteGroup.get('length');
        // length.setValidators([DecimalValidator.validDecimal(0, 999)]);
        // const width = quoteGroup.get('width');
        // width.setValidators([DecimalValidator.validDecimal(0, 999)]);
        // const height = quoteGroup.get('height');
        // height.setValidators([DecimalValidator.validDecimal(0, 999)]);
        // const shipmentValue = quoteGroup.get('shipmentValue');
        // shipmentValue.setValidators([DecimalValidator.validDecimal(0, 99999)]);
    };
    DistributionsOrderDetailsComponent.prototype.weightChanged = function (event) {
        var weight = +event.target.value;
        if (weight !== undefined && weight !== null && !isNaN(weight) && weight < 0.5) {
            this.group
                .get('quote')
                .get('weight')
                .setValue('0.5');
        }
    };
    DistributionsOrderDetailsComponent.prototype.onTimeRangeChange = function (range) {
    };
    DistributionsOrderDetailsComponent.prototype.getTimeRanges = function () {
        var _this = this;
        this._distributionService.getTimeRanges().subscribe(function (result) {
            _this.timeRanges = result;
            var timeRangeLookupId = _this.group.get('timeRangeLookupId').value;
            if (isNullOrUndefined(timeRangeLookupId)) {
                _this.group.get('timeRangeLookupId').setValue(_this.timeRanges[0].id);
            }
        });
    };
    DistributionsOrderDetailsComponent.prototype.getCommodities = function () {
        var _this = this;
        var serviceTypeId;
        this.setDirection();
        switch (this.currentDirection) {
            case ServiceDirection.Domestic:
                serviceTypeId = MyNetServiceType.Domestic;
                break;
            case ServiceDirection.Import:
                serviceTypeId = MyNetServiceType.Import;
                break;
            case ServiceDirection.Export:
                serviceTypeId = MyNetServiceType.Export;
                break;
            default:
                serviceTypeId = 0;
                break;
        }
        if (!serviceTypeId) {
            return;
        }
        var forceGetCommodities = false;
        if (this._commodityService.commodities && this._commodityService.commodities.length > 0) {
            var sameServiceType = this._commodityService.commodities.filter(function (x) { return x.serviceTypeId === serviceTypeId; }).length > 0;
            if (sameServiceType) {
                this.commodities = this._commodityService.commodities;
            }
            else {
                forceGetCommodities = true;
            }
        }
        else {
            forceGetCommodities = true;
        }
        if (forceGetCommodities) {
            this._commodityService.getCommodities(serviceTypeId).then(function (result) {
                _this.commodities = result;
                _this.subscribeCommodityChanged();
            });
        }
    };
    DistributionsOrderDetailsComponent.prototype.subscribeCommodityChanged = function () {
        var _this = this;
        this.group
            .get('commodityId')
            .valueChanges.pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (commodityId) {
            if (commodityId) {
                var commodity = _this.commodities.find(function (x) { return x.commodityId === commodityId; });
                var serviceTypeId = _this.group.get('quote').get('serviceTypeId');
                if (serviceTypeId) {
                    serviceTypeId.setValue(commodity.erpProductTypeId);
                }
            }
        });
    };
    DistributionsOrderDetailsComponent.prototype.getContactsForOrders = function (firstTime) {
        var _this = this;
        if (firstTime === void 0) { firstTime = true; }
        this._contactService.getContactsForOrders().then(function (contacts) {
            _this.contacts = contacts;
            // if (this.group.value.receiverId && firstTime) {
            //     this.receiverChanged(this.group.value.receiverId);
            // }
            if (_this.group.value.shipperId && firstTime) {
                _this.shipperChanged(_this.group.value.shipperId);
            }
            // this.dataSource = new MatTableDataSource<ContactListItem>(this.contacts);
        });
    };
    DistributionsOrderDetailsComponent.prototype.getAllContacts = function (firstTime) {
        var _this = this;
        if (firstTime === void 0) { firstTime = true; }
        this._contactService.getContacts().then(function (contacts) {
            _this.allContacts = contacts;
        });
    };
    DistributionsOrderDetailsComponent.prototype.getAllContactLists = function (firstTime) {
        var _this = this;
        if (firstTime === void 0) { firstTime = true; }
        this._contactsContactListService.getContactLists().then(function (contacts) {
            _this.contactLists = contacts.body;
            // if (this.group.value.receiverId && firstTime) {
            //     this.receiverChanged(this.group.value.receiverId);
            // }
            // if (this.group.value.shipperId && firstTime) {
            //     this.shipperChanged(this.group.value.shipperId);
            // }
        });
    };
    DistributionsOrderDetailsComponent.prototype.subscribeContactListsChanged = function () {
        var _this = this;
        this._contactsContactListService.onContactsChanged.subscribe(function (contacts) {
            _this.contactLists = contacts;
        });
    };
    DistributionsOrderDetailsComponent.prototype.subscribeContactListsAdded = function () {
        var _this = this;
        this._contactsContactListService.onContactAdded.subscribe(function (contactId) {
            if (_this.addingReceiver) {
                _this.group.get('receiverId').setValue(contactId);
            }
            _this.addingReceiver = false;
        });
    };
    DistributionsOrderDetailsComponent.prototype.subscribeQuoteChanged = function () {
        var _this = this;
        var quoteGroup = this.group.get('quote');
        quoteGroup.valueChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe(function (_) {
            _this.newQuote.setValue(true);
        });
    };
    DistributionsOrderDetailsComponent.prototype.subscribeShippingTypeChanged = function () {
        var _this = this;
        var quoteGroup = this.group.get('quote');
        quoteGroup
            .get('shippingTypeId')
            .valueChanges.pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (shippingTypeId) {
            if (shippingTypeId) {
                var shippingType = _this.shippingTypes.find(function (s) { return s.id === shippingTypeId; });
                quoteGroup.get('shippingType').setValue(shippingType.name);
            }
            else {
                quoteGroup.get('shippingType').setValue('');
            }
        });
    };
    DistributionsOrderDetailsComponent.prototype.prepareShippingTypes = function (direction, serviceType) {
        var _this = this;
        if (direction === void 0) { direction = ServiceDirection.Domestic; }
        if (serviceType === void 0) { serviceType = ServiceType.Parcels; }
        // Direction is domestic since we are in domestic component
        // Service Type is always Parcels, no docs in Lebanon domestic
        this._quoteService.getShippingTypes(direction, serviceType).subscribe(function (result) {
            _this.shippingTypes = result;
            if (_this.shippingTypes && (_this.shippingTypes.length === 1 || _this.shippingTypes.length === 2)) {
                var quoteGroup = _this.group.get('quote');
                quoteGroup.get('shippingTypeId').setValue(_this.shippingTypes[0].id);
                quoteGroup.get('shippingType').setValue(_this.shippingTypes[0].name);
            }
        });
    };
    DistributionsOrderDetailsComponent.prototype.subscribeDirectionChanged = function () {
        var _this = this;
        var quoteGroup = this.group.get('quote');
        quoteGroup
            .get('type')
            .valueChanges.pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (type) {
            _this.group.get('commodityId').setValue('');
            _this.getCommodities();
            if (type === undefined) {
                return;
            }
            _this.isExport = type === ServiceDirection.Export;
            // here
            quoteGroup.get('shippingType').setValue('');
            quoteGroup.get('shippingTypeId').setValue('');
            quoteGroup.get('shippingTypeId').markAsUntouched();
            if (_this.isExport) {
                quoteGroup.addControl('serviceTypeId', new FormControl('', Validators.required));
                _this.shippingTypes = [];
                quoteGroup.get('shippingType').setValue('');
                var value = quoteGroup.get('serviceTypeId').value;
                if (value) {
                    _this.prepareShippingTypes(type, value);
                }
                quoteGroup.get('serviceTypeId').valueChanges.subscribe(function (serviceTypeId) {
                    _this.prepareShippingTypes(type, serviceTypeId);
                });
            }
            else {
                quoteGroup.removeControl('serviceTypeId');
                _this.prepareShippingTypes();
            }
        });
    };
    DistributionsOrderDetailsComponent.prototype.subscribeShipperAddressChanged = function () {
        var _this = this;
        this.group
            .get('shipperAddressId')
            .valueChanges.pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (addressId) {
            var quoteGroup = _this.group.get('quote');
            var problems = false;
            if (addressId) {
                var selectedAddress = _this.shipperAddresses.find(function (a) { return a.addressId === addressId; });
                if (selectedAddress) {
                    var address = _this.toLongDisplayString(selectedAddress);
                    if (_this.isNetPointShipper) {
                        quoteGroup.get('fromCity').setValue(selectedAddress.display + ', ' + address);
                    }
                    else {
                        quoteGroup.get('fromCity').setValue(address);
                    }
                    quoteGroup.get('fromCityId').setValue(selectedAddress.cityId);
                    quoteGroup.get('fromCountryId').setValue(selectedAddress.countryId);
                }
                else {
                    problems = true;
                }
            }
            else {
                problems = true;
            }
            if (problems) {
                quoteGroup.get('fromCity').setValue('');
                quoteGroup.get('fromCityId').setValue('');
                quoteGroup.get('fromCountryId').setValue('');
            }
            _this.updateDirection();
        });
    };
    DistributionsOrderDetailsComponent.prototype.subscribeReceiverAddressChanged = function () {
        var _this = this;
        this.group
            .get('receiverId')
            .valueChanges.pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (contactListId) {
            var quoteGroup = _this.group.get('quote');
            var problems = false;
            if (contactListId) {
                var selectedContactList = _this.contactLists.find(function (a) { return a.contactListId === contactListId; });
                if (selectedContactList) {
                    if (selectedContactList.contactListDetail) {
                        var contactListDetail = selectedContactList.contactListDetail[0];
                        if (contactListDetail) {
                            // const InitialCityContact = this.allContacts.find(a => a.contactId === selectedContactList.contactListDetail[0].ContactId) as Contact;
                            // if(InitialCityContact){
                            // const selectedAddress = this.shipperAddresses.find(a => a.addressId === InitialCityContact.addresses[0].addressId) as Address;
                            quoteGroup.get('toCityId').setValue(204);
                            quoteGroup.get('toCountryId').setValue(158);
                            //     }
                            //     else{
                            //         problems = true;
                            //     }
                        }
                    }
                }
                else {
                    problems = true;
                }
            }
            else {
                problems = true;
            }
            if (problems) {
                quoteGroup.get('toCity').setValue('');
                quoteGroup.get('toCityId').setValue('');
                quoteGroup.get('toCountryId').setValue('');
            }
            _this.updateDirection();
        });
    };
    DistributionsOrderDetailsComponent.prototype.updateDirection = function () {
        var quoteGroup = this.group.get('quote');
        this.setDirection();
        quoteGroup.get('type').setValue(this.currentDirection);
    };
    DistributionsOrderDetailsComponent.prototype.setDirection = function () {
        var quoteGroup = this.group.get('quote');
        // const fromCountryId = quoteGroup.get('fromCountryId').value;
        // const toCountryId = quoteGroup.get('toCountryId').value;
        // if (!fromCountryId || !toCountryId) {
        //     return undefined;
        // }
        if (!quoteGroup) {
            return undefined;
        }
        // if (fromCountryId === Countries.Lebanon && toCountryId === Countries.Lebanon) {
        // domestic
        this.maxWeight = WeightValidator.MaxDomesticWeight;
        this.currentDirection = ServiceDirection.Domestic;
        // } else if (fromCountryId === Countries.Lebanon) {
        //     // Export
        //     this.maxWeight = WeightValidator.MaxExportWeight;
        //     this.currentDirection = ServiceDirection.Export;
        // } else if (toCountryId === Countries.Lebanon) {
        //     // Import
        //     this.maxWeight = undefined;
        //     this.currentDirection = ServiceDirection.Import;
        // } else {
        //     // Not Supported
        //     this.maxWeight = undefined;
        //     this.currentDirection = undefined;
        // }
        var weight = quoteGroup.get('weight');
        weight.setValidators([WeightValidator.validWeight(this.currentDirection), Validators.required]);
        weight.updateValueAndValidity();
    };
    DistributionsOrderDetailsComponent.prototype.toLongDisplayString = function (address) {
        var message = address.cityName + ', ' + address.countryName + ', ' + address.street;
        if (address.building) {
            message += ', ' + address.building;
        }
        if (address.floor) {
            message += ', ' + address.floor;
        }
        if (address.zipCode) {
            message += ', ' + address.zipCode;
        }
        if (address.landmark) {
            message += ', ' + address.landmark;
        }
        return message;
    };
    DistributionsOrderDetailsComponent.prototype.subscribeShipperChanged = function () {
        var _this = this;
        this.group
            .get('shipperId')
            .valueChanges.pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (shipperId) {
            _this.shipperChanged(shipperId);
        });
    };
    DistributionsOrderDetailsComponent.prototype.shipperChanged = function (shipperId) {
        var _this = this;
        if (!this._onInitShipper) {
            this.group.get('shipperAddressId').reset();
            this.shippingTypes = [];
            // this.resetShipperNetPoint();
        }
        else {
            this._onInitShipper = false;
        }
        this._contactService.getContact(shipperId).then(function (contact) {
            if (contact.addresses) {
                _this.shipperAddresses = contact.addresses.filter(function (item) { return item.countryId === _this.domesticCountryId; });
            }
            // if (this.group.get('isNetPointShipper').value) {
            //     if (this._netpointService.netpoints && this._netpointService.netpoints.length > 0) {
            //         this.selectShipperNetPoint(this._netpointService.netpoints.slice());
            //     } else {
            //         this._netpointService.onNetPointsRetreived.subscribe(locations => {
            //             this.selectShipperNetPoint(locations.slice());
            //         });
            //     }
            // } else 
            if (_this.shipperAddresses && _this.shipperAddresses.length === 1) {
                _this.group.get('shipperAddressId').setValue(_this.shipperAddresses[0].addressId);
            }
        });
    };
    // private selectShipperNetPoint(locations: Address[]): void {
    //     const location = locations.find(l => l.addressId === this.group.get('shipperAddressId').value);
    //     this.resetShipperNetPoint();
    //     this.netpointSelect(location, AddressType.Shipper);
    // }
    DistributionsOrderDetailsComponent.prototype.subscribeReceiverChanged = function () {
        var _this = this;
        this.group
            .get('receiverId')
            .valueChanges.pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (receiverId) {
            _this.receiverChanged(receiverId);
        });
    };
    DistributionsOrderDetailsComponent.prototype.receiverChanged = function (receiverId) {
        if (!this._onInitReceiver) {
            // this.group.get('receiverAddressId').reset();
            this.shippingTypes = [];
            // this.resetReceiverNetPoint();
        }
        else {
            this._onInitReceiver = false;
        }
        // this._contactsContactListService.getContactList(receiverId).then((contact: ContactList) => {
        //     this.receiverAddresses = contact.addresses;
        //     if (this.group.get('isNetPointReceiver').value) {
        //         if (this._netpointService.netpoints && this._netpointService.netpoints.length > 0) {
        //             this.selectReceiverNetPoint(this._netpointService.netpoints.slice());
        //         } else {
        //             this._netpointService.onNetPointsRetreived.subscribe(locations => {
        //                 this.selectReceiverNetPoint(locations.slice());
        //             });
        //         }
        //     } else 
        //     if (this.receiverAddresses && this.receiverAddresses.length === 1) {
        //         this.group.get('receiverAddressId').setValue(this.receiverAddresses[0].addressId);
        //     }
        // });
    };
    // private selectReceiverNetPoint(locations: Address[]): void {
    //     const location = locations.find(l => l.addressId === this.group.get('receiverAddressId').value);
    //     this.resetReceiverNetPoint();
    //     this.netpointSelect(location, AddressType.Receiver);
    // }
    DistributionsOrderDetailsComponent.prototype.subscribeContactAdded = function () {
        var _this = this;
        this._contactService.onContactAdded.subscribe(function (contactId) {
            if (_this.addingShipper) {
                _this.group.get('shipperId').setValue(contactId);
                // } else if (this.addingReceiver) {
                //     this.group.get('receiverId').setValue(contactId);
            }
            _this.addingShipper = false;
            // this.addingReceiver = false;
        });
    };
    DistributionsOrderDetailsComponent.prototype.subscribeContactsChanged = function () {
        var _this = this;
        this._contactService.onContactsChanged.subscribe(function (contacts) {
            _this.contacts = contacts;
        });
    };
    DistributionsOrderDetailsComponent.prototype.subscribeAllContactsChanged = function () {
        var _this = this;
        this._contactService.getContacts().then(function (contacts) {
            _this.allContacts = contacts;
        });
    };
    DistributionsOrderDetailsComponent.prototype.submitForm = function () {
        if (this.group.invalid) {
            return;
        }
        this.submited.emit(5);
    };
    DistributionsOrderDetailsComponent.prototype.confirmDistribution = function () {
        if (this.group.invalid) {
            return;
        }
        this.generateNewQuote();
        this.ConfirmDialog();
        // this.saveOrderDetails();
    };
    DistributionsOrderDetailsComponent.prototype.ConfirmDialog = function () {
        var _this = this;
        if (!this._matDialog.openDialogs || this._matDialog.openDialogs.length > 0) {
            return;
        }
        var data = this.group.getRawValue();
        this.confirmdistributionDialogRef = this._matDialog.open(ConfirmDistributionComponent, {
            panelClass: 'confirm-distribution',
            data: {
                order: this.distributionDetail
            }
        });
        this.confirmdistributionDialogRef.afterClosed().subscribe(function (response) {
            if (!response) {
                return;
            }
            var status = response[0];
            if (status === 'cancel') {
                return;
            }
            var ConfirmedOrderDetail = response[1];
            //TODO
            if (ConfirmedOrderDetail.distributionId === 0) {
                _this._distributionService.addDistribution(data).then(function (result) {
                    // localStorage.setItem('distributionId', result.orderId.toString());
                    _this.afterSaveConfirmOrder(result);
                    // this._distributionService.addDistributionDetails
                });
            }
            else if (ConfirmedOrderDetail.distributionId > 0) {
                _this._distributionService.updateDistributionDetails(data).then(function (result) {
                    // localStorage.setItem('distributionId', result.distributionId.toString());
                    _this.afterSaveConfirmOrder(result);
                });
            }
        });
    };
    DistributionsOrderDetailsComponent.prototype.afterSaveConfirmOrder = function (result) {
        var quoteForm = this.group.get('quote');
        quoteForm.get('quoteId').setValue(result.quoteId, { emitEvent: false });
        this.group.get('quoteId').setValue(result.quoteId, { emitEvent: false });
        this._router.navigateByUrl(Routes.distributions);
    };
    DistributionsOrderDetailsComponent.prototype.cancel = function () {
        this.canceled.emit();
    };
    DistributionsOrderDetailsComponent.prototype.backtoDistributions = function () {
        this._router.navigateByUrl(Routes.distributions);
    };
    DistributionsOrderDetailsComponent.prototype.generateNewQuote = function () {
        var _this = this;
        if (this.group.invalid || this.generatingQuote) {
            this.group.markAllAsTouched();
            return;
        }
        this.generatingQuote = true;
        var generateQuote;
        var quoteForm = this.group.get('quote');
        var quote = quoteForm.value;
        quote.direction = quote.type;
        console.log(quote.type);
        if (quote.type === ServiceDirection.Export) {
            generateQuote = this._quoteService.generateInternationalQuote(quote);
        }
        else {
            generateQuote = this._quoteService.generateDomesticQuote(quote);
        }
        generateQuote.subscribe(function (result) {
            quoteForm.get('quotePrice').setValue(result.price);
            quoteForm.get('quoteCurrencyId').setValue(result.currency);
            _this.newQuote.setValue(false);
            _this.generatingQuote = false;
        });
    };
    DistributionsOrderDetailsComponent.prototype.addNewShipper = function () {
        var _this = this;
        this.addNewContact(function (_) { return (_this.addingShipper = true); });
    };
    DistributionsOrderDetailsComponent.prototype.addNewReceiver = function () {
        var _this = this;
        this.addNewContact(function (_) { return (_this.addingReceiver = true); });
    };
    DistributionsOrderDetailsComponent.prototype.getCountries = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this._locationService.getCountriesFromDB().subscribe(function (success) {
                resolve(success);
                _this.countries = success;
            });
        });
    };
    DistributionsOrderDetailsComponent.prototype.addNewContact = function (beforeSave) {
        var _this = this;
        this.contactDialogRef = this._matDialog.open(ContactEditorComponent, {
            panelClass: 'contact-form-dialog',
            data: {
                action: 'new',
                countries: this.countries
            }
        });
        this.contactDialogRef.afterClosed().subscribe(function (response) {
            if (!response) {
                return;
            }
            var status = response[0];
            if (status === 'cancel') {
                return;
            }
            var contact = response[1];
            var tags = response[2];
            contact.tags = tags;
            beforeSave();
            _this._contactService.addNewContact(contact);
        });
    };
    DistributionsOrderDetailsComponent.prototype.editContact = function (contactId) {
        var _this = this;
        this._contactService.getContact(contactId).then(function (c) {
            _this.contactDialogRef = _this._matDialog.open(ContactEditorComponent, {
                panelClass: 'contact-form-dialog',
                data: {
                    action: 'edit',
                    contact: c,
                    countries: _this.countries
                }
            });
            _this.contactDialogRef.afterClosed().subscribe(function (response) {
                if (!response) {
                    return;
                }
                var status = response[0];
                if (status === 'cancel') {
                    return;
                }
                var contact = response[1];
                var tags = response[2];
                contact.tags = tags;
                _this._contactService.editContact(contact).then(function (_) { return _this.getAllContacts(true); });
            });
        });
    };
    DistributionsOrderDetailsComponent.prototype.addNewReceiverList = function () {
        var _this = this;
        this.addNewContactList(function (_) { return (_this.addingReceiver = true); });
    };
    DistributionsOrderDetailsComponent.prototype.addNewContactList = function (beforeSave) {
        var _this = this;
        this.contactListDialogRef = this._matDialog.open(ContactListEditorComponent, {
            panelClass: 'contact-form-dialog',
            data: {
                action: 'new',
                // contactList: c,
                // contactListDetail : c.contactListDetail,
                contacts: this.contactListContacts
            }
        });
        this.contactListDialogRef.afterClosed().subscribe(function (response) {
            if (!response) {
                return;
            }
            var status = response[0];
            if (status === 'cancel') {
                return;
            }
            var contactlist = response[1];
            var tags = response[2];
            if (contactlist.contactListDetail != null) {
                contactlist.contactListDetail.length = 0;
                contactlist.contactListDetail = response[3];
            }
            contactlist.tags = tags;
            beforeSave();
            _this._contactsContactListService.addNewContact(contactlist);
            // this.groupValue.receiverId = contactlist.contactListId;
        });
    };
    DistributionsOrderDetailsComponent.prototype.editContactList = function (contactListId) {
        var _this = this;
        this._contactsContactListService.getContactList(contactListId).then(function (c) {
            _this.contactListDialogRef = _this._matDialog.open(ContactListEditorComponent, {
                panelClass: 'contact-form-dialog',
                data: {
                    action: 'edit',
                    contactList: c,
                    contactListDetail: c.contactListDetail,
                    contacts: _this.contacts
                }
            });
            _this.contactListDialogRef.afterClosed().subscribe(function (response) {
                if (!response) {
                    return;
                }
                var status = response[0];
                if (status === 'cancel') {
                    return;
                }
                var contactlist = response[1];
                var tags = response[2];
                if (contactlist.contactListDetail != null) {
                    contactlist.contactListDetail.length = 0;
                    contactlist.contactListDetail = response[3];
                }
                contactlist.tags = tags;
                _this._contactsContactListService.editContact(contactlist).then(function (_) { return _this.getAllContactLists(true); });
            });
        });
    };
    DistributionsOrderDetailsComponent.prototype.numberOnly = function (event) {
        var charCode = event.which ? event.which : event.keyCode;
        var invalidChars = ['-', '+', 'e'];
        var valid = ['.'];
        if (valid.includes(event.key)) {
            return true;
        }
        if (charCode > 31 && (charCode < 48 || charCode > 57) && invalidChars.includes(event.key)) {
            return false;
        }
        return true;
    };
    // isAllSelected() {
    //     const numSelected = this.selection.selected.length;
    //     const numRows = this.dataSource.data.length;
    //     return numSelected === numRows;
    //   }
    //   /** Selects all rows if they are not all selected; otherwise clear selection. */
    //   masterToggle() {
    //     this.isAllSelected() ?
    //         this.selection.clear() :
    //         this.dataSource.data.forEach(row => this.selection.select(row));
    //   }
    //   /** The label for the checkbox on the passed row */
    //   checkboxLabel(row?: ContactListItem): string {
    //     if (!row) {
    //       return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    //     }
    //     return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.contactId + 1}`;
    //   }
    DistributionsOrderDetailsComponent.prototype.ngOnDestroy = function () {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    };
    return DistributionsOrderDetailsComponent;
}());
export { DistributionsOrderDetailsComponent };
export var AddressType;
(function (AddressType) {
    AddressType[AddressType["Shipper"] = 0] = "Shipper";
    AddressType[AddressType["Receiver"] = 1] = "Receiver";
})(AddressType || (AddressType = {}));
