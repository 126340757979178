import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { AuthenticationService } from 'app/authentication/authentication.service';
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { NewOrder, PickupDetails, ShipmentDetails } from './new-order.model';
import { OrderAnonymousBaseComponent } from './anonymous-base/anonymous-base.component';
import { debounceTime } from 'rxjs/operators';
import { QuoteService } from 'app/main/quotes/quote.service';
import { ServiceDirection } from 'app/_enums/ServiceDirection.enum';
var AnonymousNewOrderComponent = /** @class */ (function (_super) {
    tslib_1.__extends(AnonymousNewOrderComponent, _super);
    function AnonymousNewOrderComponent(_authenticationService, _fuseConfigService, _fuseSidebarService, _formBuilder, _quoteService) {
        var _this = _super.call(this) || this;
        _this._authenticationService = _authenticationService;
        _this._fuseConfigService = _fuseConfigService;
        _this._fuseSidebarService = _fuseSidebarService;
        _this._formBuilder = _formBuilder;
        _this._quoteService = _quoteService;
        _this.lebanon = 158 /* Lebanon */;
        _this.loadingPrice = false;
        _this.updateFuseTemplate();
        var t = new Promise(function (resolve) {
            _this.loadScript();
            resolve(true);
        });
        return _this;
    }
    AnonymousNewOrderComponent.prototype.loadScript = function () {
        var isFound = false;
        var scripts = document.getElementsByTagName('script');
        // tslint:disable-next-line: prefer-for-of
        for (var i = 0; i < scripts.length; ++i) {
            if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').includes('checkout.js')) {
                isFound = true;
            }
        }
        if (!isFound) {
            var node = document.createElement('script');
            node.src = 'https://ap-gateway.mastercard.com/checkout/version/51/checkout.js';
            node.type = 'text/javascript';
            node.async = false;
            node.setAttribute('data-cancel', 'cancelCallback');
            node.setAttribute('data-error', 'errorCallback');
            node.setAttribute('data-complete', window.location.origin + '/orders/payments/redirect');
            document.getElementsByTagName('head')[0].appendChild(node);
        }
    };
    AnonymousNewOrderComponent.prototype.updateFuseTemplate = function () {
        if (!this._authenticationService.isLoggedIn()) {
            this._fuseConfigService.config = {
                layout: {
                    navbar: {
                        hidden: true
                    },
                    footer: {
                        hidden: true
                    },
                    sidepanel: {
                        hidden: true
                    }
                }
            };
        }
    };
    AnonymousNewOrderComponent.prototype.initForm = function () {
        this.orderForm = this._formBuilder.group({
            shipper: this.initContact(this._formBuilder),
            receiver: this.initContact(this._formBuilder),
            pickup: this._formBuilder.group(new PickupDetails()),
            shipmentDetails: this._formBuilder.group(new ShipmentDetails()),
            packageDetails: this.initPackageDetails(this._formBuilder),
            activeListItem: this._formBuilder.control(0),
            currentDirection: this._formBuilder.control(ServiceDirection.Domestic),
            totalPrice: this._formBuilder.control(0),
            orderId: this._formBuilder.control(undefined),
            couponCode: this._formBuilder.control(undefined),
            originPrice: this._formBuilder.control(undefined),
            disableGenerate: this._formBuilder.control(false)
        });
    };
    AnonymousNewOrderComponent.prototype.toggleSidebar = function (name) {
        this._fuseSidebarService.getSidebar(name).toggleOpen();
    };
    AnonymousNewOrderComponent.prototype.ngOnInit = function () {
        this.initForm();
        this.subscribeFormChanges();
    };
    AnonymousNewOrderComponent.prototype.subscribeFormChanges = function () {
        var _this = this;
        this.orderForm.valueChanges.pipe(debounceTime(1000)).subscribe(function (data) {
            if (data.disableGenerate) {
                var disableGenerate = _this.orderForm.get('disableGenerate');
                disableGenerate.setValue(false, { onlySelf: true, emitEvent: false });
                return;
            }
            _this.loadingPrice = true;
            _this._quoteService.generateAnonymousQuote(data).subscribe(function (price) {
                _this.totalPrice = price;
                _this.loadingPrice = false;
                var totalPrice = _this.orderForm.get('totalPrice');
                var originPrice = _this.orderForm.get('originPrice');
                totalPrice.setValue(price, { onlySelf: true, emitEvent: false });
                originPrice.setValue(price, { onlySelf: true, emitEvent: false });
            });
        });
    };
    return AnonymousNewOrderComponent;
}(OrderAnonymousBaseComponent));
export { AnonymousNewOrderComponent };
