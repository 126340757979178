import * as tslib_1 from "tslib";
import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject, merge } from 'rxjs';
import { map } from 'rxjs/operators';
import { FuseUtils } from '@fuse/utils';
var ContactsContactListDataSource = /** @class */ (function (_super) {
    tslib_1.__extends(ContactsContactListDataSource, _super);
    // public _typeId: number; //Home
    function ContactsContactListDataSource(_contactsContactListService, _matPaginator, _matSort) {
        var _this = _super.call(this) || this;
        _this._contactsContactListService = _contactsContactListService;
        _this._matPaginator = _matPaginator;
        _this._matSort = _matSort;
        _this._filterChange = new BehaviorSubject('');
        _this._filteredDataChange = new BehaviorSubject('');
        _this.filteredData = _this._contactsContactListService.contacts;
        _this.pagination = _this._contactsContactListService.paginationInfo;
        return _this;
    }
    /**
     * Connect function called by the table to retrieve one stream containing the data to render.
     *
     * @returns {Observable<any[]>}
     */
    ContactsContactListDataSource.prototype.connect = function () {
        var _this = this;
        var displayDataChanges = [
            this._contactsContactListService.onContactsChanged,
            this._matPaginator.page,
            this._filterChange,
            this._matSort.sortChange
        ];
        return merge.apply(void 0, displayDataChanges).pipe(map(function () {
            var data = _this._contactsContactListService.contacts.slice();
            // let data = this._contactsContactListService.onContactsChanged.value.slice();
            data = _this.filterData(data);
            _this.filteredData = data.slice();
            data = _this.sortData(data);
            // Grab the page's slice of data
            // const startIndex = this._matPaginator.pageIndex * this._matPaginator.pageSize;
            // return data.splice(startIndex, this._matPaginator.pageSize);
            return data;
        }));
    };
    /**
     * Disconnect
     */
    ContactsContactListDataSource.prototype.disconnect = function () { };
    Object.defineProperty(ContactsContactListDataSource.prototype, "filteredData", {
        // -----------------------------------------------------------------------------------------------------
        // @ Accessors
        // -----------------------------------------------------------------------------------------------------
        // Filtered data
        get: function () {
            return this._filteredDataChange.value;
        },
        set: function (value) {
            this._filteredDataChange.next(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ContactsContactListDataSource.prototype, "filter", {
        // Filter
        get: function () {
            return this._filterChange.value;
        },
        set: function (filter) {
            this._filterChange.next(filter);
        },
        enumerable: true,
        configurable: true
    });
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Filter data
     *
     * @param data
     * @returns {any}
     */
    ContactsContactListDataSource.prototype.filterData = function (data) {
        if (!this.filter) {
            return data;
        }
        return FuseUtils.filterArrayByString(data, this.filter);
    };
    ContactsContactListDataSource.prototype.setTypeFilter = function (typeId) {
        // this._typeId = typeId;
    };
    /**
     * Sort data
     *
     * @param data
     * @returns {any[]}
     */
    ContactsContactListDataSource.prototype.sortData = function (data) {
        var _this = this;
        if (!this._matSort.active || this._matSort.direction === '') {
            return data;
        }
        return data.sort(function (a, b) {
            var _a, _b, _c, _d;
            var propertyA = '';
            var propertyB = '';
            switch (_this._matSort.active) {
                case 'fullName':
                    _a = [a.fullName, b.fullName], propertyA = _a[0], propertyB = _a[1];
                    break;
                case 'tags':
                    _b = [
                        a.tags.length === 0 ? '' : a.tags[0],
                        b.tags.length === 0 ? '' : b.tags[0]
                    ], propertyA = _b[0], propertyB = _b[1];
                    break;
                case 'email':
                    _c = [a.email, b.email], propertyA = _c[0], propertyB = _c[1];
                    break;
                case 'phoneNumber':
                    _d = [a.phoneNumber, b.phoneNumber], propertyA = _d[0], propertyB = _d[1];
                    break;
            }
            var valueA = propertyA.toUpperCase();
            var valueB = propertyB.toUpperCase();
            return (valueA < valueB ? -1 : 1) * (_this._matSort.direction === 'asc' ? 1 : -1);
        });
    };
    return ContactsContactListDataSource;
}(DataSource));
export { ContactsContactListDataSource };
