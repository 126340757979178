import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from 'environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { tokenGetter } from 'app/app.module';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var AuthenticationService = /** @class */ (function () {
    function AuthenticationService(_httpClient) {
        this._httpClient = _httpClient;
        this.jwtHelper = new JwtHelperService();
        this.onUserInfoUpdated = new BehaviorSubject(null);
        this.onUserLoggedIn = new Subject();
    }
    AuthenticationService.prototype.hasSubsidiaryId = function () {
        return this._httpClient.get(environment.apiUrl + 'v1/authentication/HasSubsidiaryId');
    };
    AuthenticationService.prototype.getHeardAboutUsLookups = function () {
        return this._httpClient.get(environment.apiUrl + 'v1/authentication/HeardAboutUsLookups');
    };
    AuthenticationService.prototype.saveHeardAboutUs = function (data) {
        return this._httpClient.post(environment.apiUrl + 'v1/authentication/HeardAboutUs', data);
    };
    AuthenticationService.prototype.hasUserIDImage = function () {
        return this._httpClient.get(environment.apiUrl + 'v1/authentication/HasUserIDImage');
    };
    AuthenticationService.prototype.register = function (user) {
        return this._httpClient.post(environment.apiUrl + 'v1/authentication/register', user);
    };
    AuthenticationService.prototype.login = function (user) {
        return this._httpClient.post(environment.apiUrl + 'v1/authentication/Login', user);
    };
    // externalLogin(user: SocialUser): Observable<any> {
    //     return this._httpClient.post(environment.apiUrl + 'v1/authentication/SocialLogin', user);
    // }
    AuthenticationService.prototype.resetPassword = function (resetPassword) {
        return this._httpClient.post(environment.apiUrl + 'v1/authentication/ResetPassword', resetPassword);
    };
    AuthenticationService.prototype.logout = function () {
        return this._httpClient.post(environment.apiUrl + 'v1/authentication/Logout', null);
    };
    AuthenticationService.prototype.forgotPassword = function (email) {
        var params = new HttpParams().append('email', email.toString());
        return this._httpClient.post(environment.apiUrl + 'v1/authentication/ForgotPassword', null, { params: params });
    };
    AuthenticationService.prototype.isLoggedIn = function () {
        var storage = localStorage.getItem('loggedInUser');
        if (!storage) {
            return false;
        }
        var loggedInUser = JSON.parse(storage);
        var loggedInUserFound = loggedInUser !== null &&
            loggedInUser !== undefined &&
            loggedInUser.userId !== undefined &&
            loggedInUser.userId !== null &&
            loggedInUser.userId > 0;
        return loggedInUserFound && !this.jwtHelper.isTokenExpired(tokenGetter());
    };
    AuthenticationService.prototype.getLoggedInUser = function () {
        var storage = localStorage.getItem('loggedInUser');
        if (!storage) {
            this.loggedInUser = undefined;
        }
        var loggedInUser = JSON.parse(storage);
        if (loggedInUser !== null &&
            loggedInUser !== undefined &&
            loggedInUser.userId !== undefined &&
            loggedInUser.userId !== null &&
            loggedInUser.userId > 0) {
            this.loggedInUser = loggedInUser;
        }
    };
    AuthenticationService.prototype.authenticate = function (user, token) {
        localStorage.setItem('loggedInUser', JSON.stringify(user));
        localStorage.setItem('subsidiaryId', JSON.stringify(user.subsidiaryId));
        localStorage.setItem('countryId', JSON.stringify(user.countryId));
        localStorage.setItem('token', token);
        localStorage.setItem('role', user.role);
        localStorage.setItem('netPointKey', user.netPointKey);
        localStorage.setItem('specialAccount', user.specialAccount);
        localStorage.setItem('bulkSettings', user.bulkSettings);
        localStorage.setItem('isMFERP', JSON.stringify(user.isMFERP));
    };
    AuthenticationService.prototype.uploadUserIDPhoto = function (image) {
        var headers = new HttpHeaders({ 'Content-Type': 'application/octet-stream' });
        return this._httpClient.post(environment.apiUrl + 'v1/authentication/UploadUserIDImage', image, { headers: headers });
    };
    AuthenticationService.prototype.uploadUserPhoto = function (image) {
        var headers = new HttpHeaders({ 'Content-Type': 'application/octet-stream' });
        return this._httpClient.post(environment.apiUrl + 'v1/authentication/UploadUserImage', image, { headers: headers });
    };
    AuthenticationService.prototype.downloadUserPhoto = function () {
        return this._httpClient.get(environment.apiUrl + 'v1/authentication/DownloadUserImage', { responseType: 'blob' });
    };
    AuthenticationService.prototype.downloadUserIDPhoto = function () {
        return this._httpClient.get(environment.apiUrl + 'v1/authentication/DownloadUserIDImage', { responseType: 'blob' });
    };
    AuthenticationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthenticationService_Factory() { return new AuthenticationService(i0.ɵɵinject(i1.HttpClient)); }, token: AuthenticationService, providedIn: "root" });
    return AuthenticationService;
}());
export { AuthenticationService };
