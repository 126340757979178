import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { OrderAnonymousBaseComponent } from '../anonymous-base/anonymous-base.component';
import { QuoteService } from 'app/main/quotes/quote.service';
import { ServiceDirection } from 'app/_enums/ServiceDirection.enum';
import { ServiceType } from 'app/_enums/ServiceType.enum';
import { FormControl } from '@angular/forms';
var OrdersAnonymousShipmentPickupComponent = /** @class */ (function (_super) {
    tslib_1.__extends(OrdersAnonymousShipmentPickupComponent, _super);
    function OrdersAnonymousShipmentPickupComponent(_quoteService) {
        var _this = _super.call(this) || this;
        _this._quoteService = _quoteService;
        _this.minDate = new Date(new Date().setDate(new Date().getDate() + 1));
        _this.submited = false;
        return _this;
    }
    OrdersAnonymousShipmentPickupComponent.prototype.ngOnInit = function () {
        var _this = this;
        this._quoteService
            .getShippingTypesPromise(ServiceDirection.Domestic, ServiceType.Parcels)
            .then(function (result) {
            _this.shippingTypeRetreived(result);
        });
        this._quoteService.shippingTypesChanged.subscribe(function (result) {
            _this.shippingTypeRetreived(result);
        });
        this.group.get('shippingTypeId').valueChanges.subscribe(function (value) {
            var name = '';
            if (value) {
                var shippingType = _this.shippingTypes.find(function (x) { return x.id === value; });
                if (shippingType) {
                    name = shippingType.name;
                }
            }
            _this.group.setControl('shippingTypeName', new FormControl(name));
        });
    };
    OrdersAnonymousShipmentPickupComponent.prototype.shippingTypeRetreived = function (result) {
        this.shippingTypes = result;
        if (this.shippingTypes && (this.shippingTypes.length === 1 || this.shippingTypes.length === 2)) {
            this.group.get('shippingTypeId').setValue(this.shippingTypes[0].id);
            this.group.setControl('shippingTypeName', new FormControl(this.shippingTypes[0].name));
        }
        else {
            this.group.get('shippingTypeId').setValue(undefined);
            this.group.setControl('shippingTypeName', new FormControl(undefined));
        }
    };
    OrdersAnonymousShipmentPickupComponent.prototype.validateAndContinue = function () {
        if (this.group.valid) {
            this.continue();
        }
        else {
            this.submited = true;
            this.group.updateValueAndValidity();
            this.group.markAllAsTouched();
        }
    };
    return OrdersAnonymousShipmentPickupComponent;
}(OrderAnonymousBaseComponent));
export { OrdersAnonymousShipmentPickupComponent };
