import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var QuoteService = /** @class */ (function () {
    function QuoteService(_httpClient) {
        this._httpClient = _httpClient;
        this.citiesCache = [];
        this.countriesCache = [];
        this.shippingTypesChanged = new BehaviorSubject(null);
        this.onCodChanged = new BehaviorSubject(null);
    }
    QuoteService.prototype.getDomesticCities = function () {
        return this._httpClient.get(environment.apiUrl + 'v1/Quotes/Lebanon/Cities');
    };
    QuoteService.prototype.getCountries = function () {
        return this._httpClient.get(environment.apiUrl + 'v1/Quotes/Countries');
    };
    QuoteService.prototype.getCitiesByCountryId = function (countryId) {
        return this._httpClient.get(environment.apiUrl + 'v1/Quotes/Countries/' + countryId + '/Cities');
    };
    QuoteService.prototype.getShippingTypes = function (direction, type) {
        var params = new HttpParams().append('d', direction.toString()).append('t', type.toString());
        return this._httpClient.get(environment.apiUrl + 'v1/Quotes/ShippingTypes', { params: params });
    };
    QuoteService.prototype.getShippingTypesPromise = function (direction, type) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (direction === undefined || type === undefined) {
                resolve(null);
            }
            var params = new HttpParams().append('d', direction.toString()).append('t', type.toString());
            _this._httpClient
                .get(environment.apiUrl + 'v1/Quotes/ShippingTypes', { params: params })
                .subscribe(function (result) {
                _this.shippingTypes = result;
                _this.shippingTypesChanged.next(_this.shippingTypes);
                resolve(result);
            }, reject);
        });
    };
    QuoteService.prototype.saveCodAmount = function (cod) {
        return this._httpClient.post(environment.apiUrl + 'v1/Quotes/Cod', cod);
    };
    QuoteService.prototype.saveBulkCodAmount = function (cod) {
        return this._httpClient.post(environment.apiUrl + 'v1/Quotes/BulkCod', cod);
    };
    QuoteService.prototype.deleteCodAmount = function (quoteId) {
        return this._httpClient.delete(environment.apiUrl + 'v1/Quotes/Cod/' + quoteId);
    };
    QuoteService.prototype.getCodAmounts = function (orderId) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this._httpClient
                .get(environment.apiUrl + 'v1/Quotes/Cod/' + orderId)
                .subscribe(function (cods) {
                _this.cods = cods;
                _this.onCodChanged.next(_this.cods);
                resolve(cods);
            }, reject);
        });
    };
    QuoteService.prototype.getPieces = function (quoteId) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this._httpClient
                .get(environment.apiUrl + 'v1/Quotes/Dimensions/' + quoteId)
                .subscribe(function (pieces) {
                _this.pieces = pieces;
                _this.onPieceChanged.next(_this.pieces);
                resolve(pieces);
            }, reject);
        });
    };
    QuoteService.prototype.getSurchargesFromTarif = function (productId) {
        return this._httpClient.get(environment.apiUrl + 'v1/Quotes/Surcharges/' + productId);
    };
    QuoteService.prototype.saveSurcharges = function (surcharges) {
        return this._httpClient.post(environment.apiUrl + 'v1/Quotes/Surcharges', surcharges);
    };
    QuoteService.prototype.savePieces = function (pieces) {
        return this._httpClient.post(environment.apiUrl + 'v1/Quotes/Dimensions', pieces);
    };
    QuoteService.prototype.deletePieces = function (pieceId) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this._httpClient.delete(environment.apiUrl + 'v1/Quotes/Dimensions/' + pieceId).subscribe(function (_) {
                resolve(_this);
            }, reject);
        });
    };
    QuoteService.prototype.generateDomesticQuote = function (quote) {
        return this._httpClient.post(environment.apiUrl + 'v1/Quotes/DomesticQuote', quote);
    };
    QuoteService.prototype.generateDomesticBulkQuote = function (quotes) {
        return this._httpClient.post(environment.apiUrl + 'v1/Quotes/DomesticQuote/Bulk', quotes);
    };
    QuoteService.prototype.generateInternationalQuote = function (quote) {
        return this._httpClient.post(environment.apiUrl + 'v1/Quotes/InternationalQuote', quote);
    };
    QuoteService.prototype.addQuote = function (quote) {
        return this._httpClient.post(environment.apiUrl + 'v1/Quotes', quote);
    };
    QuoteService.prototype.generateAnonymousQuote = function (order) {
        return this._httpClient.post(environment.apiUrl + 'v1/Quotes/AnonymousQuote', order);
    };
    QuoteService.prototype.FetchStockAsync = function (storerKey, sku) {
        return this._httpClient.get(environment.apiUrl + 'v1/Quotes/FetchStock/' + storerKey + "/" + sku);
    };
    QuoteService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function QuoteService_Factory() { return new QuoteService(i0.ɵɵinject(i1.HttpClient)); }, token: QuoteService, providedIn: "root" });
    return QuoteService;
}());
export { QuoteService };
