import { HttpClient } from '@angular/common/http';
import { EMPTY } from 'rxjs';
import { environment } from 'environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var UserService = /** @class */ (function () {
    function UserService(_httpClient) {
        this._httpClient = _httpClient;
    }
    UserService.prototype.generatePhoneNumberToken = function (phoneNumber) {
        if (phoneNumber && typeof phoneNumber === 'string') {
            return this._httpClient.post(environment.apiUrl + 'v1/Users/GeneratePhoneNumberToken', {
                phoneNumber: phoneNumber
            });
        }
        else {
            return EMPTY;
        }
    };
    UserService.prototype.confirmPhoneNumberToken = function (data) {
        return this._httpClient.post(environment.apiUrl + 'v1/Users/ConfirmPhoneNumberToken', data);
    };
    UserService.prototype.isPhoneNumberExist = function (phoneNumber) {
        return this._httpClient.post(environment.apiUrl + 'v1/Users/IsPhoneNumberExist', { phoneNumber: phoneNumber });
    };
    UserService.prototype.getMyInfo = function () {
        return this._httpClient.get(environment.apiUrl + 'v1/Users/MyInfo');
    };
    UserService.prototype.addUserDetails = function (userDetails) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this._httpClient
                .post(environment.apiUrl + 'v1/Users/MyInfo/', userDetails)
                .subscribe(function (loginResult) {
                localStorage.setItem('loggedInUser', JSON.stringify(loginResult));
                resolve(loginResult);
            }, reject);
        });
    };
    UserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserService_Factory() { return new UserService(i0.ɵɵinject(i1.HttpClient)); }, token: UserService, providedIn: "root" });
    return UserService;
}());
export { UserService };
export var ChangePhoneNumberStatus;
(function (ChangePhoneNumberStatus) {
    ChangePhoneNumberStatus[ChangePhoneNumberStatus["SamePhoneNumber"] = 1] = "SamePhoneNumber";
    ChangePhoneNumberStatus[ChangePhoneNumberStatus["SMSSent"] = 2] = "SMSSent";
    ChangePhoneNumberStatus[ChangePhoneNumberStatus["FailedToSendSMS"] = 3] = "FailedToSendSMS";
    ChangePhoneNumberStatus[ChangePhoneNumberStatus["Confirmed"] = 4] = "Confirmed";
    ChangePhoneNumberStatus[ChangePhoneNumberStatus["FailedToChangeNumber"] = 5] = "FailedToChangeNumber";
})(ChangePhoneNumberStatus || (ChangePhoneNumberStatus = {}));
