import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var CommodityService = /** @class */ (function () {
    function CommodityService(_httpClient) {
        this._httpClient = _httpClient;
        this.commoditiesChanged = new BehaviorSubject(null);
    }
    CommodityService.prototype.getCommodities = function (serviceTypeId) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this._httpClient
                .get(environment.apiUrl + 'v1/Commodities/ServiceType/' + serviceTypeId)
                .subscribe(function (result) {
                _this.commodities = result;
                _this.commoditiesChanged.next(_this.commodities);
                resolve(result);
            }, reject);
        });
    };
    CommodityService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CommodityService_Factory() { return new CommodityService(i0.ɵɵinject(i1.HttpClient)); }, token: CommodityService, providedIn: "root" });
    return CommodityService;
}());
export { CommodityService };
