import { OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { Subject } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/internal/operators';
import { Router } from '@angular/router';
var DistributionPrintDialogComponent = /** @class */ (function () {
    // pageTypeId: number;
    function DistributionPrintDialogComponent(_router, _formBuilder, matDialogRef, _data) {
        this._router = _router;
        this._formBuilder = _formBuilder;
        this.matDialogRef = matDialogRef;
        this._data = _data;
        this._unsubscribeAll = new Subject();
        // Workaround for angular component issue #13870
        this.disableAnimation = true;
        this.minDate = new Date(new Date().setDate(new Date().getDate() + 1));
        this.cashEnabled = false;
        this.printDistributionForm = this._formBuilder.group({
            pagesPerSheet: [1, [Validators.required]] //,
            // codAmount: ['', null],
            // currencyId: [CurrencyType.LBP, null]
        });
        // this.pageTypeId = _data.pageTypeId;
    }
    DistributionPrintDialogComponent.prototype.ngOnInit = function () {
        this.pagesTypes = [
            { name: "1 - One page per sheet", value: 1 },
            { name: "2 - Two pages per sheet", value: 2 },
            { name: "4 - Four pages per sheet", value: 4 }
        ];
        this.subscribeRetourTypeChanged();
    };
    Object.defineProperty(DistributionPrintDialogComponent.prototype, "formValue", {
        get: function () {
            return this.printDistributionForm.value;
        },
        enumerable: true,
        configurable: true
    });
    DistributionPrintDialogComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        // timeout required to avoid the dreaded 'ExpressionChangedAfterItHasBeenCheckedError'
        setTimeout(function () { return (_this.disableAnimation = false); });
    };
    DistributionPrintDialogComponent.prototype.ngOnDestroy = function () {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    };
    DistributionPrintDialogComponent.prototype.subscribeRetourTypeChanged = function () {
        this.printDistributionForm
            .get('pagesPerSheet')
            .valueChanges.pipe(takeUntil(this._unsubscribeAll))
            .subscribe();
    };
    DistributionPrintDialogComponent.prototype.confirm = function () {
        console.log(this.printDistributionForm.value);
        this.matDialogRef.close(['new', this.printDistributionForm.value,]);
    };
    DistributionPrintDialogComponent.prototype.numberOnly = function (event) {
        var charCode = event.which ? event.which : event.keyCode;
        var invalidChars = ['-', '+', 'e'];
        var valid = ['.'];
        if (valid.includes(event.key)) {
            return true;
        }
        if (charCode > 31 && (charCode < 48 || charCode > 57) && invalidChars.includes(event.key)) {
            return false;
        }
        return true;
    };
    return DistributionPrintDialogComponent;
}());
export { DistributionPrintDialogComponent };
