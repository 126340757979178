import { Router } from '@angular/router';
import { AuthenticationService } from 'app/authentication/authentication.service';
import { Routes } from 'app/Routes';
import * as i0 from "@angular/core";
import * as i1 from "../authentication/authentication.service";
import * as i2 from "@angular/router";
var AuthGuard = /** @class */ (function () {
    function AuthGuard(authService, router) {
        this.authService = authService;
        this.router = router;
    }
    AuthGuard.prototype.canActivate = function (next) {
        // const roles = next.firstChild.data['roles'] as Array<string>;
        // if (roles) {
        //     const match = this.authService.roleMatch(roles);
        //     if (match) {
        //         return true;
        //     } else {
        //         this.router.navigate(['members']);
        //         console.error('You are not authorized to access this area');
        //     }
        // }
        if (this.authService.isLoggedIn()) {
            return true;
        }
        this.router.navigate([Routes.login]);
        return false;
    };
    AuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.ɵɵinject(i1.AuthenticationService), i0.ɵɵinject(i2.Router)); }, token: AuthGuard, providedIn: "root" });
    return AuthGuard;
}());
export { AuthGuard };
